<div>
  <div>
    <h5>Constructor de translation switcher</h5>
  </div>

  <div>
    <form class="builder-form" [formGroup]="formGroup">
      <input style="width: 100%" pInputText type="text" nbInput fullWidth formControlName="text" placeholder="Texto para visualizar" />

      <br /><br />

      <input
        style="width: 100%"
        pInputText
        class="form-input"
        type=""
        nbInput
        fullWidth
        formControlName="response"
        placeholder="Traducción al hacer clic" />
    </form>
  </div>
  <br />
  <div>
    <p-button (click)="copyToClipboard()" [disabled]="formGroup.invalid" label="Copia Código" [rounded]="true"></p-button>
    <p-button (click)="showCode()" [disabled]="formGroup.invalid" label="Mostrar" [rounded]="true" severity="secondary"></p-button>
  </div>
</div>
