import { Injectable } from '@angular/core';
import { ConversationChatSettings, LearningExample, TranscriptionsWhisper } from '../classes';
import { AIAPI, ChatRole, UtilsAPI, VerbTense } from '../enums';
import { HttpService } from '../system/http.service';
import { UserService } from './user.service';
import { ConversationAIAbstractService } from 'projects/dataclouder/conversation-system/src/lib/interfaces/conversation-ai.class';
import { IConversationCard } from 'projects/dataclouder/conversation-system/src/public-api';

export type AudioGenerated = { blobUrl: string; transcription: any };
export type TTSRequest = { text: string; voice: string; generateTranscription: boolean; speedRate: number; speed?: string; ssml?: string };

@Injectable({
  providedIn: 'root',
})
export class ConversationAIService implements ConversationAIAbstractService {
  constructor(private httpService: HttpService, private userService: UserService) {}

  async saveConversationCard(conversation: IConversationCard): Promise<IConversationCard> {
    debugger;
    if (conversation.id || conversation._id) {
      return await this.httpService.putDataToUrl('http://localhost:8080', `api/conversation-ai/conversation/${conversation._id}`, conversation);
    } else {
      return await this.httpService.postDataToUrl('http://localhost:8080', 'api/conversation-ai/conversation', conversation);
    }
  }

  async deleteConversationCard(id: string): Promise<any> {
    return await this.httpService.deleteDataFromUrl('http://localhost:8080', `api/conversation-ai/conversation/${id}`);
  }

  async findConversationCard(id: string): Promise<any> {
    return await this.httpService.getDataFromUrl('http://localhost:8080', `api/conversation-ai/conversation/${id}`);
  }
  async getAllConversationCards(): Promise<any[]> {
    return await this.httpService.getDataFromUrl('http://localhost:8080', 'api/conversation-ai/conversation');
  }

  // TODO: necesito ponerle un tipo al return.

  public async callChatCompletion(conversation: ConversationChatSettings): Promise<any> {
    let messages = conversation.messages.map((m) => ({ content: m.content, role: m.role }));
    messages = messages.filter((m) => m.role != ChatRole.AssistantHelper);
    const conversationFiltered = { ...conversation, messages };

    return await this.httpService.postDataToService(`${AIAPI.GenerateChat}`, conversationFiltered, 'python');
  }

  public async getTextAudioFile(tts: TTSRequest): Promise<AudioGenerated> {
    const httpReq: any = await this.httpService.receiveFile(`${AIAPI.GetTTS}`, tts, 'python');
    const audioData = { blobUrl: null, transcription: null };

    const transcription = httpReq?.headers.get('transcription');

    if (transcription) {
      const data = JSON.parse(transcription);
      audioData.transcription = data;
    }

    const mp3 = window.URL.createObjectURL(httpReq.body);
    audioData.blobUrl = mp3;

    return audioData;
  }
}
