import { Injectable, inject } from '@angular/core';
import { DialogService } from 'primeng/dynamicdialog';
import { DynamicDialogRef } from 'primeng/dynamicdialog';
import { SalesLetterComponent } from 'src/app/information/componentSalesLetter/sales-letter.component';

@Injectable({
  providedIn: 'root',
})
export class SalesLetterService {
  private dialogService = inject(DialogService);

  /** Inserted by Angular inject() migration for backwards compatibility */
  constructor(...args: unknown[]);

  constructor() {}

  public openSalesLetterDialog(): void {
    this.dialogService.open(SalesLetterComponent, {
      header: 'Promoción final y regalo',
      width: '95%',
      // styleClass: 'dialog-learning-example',
      focusOnShow: false,
      closable: true,
    });
  }
}
