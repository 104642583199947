import { Injectable, inject } from '@angular/core';
import { FiltersConfig, IFilterQueryResponse } from '@dataclouder/ngx-core';
import { DialogService } from 'primeng/dynamicdialog';
import { filter } from 'rxjs';
import { WordFormComponent } from 'src/app/pages/my-words/word-form/word-form.component';

import { EnglishVerb, PromoCode } from '../classes';
import { AdminApi, WordFormMode, WordType } from '../enums';
import { GeneralWord, LangWord } from '../lexicore.classes';
import { UserService } from './user.service';
import { HttpService } from '../system/http.service';
import { ToastService } from '../system/toast.service';

export interface PromotedData {
  current: GeneralWord;
  promoted: Array<GeneralWord>;
}

export interface DiccionaryData {
  [key: string]: PromotedData;
}

@Injectable({
  providedIn: 'root',
})
export class AdminService {
  private httpService = inject(HttpService);
  private userService = inject(UserService);
  private toastrService = inject(ToastService);
  private dialogService = inject(DialogService);

  /** Inserted by Angular inject() migration for backwards compatibility */
  constructor(...args: unknown[]);

  constructor() {}

  public async getWordsPromoted() {
    return this.httpService.getDataFromService<{ [key: string]: any }>(AdminApi.PromotedWords);
  }

  public async getVerbsPromoted() {
    return this.httpService.getDataFromService<{ [key: string]: any }>(AdminApi.PromotedVerbs);
  }

  public async mergePromotedWord(payLoad: any) {
    return this.httpService.postDataToService(AdminApi.MergeWord, payLoad);
  }

  public async deletePromotion(id: string, wordType: WordType) {
    const path = `${AdminApi.DeletePromotion}/${wordType}/${id}`;
    return this.httpService.deleteDataFromService(path);
  }

  public async addTagToWords(tag: string, wordsToTag: Array<string>) {
    const payload = { tag, words: wordsToTag };
    return this.httpService.postDataToService(AdminApi.TagWords, payload);
  }

  public async getFullWord(word: string) {
    const langString = this.userService.getUserLangOptions();

    return this.httpService.getDataFromService<LangWord>(`${AdminApi.Word}/${word}?${langString}`);
  }

  public async openWordForm(wordToSearch: string) {
    const word = await this.getFullWord(wordToSearch);
    const target = this.userService.getUserSnapshot().settings.targetLanguage;

    if (word == null) {
      this.toastrService.info(`No encontró la palabra  ${wordToSearch} en el diccionario de idioma '${target}', ni pudo generarla`, 'Edición');
      // this.confirmNewWordAdding(this.editedWord);
      return;
    }
    this.toastrService.info(`Se encontró la palabra en la base de datos de idioma '${target}'`, 'Edición');

    this.openEditWord(word)
      .onClose.pipe(filter(Boolean))
      .subscribe(async (wordForm) => {
        const notPromotion = true;
        if (notPromotion) {
          // TODO: que hacer aqui? creo que ya no existe el concepto de la promoción será que retomo?
          // this.saveKnownledgeWord(wordForm);
        } else {
          // TODO! No estoy seguro si la lógica de promoción debe ir aquí, creo que puedo eliminarla.
          // this.promoteWord(wordForm);
        }
      });
  }

  public async deleteWord(word: string) {
    const langString = this.userService.getUserLangOptions();
    return this.httpService.deleteDataFromService(`${AdminApi.Word}/${word}?${langString}`);
  }

  public async getFullVerb(verb: string) {
    const langString = this.userService.getUserLangOptions();
    return this.httpService.getDataFromService<EnglishVerb>(`${AdminApi.Verb}/${verb}?${langString}`);
  }

  public async postVerb(verb: EnglishVerb) {
    const langString = this.userService.getUserLangOptions();

    return this.httpService.postDataToService<EnglishVerb>(`${AdminApi.UpdateKnowledge}/verb?${langString}`, verb);
  }

  public async updateWord(word: Partial<LangWord>, target = null) {
    let langString = this.userService.getUserLangOptions();
    if (target) {
      langString = `target=${target}`;
    }

    return this.httpService.postDataToService<LangWord>(`${AdminApi.UpdateKnowledge}?${langString}`, word);
  }

  public async postPromoCode(code: PromoCode | any) {
    const langString = this.userService.getUserLangOptions();

    return this.httpService.postDataToService<any>(`${AdminApi.PromoCode}?${langString}`, code);
  }

  public async getFilteredWords(filter: FiltersConfig, langCode: string | null = null): Promise<IFilterQueryResponse<LangWord>> {
    if (!langCode) {
      const langString = this.userService.getUserLangOptions();
      return this.httpService.postDataToService<IFilterQueryResponse<LangWord>>(`${AdminApi.WordsFiltered}?${langString}`, filter);
    }
    return this.httpService.postDataToService<IFilterQueryResponse<LangWord>>(`${AdminApi.WordsFiltered}?target=${langCode}`, filter);
  }

  private openEditWord(word: LangWord) {
    return this.dialogService.open(WordFormComponent, {
      data: { englishWord: word, mode: WordFormMode.Admin },
      closable: true,
      width: '70vw',
      baseZIndex: 1000,
      modal: true,
    });
  }
}
