<div>
  <div>Formulario verbo</div>

  <div>
    <form [formGroup]="formGroup">
      <div class="grid">
        <div class="sm:col-6">
          <div class="form-group">
            <label class="label"
              >Verbo Base
              <!-- <nb-icon icon="info-outline" nbTooltip="Verbo a utilizar en presente, ej. eat,do,create"></nb-icon> -->
            </label>
            <input formControlName="word" type="text" nbInput fullWidth placeholder="Verbo" />
          </div>
        </div>
        <div class="sm:col-6">
          <div class="form-group">
            <label class="label"
              >Pasado
              <!-- <nb-icon icon="info-outline" nbTooltip="Verbo en tiempo pasado, ej. ate, did, created"></nb-icon> -->
            </label>
            <input formControlName="past" type="text" nbInput fullWidth placeholder="Pasado" />
          </div>
        </div>
      </div>
      <div class="grid">
        <div class="sm:col-6">
          <div class="form-group">
            <label class="label">Participio </label>
            <input formControlName="participle" type="email" nbInput fullWidth placeholder="Participio" />
          </div>
        </div>

        <div class="sm:col-6">
          <div class="form-group">
            <label for="inputWebsite" class="label"
              >traducción
              <!-- <nb-icon
              icon="info-outline"
              nbTooltip="La mejor traducción al español del verbo, ej. comer, hacer, crear"
            ></nb-icon> -->
          </label>
          <input formControlName="translation" type="text" nbInput fullWidth placeholder="traducción" />
        </div>
      </div>
    </div>

    <div class="grid">
      <!-- <div class="col-6" *ngIf="formGroup.controls['score']">
      <div class="form-group">
        <label class="label">Calificación
          <nb-icon icon="info-outline" nbTooltip="Tu puntaje de dominio de esta palabra"> </nb-icon>
        </label>
        <nb-select placeholder="Selecciona" formControlName="score">
          <nb-option [value]="0">0%</nb-option>
          <nb-option [value]="1">10%</nb-option>
          <nb-option [value]="2">20%</nb-option>
          <nb-option [value]="3">30%</nb-option>
          <nb-option [value]="4">40%</nb-option>
          <nb-option [value]="9">90%</nb-option>
          <nb-option [value]="10">100%</nb-option>
        </nb-select>
      </div>
    </div> -->

    <div class="col-6">
      <div class="form-group">
        <label class="label"
          >Frecuencia
          <!-- <nb-icon icon="info-outline" nbTooltip="Que tan usuda es ésta palabra"> </nb-icon> -->
        </label>
        <!-- <nb-select placeholder="Selecciona" formControlName="useFrequency">
        <nb-option [value]="0">1 - Primera vez</nb-option>
        <nb-option [value]="2">2 - Memorizando </nb-option>
        <nb-option [value]="5">3 - Poco Frecuente </nb-option>
        <nb-option [value]="7">4 - Utilizada</nb-option>
        <nb-option [value]="9">5 - Gran Dominio</nb-option>
      </nb-select> -->
    </div>
  </div>
</div>

<div class="grid">
  <div class="col-6">
    <div class="form-group">
      <label class="label"
        >Es Irregular
        <!-- <nb-icon
        icon="info-outline"
        nbTooltip="marca si es un verbo irregular, no respeta las reglas de conjugación, generalmente no termina en 'ed' "
        >
      </nb-icon> -->
    </label>
    <br />
    <nb-checkbox formControlName="isIrregular" status="basic">¿Irregular? </nb-checkbox>
  </div>
</div>

<div class="col-6">
  <div class="form-group">
    <label class="label"
      >Es Frasal
      <!-- <nb-icon
      icon="info-outline"
      nbTooltip="Un verbo frasal se compone de verbo + adjetivo, ej, stand up, get out, run away"
      >
    </nb-icon> -->
  </label>
  <br />
  <nb-checkbox formControlName="isPhrasal" status="basic">Frasal?</nb-checkbox>
</div>
</div>
</div>

<div class="grid">
  <div class="col-12">
    <div class="form-group">
      <label class="label"
        >Explicación
        <!-- <nb-icon icon="info-outline" nbTooltip="Explicación en español de como usar apropiadamente este verbo">
      </nb-icon> -->
    </label>
    <textarea formControlName="spanishExplanation" type="text" nbInput fullWidth placeholder="Explicación, cómo usarlo etc."></textarea>
  </div>
</div>
</div>

<div class="form-group" formArrayName="definitions">
  <label class="label"
    >Definitions
    <!-- <nb-icon icon="info-outline" nbTooltip="Definición del verbo"> </nb-icon> -->
  </label>

  <!-- <div *ngFor="let item of definitionFormArray.controls; let i = index" #definitionsForm>
  <nb-form-field class="mar-top">
    <input type="text" nbInput fullWidth [formControlName]="i" placeholder="Escribe ejemplo..." />

    <button nbSuffix nbButton ghost (click)="deleteFormArrayByIndex('definitions', i)">
      <nb-icon icon="close-circle-outline" pack="eva" attr.aria-label="eliminar"></nb-icon>
    </button>
  </nb-form-field>
</div> -->

<div class="grid">
  <div class="col-6">
    <button class="mar-top" size="tiny" (click)="pushControlToFormArray('definitions')" nbButton fullWidth status="info"> Agregar definición </button>
  </div>
  <div class="col-6"></div>
</div>
</div>

<div class="form-group" formArrayName="examples">
  <label class="label"
    >Ejemplos
    <!-- <nb-icon icon="info-outline" nbTooltip="Ejemplo dónde se utilize este verbo (cualquier tiempo verbal)"> </nb-icon> -->
  </label>
  <!-- <div *ngFor="let item of exampleFormArray.controls; let i = index">
  <nb-form-field class="mar-top">
    <input type="text" nbInput fullWidth [formControlName]="i" placeholder="Escribe ejemplo..." />

    <button nbSuffix nbButton ghost (click)="deleteFormArrayByIndex('examples', i)">
      <nb-icon icon="close-circle-outline" pack="eva" attr.aria-label="eliminar"></nb-icon>
    </button>
  </nb-form-field>
</div> -->

<div class="grid">
  <div class="col-6">
    <button class="mar-top" size="tiny" (click)="pushControlToFormArray('examples')" nbButton fullWidth status="info"> Agregar ejemplo </button>
  </div>
  <div class="col-6"></div>
</div>
</div>

<div class="form-group" formArrayName="synonyms">
  <label class="label"
    >Sinónimos
    <!-- <nb-icon icon="info-outline" nbTooltip="Escribe algunos sinónimos"> </nb-icon> -->
  </label>
  <!-- <div *ngFor="let item of synonymFormArray.controls; let i = index">
  <nb-form-field class="mar-top">
    <input type="text" nbInput fullWidth [formControlName]="i" placeholder="Escribe ejemplo..." />

    <button nbSuffix nbButton ghost (click)="deleteFormArrayByIndex('synonyms', i)">
      <nb-icon icon="close-circle-outline" pack="eva" attr.aria-label="eliminar"></nb-icon>
    </button>
  </nb-form-field>
</div> -->

<div class="grid">
  <div class="col-6">
    <button class="mar-top" size="tiny" (click)="pushControlToFormArray('synonyms')" nbButton fullWidth status="info"> Agregar sinonimo </button>
  </div>
  <div class="col-6"></div>
</div>
</div>

<div class="form-group" formArrayName="otherTranslations">
  <label class="label"
    >Otras traducciones
    <!-- <nb-icon icon="info-outline" nbTooltip="Traducciones opcionales para este verbo"> </nb-icon> -->
  </label>
  @for (item of otherTranslationFormArray.controls; track item; let i = $index) {
    <div>
      <!-- <nb-form-field class="mar-top">
      <input type="text" nbInput fullWidth [formControlName]="i" placeholder="Escribe ejemplo..." />
      <button nbSuffix nbButton ghost (click)="deleteFormArrayByIndex('otherTranslations', i)">
        <nb-icon icon="close-circle-outline" pack="eva" attr.aria-label="eliminar"></nb-icon>
      </button>
    </nb-form-field> -->
  </div>
}
<div class="grid">
  <div class="col-6">
    <button class="mar-top" size="tiny" (click)="pushControlToFormArray('otherTranslations')" nbButton fullWidth status="info">
      Agregar otra traducción
    </button>
  </div>
  <div class="col-6"></div>
</div>
</div>
</form>
</div>

<div class="align-button">
  @if (englishVerb()?.modifiedDate || englishVerb()?.isCustom === false) {
    <button
      class="margin"
      nbButton
      status="info"
      (click)="promoteVerb()"
      nbTooltip="Comparte el verbo, se válidará para ser agregado a la base de datos"
      nbTooltipStatus="primary">
      Promover
    </button>
  }
  <button nbButton status="primary" (click)="save()">{{ this.buttonText }}</button>
</div>
</div>
