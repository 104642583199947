import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, Validators, FormsModule, ReactiveFormsModule } from '@angular/forms';

import { ButtonModule } from 'primeng/button';
import { InputTextModule } from 'primeng/inputtext';
import { DynamicDialogRef } from 'primeng/dynamicdialog';

import { ComponentBuilder } from '../../ComponentBuilder';

@Component({
  selector: 'app-text-writer-buider',
  templateUrl: './text-writer-buider.component.html',
  styleUrls: ['./text-writer-buider.component.scss'],
  standalone: true,
  imports: [FormsModule, ReactiveFormsModule, ButtonModule, InputTextModule],
})
export class TextWriterBuiderComponent extends ComponentBuilder implements OnInit {
  constructor(
    protected override formBuilder: UntypedFormBuilder,
    // protected override toastrService: ToastService,
    protected override ref: DynamicDialogRef,
  ) {
    super(formBuilder, null);
  }

  override formGroup = this.formBuilder.group({ response: ['', Validators.required], hint: [], explanation: [] });

  ngOnInit(): void {}
}
