import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnInit, input, inject } from '@angular/core';
import { FormBuilder, FormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DcFlashcardComponent } from '@dataclouder/dc-flashcard';
import { FiltersConfig } from '@dataclouder/ngx-core';
import { ButtonModule } from 'primeng/button';
import { InputTextModule } from 'primeng/inputtext';
import { SelectModule } from 'primeng/select';
import { AdminService } from 'src/app/core/data-services/admin.service';
import { UserService } from 'src/app/core/data-services/user.service';
import { LangCodeDescription, LangWord } from 'src/app/core/lexicore.classes';
import { ToastService } from 'src/app/core/system/toast.service';

@Component({
  selector: 'app-word-flashcard-form',
  imports: [InputTextModule, ReactiveFormsModule, DcFlashcardComponent, ButtonModule, SelectModule, FormsModule, InputTextModule],
  template: `
    <h4>Flashcard</h4>

    <div style="display: flex; gap: 10px">
      <p-button label="Clonar de otro idioma" icon="pi pi-plus" (click)="cloneFromOtherLanguage()" [disabled]="!word"></p-button>

      <input [(ngModel)]="selectedWord" type="text" pInputText placeholder="Enter a word" />
      <p-select [options]="languageOptions" optionValue="value" optionLabel="label" [(ngModel)]="selectedLanguage" placeholder="Select a Language" />
    </div>

    @if (!reloading){
    <dc-flashcard-form [word]="word" />
    }
  `,
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
})
export class WordFlashcardFormComponent implements OnInit {
  private formBuilder = inject(FormBuilder);
  private adminService = inject(AdminService);
  private toastService = inject(ToastService);
  private userService = inject(UserService);
  private cdr = inject(ChangeDetectorRef);

  @Input() public word: LangWord;
  public readonly formGroup = input(this.formBuilder.group({
    flashcard: [''],
    otherTranslations: this.formBuilder.array<FormGroup<string>>([]),
}));

  languageOptions = Object.entries(LangCodeDescription).map(([key, value]) => ({ label: value, value: key }));
  selectedLanguage = null;
  selectedWord = null;
  reloading = false;

  /** Inserted by Angular inject() migration for backwards compatibility */
  constructor(...args: unknown[]);
  constructor() {}
  ngOnInit(): void {
    if (this.word.en) {
      this.selectedLanguage = 'en';
      this.selectedWord = this.word.en.translation;
    }
  }
  async cloneFromOtherLanguage() {
    const message = `Por favor, confirma, se clonará la flashcard "${this.selectedWord}" del diccionario en  "${LangCodeDescription[this.selectedLanguage]}"`;
    const isConfirm = confirm(message);
    if (isConfirm) {
      const filters: FiltersConfig = { filters: { word: this.selectedWord }, returnProps: { flashcards: 1 } };
      const words = await this.adminService.getFilteredWords(filters, this.selectedLanguage);
      if (!words.rows.length) {
        alert(`No se encontró esta palabra ${this.selectedWord} en el otro idioma ${LangCodeDescription[this.selectedLanguage]}`);
        return;
      }
      if (!words.rows[0].flashcards) {
        const warningMessage = `Parece que esta palabra ${this.selectedWord} aún no tiene flashcards en el idioma ${
          LangCodeDescription[this.selectedLanguage]
        }`;
        alert(warningMessage);
        return;
      }
      this.word.flashcards = words.rows[0].flashcards;
      const update: Partial<LangWord> = { word: this.word.word, flashcards: this.word.flashcards };
      const target = this.userService.getUserSnapshot().settings.targetLanguage;
      const result = await this.adminService.updateWord(update, target);

      this.word = { ...this.word, flashcards: this.word.flashcards };
      console.log('result', result);
      this.reloading = true;
      this.cdr.detectChanges();

      setTimeout(() => {
        this.reloading = false;
        this.cdr.detectChanges();
      }, 600);

      this.toastService.success(`Se clonaron las flashcards de la palabra ${this.selectedWord} 
        del idioma ${LangCodeDescription[this.selectedLanguage]} recarga para visualizar cambios.`);
      console.log('words', words);
      return;
    } else {
      alert('Se esta trabajando en esta funcionalidad....');
    }

    // 1) Solicitud query al diccionario especificado solo trayendo flashcard.
    // 2)  Pasar la flashcard a la información.
  }
}
