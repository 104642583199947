import { Injectable, inject } from '@angular/core';
import {
  IConversationSettings,
  AgentCardsAbstractService,
  ChatUserSettings,
  IAgentCard,
  ConversationMessagesDTO,
  TranscriptionsWhisper,
  IAgentResponseDTO,
  IAIModel,
} from '@dataclouder/ngx-agent-cards';
import { FiltersConfig, IFilterQueryResponse } from '@dataclouder/ngx-core';

import { AIAPI, ChatRole, Endpoints } from '../enums';
import { UserService } from './user.service';
import { AudioSpeedRating } from '../constants';
import { HttpService } from '../system/http.service';
import { ToastService } from '../system/toast.service';

export type AudioGenerated = { blobUrl: string; transcription: any };
export type TTSRequest = { text: string; voice: string; generateTranscription: boolean; speedRate: number; speed?: string; ssml?: string };

@Injectable({
  providedIn: 'root',
})
export class AgentCardsService implements AgentCardsAbstractService {
  private httpService = inject(HttpService);
  private userService = inject(UserService);
  private toastService = inject(ToastService);

  /** Inserted by Angular inject() migration for backwards compatibility */
  constructor(...args: unknown[]);

  constructor() {}

  public async findAgentCardByTitle(title: string): Promise<IAgentCard> {
    const filters: FiltersConfig = { filters: { title } };
    const response = await this.httpService.postDataToService<IFilterQueryResponse<IAgentCard>>(`${Endpoints.ConversationCard.ConversationQuery}`, filters);
    return response.rows[0];
  }

  public async findConversationByTitle(title: string): Promise<IAgentCard> {
    const filters: FiltersConfig = { filters: { title } };
    const response = await this.httpService.postDataToService<IFilterQueryResponse<IAgentCard>>(`${Endpoints.ConversationCard.ConversationQuery}`, filters);
    return response.rows[0];
  }

  public async getAudioTranscriptions(audioBlob: Blob, metadata: any = null): Promise<TranscriptionsWhisper> {
    return await this.httpService.uploadAudioFile(`${AIAPI.Whisper}`, audioBlob, metadata, 'python');
  }

  public async findFilteredAgentCards(paginator: FiltersConfig) {
    const user = this.userService.getUserSnapshot();

    paginator.filters['lang'] = user?.settings?.targetLanguage;
    paginator.filters['metaApp.isPublic'] = true;
    const response = await this.httpService.postDataToService<IFilterQueryResponse<IAgentCard>>(`${Endpoints.ConversationCard.ConversationQuery}`, paginator);
    return response;
  }

  public async getListModels(provider: string): Promise<any> {
    const data = await this.httpService.getDataFromService(`${Endpoints.ConversationCard.ListModels}?provider=${provider}`, 'python');
    return data;
  }

  public async callChatCompletion(conversation: IConversationSettings | ConversationMessagesDTO): Promise<IAgentResponseDTO> {
    let messages = conversation.messages.map((m) => ({ content: m.content, role: m.role }));
    messages = messages.filter((m) => m.role != ChatRole.AssistantHelper);
    const conversationFiltered = { ...conversation, messages };

    // return await this.httpService.postDataToService(`${AIAPI.GenerateChat}`, conversationFiltered, 'python');
    return await this.httpService.postDataToService(`${Endpoints.ConversationCard.AgentChat}`, conversationFiltered, 'python');
  }

  public async callInstruction(prompt: string, model: IAIModel = {}): Promise<any> {
    const messages = [{ content: prompt, role: ChatRole.User }];
    const conversationDto = { messages, model };
    return await this.httpService.postDataToService(`${Endpoints.ConversationCard.AgentChat}`, conversationDto, 'python');
  }

  async translateConversation(currentLang: string, targetLang: string, idCard: string): Promise<ChatUserSettings> {
    const response = await this.httpService.postDataToService(
      `${Endpoints.ConversationCard.TranslateConversation}`,
      { currentLang, targetLang, idCard },
      'python',
    );

    return response;
  }

  async getConversationUserChatSettings(): Promise<ChatUserSettings> {
    const settings = (await this.userService.getUserSnapshot().settings.conversation) as ChatUserSettings;
    return settings;
  }

  async saveConversationSettings(settings: IAgentCard) {
    const response = await this.httpService.postDataToService('api/conversation-ai/settings', settings);
    return response;
  }

  async saveConversationCard(conversation: IAgentCard): Promise<IAgentCard> {
    if (conversation.id || conversation._id) {
      return await this.httpService.putDataToService(`${Endpoints.ConversationCard.Conversation}/${conversation._id}`, conversation);
    } else {
      // Add default target language.
      conversation.lang = this.userService.getUserSnapshot().settings.targetLanguage;
      return await this.httpService.postDataToService(`${Endpoints.ConversationCard.Conversation}`, conversation);
    }
  }

  async deleteConversationCard(id: string): Promise<any> {
    return await this.httpService.deleteDataFromService(`${Endpoints.ConversationCard.Conversation}/${id}`);
  }

  async findConversationCardByID(id: string): Promise<IAgentCard> {
    return await this.httpService.getDataFromService(`${Endpoints.ConversationCard.Conversation}/${id}`);
  }

  async filterConversationCards(filters: FiltersConfig): Promise<IFilterQueryResponse<IAgentCard>> {
    return await this.httpService.postDataToService(`${Endpoints.ConversationCard.ConversationQuery}`, filters);
  }

  async getAllConversationCards(): Promise<any[]> {
    return await this.httpService.getDataFromService(`${Endpoints.ConversationCard.Conversation}`);
  }

  // TODO: necesito ponerle un tipo al return.

  public async getTextAudioFile(tts: TTSRequest): Promise<AudioGenerated> {
    const httpReq: any = await this.httpService.postFile(`${AIAPI.GetTTS}`, tts, 'python');
    const audioData = { blobUrl: null, transcription: null };

    const transcription = httpReq?.headers.get('transcription');

    if (transcription) {
      const data = JSON.parse(transcription);
      audioData.transcription = data;
    }

    const mp3 = window.URL.createObjectURL(httpReq.body);
    audioData.blobUrl = mp3;

    return audioData;
  }

  async saveConversationUserChatSettings(newSettings: any): Promise<any> {
    // TODO: deberia tener un metodo updateUserSettings() pero mientras tengo que pasar todo el objeto settings en vez de solo conversation
    const settings = { ...this.userService.getUserSnapshot().settings };
    const conversation: any = newSettings;

    conversation.speed = AudioSpeedRating[conversation.speed ?? 3];

    settings.conversation = conversation;
    await this.userService.updateUser({ id: this.userService.getUserSnapshot().id, settings });

    this.toastService.success('Se actualizaron las configuraciones', 'Listo');
  }
}
