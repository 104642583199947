import { Injectable, inject } from '@angular/core';
import { IAgentCard } from '@dataclouder/ngx-agent-cards';
import { NotionAbstractService, NotionDBResponse, NotionExportType, NotionPageResponse } from '@dataclouder/ngx-lessons';
import { Endpoints } from 'src/app/core/enums';

import { HttpService } from './system/http.service';

@Injectable({
  providedIn: 'root',
})
export class NotionService implements NotionAbstractService {
  private httpService = inject(HttpService);

  /** Inserted by Angular inject() migration for backwards compatibility */
  constructor(...args: unknown[]);

  constructor() {}

  public getDBAvailible(): Promise<NotionDBResponse> {
    return this.httpService.getDataFromService(Endpoints.Notion.ListDBs);
  }

  public getPagesAvailable(): Promise<NotionPageResponse> {
    return this.httpService.getDataFromService(Endpoints.Notion.ListPages);
  }

  public createNotionPage(card: IAgentCard): Promise<{ success: boolean; error: string; page: any }> {
    return this.httpService.getDataFromService(`${Endpoints.Notion.CreatePage}/${card.id}`);
  }

  public async getPageInSpecificFormat(pageId: string, format: NotionExportType): Promise<any> {
    const data = await this.httpService.getDataFromService(`${Endpoints.Notion.PageInSpecificFormat}/${pageId}?exportType=${format}`);
    return data;
  }

  public async improveNotionWithAI(pageId: string): Promise<any> {
    const md = await this.getPageInSpecificFormat(pageId, NotionExportType.HTML);

    console.log(md);
    const prompt = `
    Mejorar el contenido de la página de Notion con el siguiente contenido:
    ${md}
    `;

    // const data = await this.httpService.postDataToService(`${Endpoints.Notion.ImproveNotionWithAI}/${pageId}`, md);
    // const data = await this.httpService.getDataFromService(`${Endpoints.Notion.ImproveNotionWithAI}/${pageId}`);
    // return data;
    return null;
  }
}
