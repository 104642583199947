import { FormArray, FormControl, FormGroup } from '@angular/forms';
import { ActionPromoCode, AudioSpeed, ChatRole, PermissionType, StateStep, PlanType, VerbTense, TextEngines } from './enums';
import { LessonComponentConfiguration } from '../pages/lessons/components/lessons.clases';

class AuditData {
  createdDate: string;
  modifiedDate: string;

  isCustom: boolean; // cuando una palabra es creada por el usuario o es nueva para el sistema
}

class UserExtraData extends AuditData {
  progress: number;
  completed: boolean;
}

export class GeneralWord extends UserExtraData {
  public id?: string;
  public word: string;
  public useFrequency: number;

  public examples: Array<string>;
  public synonyms: Array<string>;
  public definitions: Array<string>;

  public translation: string;
  public spanishExplanation: string;

  public otherTranslations: Array<string>;
  public learningExamples: Array<LearningExample>;
  public audios: Array<AudioStorage>;
  public ipaPhonetic: string;

  constructor() {
    super();
    this.examples = [];
  }
}

export class LearningExample {
  _id: string; // id del learningExample de mongo.
  id: string; // todo estoy revisando estrategia cual me conviene tener.
  word?: string; // TODO: Sería más facil si word existe aunque este duplicado.
  attribution: string;
  audio: { bucket: string; path: string; url: string; text: string };
  image: { bucket: string; path: string; url: string; text: string };
  example: string;
  // translation: string;
  // spanishExplanation: string;

  meaning: string;
  scenario: string;
  tense: VerbTense;
  generatedByAI: boolean;
  author: string;
  difficultyLevel: string;
  isPublic: boolean;
  takenCount: number;
  meaningTranslation?: any; // TODO: Eliminar cuando pueda
  translation?: any; // eliminar cuando pueda
}

export class LangWord extends GeneralWord {
  wordType: WordType;

  constructor(data: any = {}) {
    super();
    Object.assign(this, data);
  }
}

export class EnglishVerb extends GeneralWord {
  public verb: string;
  public past: string;
  public participle: string;
  public isIrregular: boolean;

  constructor(data: any = {}) {
    super();
    Object.assign(this, data);
  }
}

export class EnglishPhrase extends UserExtraData {
  id: string;
  phrase: string;
  translation: string;
  otherTranslations: Array<any>;
  context: string;
  tags: Array<string>;

  constructor(data: any = {}) {
    super();
    Object.assign(this, data);
  }
}

enum WordType {
  noun = 'sustantivo',
  adjective = 'adjetivo',
  pronoun = 'pronombre',
  adverb = 'adverbio',
  preposition = 'preposition',
  conjuction = 'conjuction',
  injection = 'injection',
  unknown = '',
}

export class ConversationUserSettings {
  realTime: boolean;
  repeatRecording: boolean;
  fixGrammar: boolean;
  superHearing: boolean;
  voice: string;
  autoTranslate: boolean;
  highlightWords: boolean;
  synthVoice: boolean;
  modelName: string;
  provider: string;
  speed: string;
  speedRate: number; // temporal
}

export class ConversationChatSettings {
  messages?: ChatMessage[];
  last_prompt?: string;
  conversationType?: ConversationType;
  textEngine?: string;
  voice?: string; // first voice
  secondaryVoice?: string; // apply for narrator
  overrideConversationSettings?: Partial<ConversationUserSettings>;
}

export class ConversationDTO {
  id: string;
  entityId: string; // LearningExample or Scenario id
  type: ConversationType;
  createdAt?: Date;
  messages: Array<ChatMessage>;
  transcription?: boolean;
  modelName?: string;
  provider?: string;
  returnJson?: boolean;
}

export class UserSettings {
  wordsNumber: number;
  enableNotifications: boolean;
  audioSpeed: AudioSpeed;
  conversation: ConversationUserSettings;
  targetLanguage: string;
  baseLanguage: string;
}

export class FrontendSteps {
  introTaken?: StateStep;
  homeTourState?: StateStep;
  verbsTourState?: StateStep;
}

export class UserSubscription {
  public plan: string;
  start: string;
  end: string;
}

type UserLesson = { lessonId: string; score: number; status: string; intentionStatus: string };

type RecommendedWords = { updatedDate: any; words: Array<string> };

export interface PersonalData {
  firstname: string;
  lastname: string;
  gender: string;
  birthday: string | Date;
}

export class UserStats {
  points: number;
  redeemablePoints: number;
}

export class LanguageProgress {
  lang: string;
  level: string;
}

export class User {
  public id: string;
  public personalData: PersonalData;
  public email;
  public authStrategy: string;
  public gender;
  public urlPicture;
  public settings: UserSettings;
  public frontendSteps: FrontendSteps;
  public claims: AppAuthClaims;
  public recommendations: {
    verbs: { updatedDate: Date; verbs: any };
    words: { updatedDate: Date; words: any };
    lessons: { updatedDate: Date; lessons: any };
    scenarios: { updatedDate: Date; scenarios: any };
  };
  public languageProgress: Record<string, LanguageProgress>;
  public news: Array<any>;
  public lessons: Array<UserLesson>;
  public stats: UserStats;

  public devices: Array<{ agent: string; token: string }>;

  public students?: Array<any>; // optional user students.

  constructor(email: string, firstname: string, authStrategy: string, urlPicture: string) {
    this.personalData.firstname = firstname;
    this.authStrategy = authStrategy;
    this.email = email;
    this.urlPicture = urlPicture;
  }
}

export class Translation {
  public definitions?: Array<Definition>;
  public otherTranslations: Array<any>;
  public translation: string;
  public synonyms: Array<string>;
  public useFrecuency: number;
  public word: string;
}

interface Definition {
  example: string;
  meaning: string;
  number: string;
  wordType: string;
}

export interface StorageFile {
  bucket: string;
  url: string;
  path: string; // path where the file is in the storage
}

export interface AudioStorage extends StorageFile {
  text?: string;
  voiceType?: string;
}

// new version of ImgFirebase, Replace ImgData
export interface ImgStorageData extends StorageFile {
  fullPath: string; // path + name
  resolutions: any;
  resolution: string;
}

export interface PayloadFirebase {
  name: string;
  email: string;
  email_verified: boolean;
  picture: string;
  user_id: string;
}

export type LessonImage = ImgStorageData & { type: string };

export class Lesson extends AuditData {
  id: string;
  title: string;
  description: string;
  textCoded: string;
  authorId: string;
  authorEmail: string;
  tags: Array<string>;
  isPublished: boolean;
  isPrivate: boolean;
  comments: Array<Comment>;
  media: { audios?: Array<LessonImage>; images?: Array<LessonImage>; videos?: Array<LessonImage> };
  components: Array<LessonComponentConfiguration>;
  generatedByAI: boolean;
  prompt: string;
  level: number;

  constructor(data: any = {}) {
    super();
    this.isPublished = false;
    this.isPrivate = false;
    Object.assign(this, data);
  }
}

export enum ScenarioType {
  General = 'general',
  Reflection = 'lesson',
  LearningExample = 'learningExample',
  Challenge = 'challenge',
}

export interface CharaCard {
  name: string;
  description: string;
  scenario: string;
  first_mes: string;
  creator_notes: string;
  mes_example: string;
  alternate_greetings: string[];
  tags: string[];
  system_prompt: string;
  post_history_instructions: string;
}

export interface IScenario extends AuditData {
  _id: any;
  id: string;
  textEngine: TextEngines;
  scenarioType: ScenarioType;

  card: CharaCard;
  title: string;
  image: any;
  voice: string;
  secondaryVoice: string;
  isPublished: boolean;
  authorId: string;
  authorEmail: string;
  takenCount: number;
  lang: string;
  isPublic: any;
}

export interface LessonCompSettings {
  response: string;
  responses: string; // en caso de que haya multiples respuestas
  options: string[]; // optiones para visualizar
  text: string;
  hint: string;
  explanation: string;
}

export class LessonWithTaken extends Lesson {
  taken: any;
}

export class Topic extends AuditData {
  public id?: string;
  public isPublished: string;
  public userAuthor: string;
  public userIdAuthor: string;
  public title: string;
  public description: string;
  public votes: string;
  public comments: string;
  public words: Array<LangWord>;
  public verbs: Array<EnglishVerb>;

  constructor(title: string, descripction: string, userIdAuthor: string = null, userAuthor: string = null) {
    super();
    this.title = title;
    this.description = descripction;
    this.userIdAuthor = userIdAuthor;
    this.userAuthor = userAuthor;
  }
}

export enum LearningActivityType {
  ShadowingLearningExample = 'shadowingLearningExample',
  WordFlashCard = 'wordFlashCard',
  VerbFlashCard = 'verbFlashCard',
  ReflectionWord = 'reflectionWord',
  WordLearningExampleConversation = 'wordLearningExampleConversation',
}
// Este objeto es para encapsular el progreso en el formulario reactivo

export interface SentenceData {
  learningExampleId: string;
  difficultyLevel: string;
  score: number;
}
export class ProgressWordEvaluation {
  public word: string;
  public progress?: number; // current progress of the word.
  public completed?: boolean;

  public ok?: boolean; // paso la prueba en la evaluación
  public revealed?: boolean; // particular de las flashWordsCard, cuando se revela la respuesta
  public learningActivity?: LearningActivityType;
  public activityId?: string; // change depending learningExampleId

  public points?: number; // puntos de usuario.
  public sentenceData?: SentenceData; // particular de las practiceSentence, cuando se revela la respuesta

  constructor(data: ProgressWordEvaluation) {
    Object.assign(this, data);
  }
}

export class ProgressWordResponseDto extends ProgressWordEvaluation {
  public justCompleted?: boolean;
}

export class ProgressResultsDto {
  points: number;
  progress: ProgressWordResponseDto[];
}

export class BotPath {
  // word: BotPathResults;
  examples: BotPathResults;
  synonyms: BotPathResults;
  definitions: BotPathResults;
  translation: BotPathResults;
  spanishNote: BotPathResults;
  intro: BotPathResults;

  constructor(intro: boolean, examples: boolean, synonyms: boolean, definitions: boolean, translation: boolean, spanishNote: boolean) {
    this.examples = { show: examples, isDone: false };
    this.synonyms = { show: synonyms, isDone: false };
    this.definitions = { show: definitions, isDone: false };
    this.translation = { show: translation, isDone: false };
    this.spanishNote = { show: spanishNote, isDone: false };
    this.intro = { show: spanishNote, isDone: false };
  }
}

export class AppExeption {
  error_message: string;
  explanation?: string;
  code: number;
  method?: string;
  obj?: any;
}

// TODO change name to mongo results
export interface DeletedData {
  id: string;
  collection: string;
  message: string;
  time: string;
}

export interface BotPathResults {
  show: boolean;
  isDone: boolean;
}

export enum AppEntity {
  Word = 'word',
  LearningExample = 'learningExample',
  Verb = 'verb',
  Lesson = 'lesson',
}

enum CommentType {
  Feedback = 'feedback',
  Regular = 'regular',
}

export class RemovableComment {
  entity: AppEntity;
  entityId: string;
  child?: RemovableComment;
}

export interface AppComment {
  createdDate: string | number | Date;
  id: string;
  comment: string;
  userId?: string;
  userName?: string;
  userImage?: string;
  type: CommentType;
}

export interface AppPaginator {
  page: number;
  rowsPerPage: number;
  sort?: Record<string, any>;
  filters?: Record<string, any>;
  text?: string;
}

// TODO creo que todavia no utilizo este, agregar el wordForm learningExamples
export interface LearningExampleForm {
  example: FormControl<string | null>;
  translation: FormControl<string | null>;
  spanishExplanation: FormControl<string | null>;
  image: FormControl<any | null>;
  attribution: FormControl<string | null>;
  audio: FormControl<any | null>;
  voiceType: FormControl<string | null>;
}

export type WordForm = FormGroup<{
  word: FormControl<string>;
  translation: FormControl<string | null>;
  score: FormControl<number | null>;
  useFrequency: FormControl<number | null>;
  spanishExplanation: FormControl<string>;
  type: FormControl<string | null>;
  examples: FormArray<any>;
  synonyms: FormArray<any>;
  definitions: FormArray<any>;
  otherTranslations: FormArray<any>;
  learningExamples?: FormArray<any>;
}>;

export interface SynthesizedAudio extends StorageFile {
  text: string;
  voiceType: string;
}

export interface ChatCompletionAudio {
  audio: SynthesizedAudio;
  role: string;
  content: string;
}

export interface ReactionAggregated {
  count: number;
  byUser: number;
}
export enum RolType {
  Admin = 'admin',
  Teacher = 'teacher',
  Tester = 'tester',
}

interface Permission {
  type: any;
  exp: number;
}
export type RolClaim = Record<RolType, any>;

export interface AppAuthClaims {
  plan: SubscriptionClaim;
  permissions: PermissionClaim;
  roles: RolClaim;
}

export type PermissionClaim = Record<PermissionType, { exp: Date; num: number }>;

export type SubscriptionClaim = { type: PlanType; exp?: number };

export interface PromoCode {
  code: string;
  action: ActionPromoCode; // 'freePlan' | 'freePermissions' | 'planDiscount'
  days?: number; // if freePlan or freePermissions
  discount?: number; // if Plandiscount
  expireDate: number; // timestamp when the code expires
  maxUses?: number; // how many times the code can be used
  used?: number; // how many times the code has been used
  permissions?: Array<PermissionType>;
}

export enum WordFilterCategory {
  CEFRLevel = 'cefrLevel',
  UseFrequency = 'useFrequency',
}

export class RandomFilter {
  nWords: number;
  by: WordFilterCategory; // level, useFrequency
  values: any | any[]; // 'A1' o ['A2', 'A2']
}

export enum ConversationType {
  LearningExample = 'learningExample',
  Scenario = 'scenario',
  WordReflection = 'wordReflection',
  Lesson = 'lesson',
}

export class WordTimestamps {
  word: string;
  start: number;
  end: number;
  highlighted: any;
}

export class ChatMultiMessage {
  voice: string;
  content: string;
  text: string;
  audioUrl: string;
  audioPromise: any;
  isLoading?: boolean;
  transcription?: any;
  transcriptionTimestamps?: WordTimestamps[];
  tag: string | string[] | Set<string> | { [klass: string]: any };
}

export class ChatMessage {
  content: string; // Contenido del mensaje en el formato que venga.
  role: ChatRole; // Assistant | User
  ssml?: string;
  text?: string; // extraccion simple sin formato
  translation?: string;
  audioUrl?: string;
  audioHtml?: HTMLAudioElement; // this is to get a reference to node so i can subscribe to events
  promisePlay?: any; // promise to play the audio
  stats?: any;
  multiMessages?: ChatMultiMessage[]; //
  transcription?: TranscriptionsWhisper; // data i got from whisper
  transcriptionTimestamps?: WordTimestamps[]; // array of words with start and end time
  voice?: string;
}

export type TranscriptionsWhisper = { text: string; task: string; language: string; words: any; duration: number };

// export class Completion {
//   messages: Array<ChatMessage>;
// }

export interface WordsMetadataByStatus {
  alreadyKnownWords: string[];
  toLearnWords: string[];
}
