import { createReducer, on } from '@ngrx/store';

import { VerbActions, VerbsState } from '.';
import { EnglishVerb } from '../../classes';
import { hydrateStorage, getStorageRehydration, StorageStateEnum } from '../app.state';
// Note todas las acciones rehidratan el storage, revisar el perfomarnce de esto

const verbsStorage = getStorageRehydration<EnglishVerb[]>(StorageStateEnum.Verbs);

export const verbsInitialState: VerbsState = {
  verbs: verbsStorage,
  error: null,
  isLoaded: false,
  isRehydrated: !!verbsStorage,
  isLoading: true,
};

export const verbsReducer = createReducer(
  verbsInitialState,

  on(VerbActions.setInitialState, () => verbsInitialState),

  on(VerbActions.resetInitialState, () => ({ verbs: [], error: '', isLoading: false, isLoaded: false, isRehydrated: false })),

  on(VerbActions.setIsLoading, (state, action) => ({ ...state, isLoading: action.isloading })),

  on(VerbActions.loadVerbsSuccess, (state, action): VerbsState => {
    hydrateStorage(StorageStateEnum.Verbs, action.verbs);
    return { ...state, verbs: [...action.verbs], isLoading: false, isLoaded: true };
  }),

  on(VerbActions.updateVerbSuccess, (state, action): VerbsState => {
    const updatedVerbs = state.verbs.map((verb) => (action.verb.word === verb.word ? action.verb : verb));
    hydrateStorage(StorageStateEnum.Verbs, updatedVerbs);
    return { ...state, verbs: updatedVerbs, error: '' };
  }),

  on(VerbActions.deleteVerbSuccess, (state, action): VerbsState => {
    const filterVerbs = state.verbs.filter((verb) => verb.word !== action.word);
    hydrateStorage(StorageStateEnum.Verbs, filterVerbs);

    return { ...state, verbs: filterVerbs, error: '' };
  }),
  on(VerbActions.createVerbSuccess, (state, action): VerbsState => {
    const verbs = [...state.verbs, action.verb];
    hydrateStorage(StorageStateEnum.Verbs, verbs);
    return { ...state, verbs: [...state.verbs, action.verb] };
  }),
  on(VerbActions.updateProgressWord, (state, action): VerbsState => {
    const verbsMapped = state.verbs.map((verb) => {
      for (const progress of action.progress) {
        if (progress.word === verb.word) {
          return { ...verb, progress: progress.progress };
        }
      }
      return verb;
    });
    hydrateStorage(StorageStateEnum.Verbs, verbsMapped);

    return { ...state, verbs: verbsMapped, error: '' };
  }),

  on(VerbActions.updateVerbProperty, (state, action): VerbsState => {
    // update a property, but if value is array then push data into existing array.
    const verbsMapped = state.verbs.map((word) => {
      if (action.word === word.word) {
        if (Array.isArray(action.value)) {
          const previousValues = word[action.property] ?? [];
          return { ...word, [action.property]: [...previousValues, ...action.value] };
        }
        return { ...word, [action.property]: action.value };
      }
      return word;
    });
    hydrateStorage(StorageStateEnum.Verbs, verbsMapped);
    return { ...state, verbs: verbsMapped, error: '' };
  }),
);
