import { Component, Input } from '@angular/core';
import { ComponentWithForm } from '../ComponentWithForm';
import { LessonComponentConfiguration, LessonComponentInterface } from '../lessons.clases';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DropdownModule } from 'primeng/dropdown';

@Component({
  selector: 'app-selector',
  templateUrl: './selector.component.html',
  styleUrls: ['./selector.component.scss'],
  standalone: true,
  imports: [FormsModule, ReactiveFormsModule, DropdownModule],
})
export class SelectorComponent extends ComponentWithForm implements LessonComponentInterface {
  public isFilled = false;

  constructor() {
    super();
  }
  @Input() config: LessonComponentConfiguration;

  public evaluate() {
    let result;
    if (this.control.value == null) {
      result = null;
    } else {
      result = this.control.value?.toLowerCase().trim() === this.config.settings.response.toLowerCase().trim();
    }

    if (result) {
      this.status = 'success';
    } else {
      this.status = 'danger';
    }
    this.isFilled = true;
    return result;
  }

  public override validate() {
    if (this.control.invalid) {
      this.status = 'warning';
    }
    return true;
  }
}
