import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { Observable, from } from 'rxjs';
import { mergeMap } from 'rxjs/operators';

import { FirebaseAuthService } from './firebase-auth.service';

@Injectable({
  providedIn: 'root',
})
export class InterceptorTokenService implements HttpInterceptor {
  private fbAuthService = inject(FirebaseAuthService);

  /** Inserted by Angular inject() migration for backwards compatibility */
  constructor(...args: unknown[]);

  constructor() {}

  public intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    // obtengo el id directo de la biblioteca de firebase, en un observable procesado en tokenId$

    return this.fbAuthService.tokenId$.pipe(
      mergeMap((token) => {
        const modifiedRequest = req.clone({
          setHeaders: { Authorization: `Bearer ${token}` },
        });
        return next.handle(modifiedRequest);
      }),
    );
  }
}
