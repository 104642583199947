import { Injectable, inject } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';
import { Capacitor } from '@capacitor/core';

@Injectable({
  providedIn: 'root',
})
export class IsMobileGuardService {
  private router = inject(Router);

  /** Inserted by Angular inject() migration for backwards compatibility */
  constructor(...args: unknown[]);

  constructor() {}

  public canActivate(_next: ActivatedRouteSnapshot, _state: RouterStateSnapshot): any {
    if (Capacitor.isNativePlatform()) {
      return this.router.parseUrl('m/welcome');
    } else {
      return true;
    }
  }
}
