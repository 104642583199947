<div style="display: flex; justify-content: space-between; margin-bottom: 20px">
  <h3>Formulario palabra</h3>
  <p-button icon="pi pi-sparkles" (click)="completeWordWithAI()" label="Generar AI" [outlined]="true" [loading]="isGenerating"> </p-button>
</div>

@if(extraFields){

  <div style="padding: 20px; background-color: beige">
    <h3> Extension Japones</h3>
    <formly-form [fields]="extraFields" [form]="extensionForm"></formly-form>
  </div>
}
@if (loadingWord) {
  <span> -> Buscando palabra...</span>
}

<div class="static">
  <form [formGroup]="formGroup">
    <div class="grid">
      <div class="sm:col-6">
        <div class="field">
          <label class="label">Palabra (Target): </label>
          <input pInputText formControlName="word" type="text" class="w-full" placeholder="Small" />
        </div>
      </div>
      <div class="sm:col-6">
        <div class="field">
          <label class="label">Traducción (Base)</label>
          <input pInputText formControlName="translation" type="text" placeholder="Traducción" class="w-full" />
        </div>
      </div>
    </div>
    <div class="grid">
      <div class="col-6">
        <div class="form-group">
          <label class="label">Tipo de palabra</label>
          <br />

          <p-dropdown formControlName="wordCategory" [options]="wordTypeOptions" optionLabel="label" optionValue="value" placeholder="Selecciona"></p-dropdown>
        </div>
      </div>

      <div class="col-6">
        <div class="form-group">
          <label class="label">Frecuencia</label>
          <br />
          <!-- TODO add frecuency -->

          <p-dropdown
            formControlName="useFrequency"
            [options]="useFrequencyOptions"
            optionLabel="label"
            optionValue="value"
          placeholder="Selecciona"></p-dropdown>
        </div>
      </div>
    </div>

    <!-- Add new form controls -->
    <div class="grid">
      <div class="sm:col-6">
        <div class="field">
          <label class="label">IPA Phonetic:</label>
          <input pInputText formControlName="ipaPhonetic" type="text" class="w-full" placeholder="IPA pronunciation" />
        </div>
      </div>
      <div class="sm:col-6">
        <div class="field">
          <label class="label">Respelled Phonetic:</label>
          <input pInputText formControlName="respelledPhonetic" type="text" class="w-full" placeholder="Respelled pronunciation" />
        </div>
      </div>
    </div>

    <div class="grid">
      <div class="sm:col-6">
        <div class="field">
          <label class="label">fonetica para hispanohablantes:</label>
          <input pInputText formControlName="hispanicPhonetic" type="text" class="w-full" placeholder="Hispano fonetica" />
        </div>
      </div>
      <div class="sm:col-6">
        <div class="field">
          <label class="label">Level:</label>
          <input pInputText formControlName="level" type="text" class="w-full" placeholder="Difficulty level" />
        </div>
      </div>
    </div>

    <app-array-form-handler [arrayForm]="formGroup.controls.definitions" entity="definiciones"></app-array-form-handler>

    <app-array-form-handler [arrayForm]="formGroup.controls.examples" entity="ejemplos"></app-array-form-handler>

    <app-array-form-handler [arrayForm]="formGroup.controls.synonyms" entity="sinónimos"></app-array-form-handler>

    <app-array-form-handler [arrayForm]="formGroup.controls.otherTranslations" entity="otras traducciones"></app-array-form-handler>

    <app-array-form-handler [arrayForm]="formGroup.controls.otherTranslations" entity="otras traducciones"></app-array-form-handler>

    <p-divider align="center" type="dotted">
      <b>Idiomas Base (traducciones validas)</b>
    </p-divider>
    <h5> 🇪🇸 <b>Espanol</b> </h5>

    <app-lang-translations-form [formGroup]="formGroup.controls.es"></app-lang-translations-form>

    <h5> 🇺🇸 <b>English</b> </h5>

    <app-lang-translations-form [formGroup]="formGroup.controls.en"></app-lang-translations-form>

    <hr />

    <div style="display: flex; justify-content: space-between; margin-bottom: 20px"> </div>
  </form>

  <p-divider align="center" type="solid">
    <div style="color: var(--primary-color); display: flex; align-items: center; gap: 10px"> <i class="pi pi-mobile divider-icon"></i> <b>Flashcards.</b> </div>
  </p-divider>

  <app-word-flashcard-form [word]="langWord"></app-word-flashcard-form>

  <p-divider align="center" type="solid">
    <div style="color: var(--primary-color); display: flex; align-items: center; gap: 10px">
      <i class="pi pi-book divider-icon"></i> <b>Ejemplos de aprendizaje.</b>
    </div>
  </p-divider>

  @for (form of formGroup.controls.learningExamples.controls; track form; let i = $index) {
    <div>
      <p-divider align="left" type="dotted"> {{ i + 1 }} <i class="pi pi-id-card divider-icon"></i> </p-divider>
      <app-learning-example-form [form]="form" [word]="langWord.word" (removed)="deleteFormArrayByIndex('learningExamples', i)"></app-learning-example-form>
    </div>
  }
</div>

<!-- Add floating save button -->
<div class="fixed-bottom-right">
  <p-button (click)="save()" [disabled]="formGroup.pristine" styleClass="p-button-rounded p-button-lg" icon="pi pi-save"> </p-button>
</div>
