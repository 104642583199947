<div class="upload-section">
  <input type="file" [id]="fileInputId" class="file-input" (change)="fileChangeEvent($event)" />
  <label pButton [for]="fileInputId" [pTooltip]="storagePath" class="upload-button">
    {{ buttonLabel() }}
  </label>
</div>
@if(displayDialog) {
  <!-- Cropper Dialog -->

  <p-dialog header="Recortar imagen" [(visible)]="displayDialog" [modal]="true" [draggable]="false" [resizable]="false" styleClass="cropper-dialog">
    <!-- Image Settings Section -->
    <div class="settings-section">
      @if(!imgStorageSettings().path) {
        <p-message severity="warn">Developer Note: make sure you have a path to save the image pass object imgStorageSettings</p-message>
        } @if(currentStorage.url) {

        <p-message severity="warn" variant="outlined">
          <div>
            <span class="setting-label">Image will be replaced:</span>
            <img width="100" height="Auto" [src]="currentStorage?.url" />
          </div>
        </p-message>

      }

      <p-message>
        <b>Estas opciones estan preconfiguradas y no se pueden cambiar</b>

        <ul>
          <li> <b>Path to save:</b> {{ storagePath }}< </li>
          <li>
            <b>Resoluciones:</b>
            <span>{{ imgStorageSettings()?.cropSettings?.resolutions }}</span>
          </li>
        </ul>
      </p-message>

      <div class="setting-item">
        <span class="setting-label">Aspecto:</span>
        <p class="setting-value">{{ imgStorageSettings()?.cropSettings?.aspectRatio }}</p>
      </div>

      <p-select
        [options]="aspectRatioOptions"
        [ngModel]="ratioSelected"
        (ngModelChange)="changeRatio($event)"
        optionLabel="description"
        placeholder="Select a ratio" />

      <!-- File Metadata Section -->
      @if (fileMetadata) {
        <div class="metadata-section">
          <span class="metadata-item">tipo: {{ fileMetadata.type }}</span>
          <span class="metadata-item">tamaño: {{ fileMetadata.size }}</span>
        </div>
      }

      <!-- Rename Input -->
      <input
        pInputText
        [disabled]="imgStorageSettings()?.fileName"
        [(ngModel)]="renameFile"
        type="text"
        placeholder="Rename File"
        (ngModelChange)="reloadPath()"
        class="rename-input" />
    </div>

    <!-- Image Cropper -->

    <div class="cropper-container-father">
      <image-cropper
        [imageChangedEvent]="imageChangedEvent"
        [maintainAspectRatio]="true"
        [aspectRatio]="aspectRatioValue"
        format="webp"
        [resizeToWidth]="resizeToWidth"
        (imageCropped)="onInnerImageCropped($event)"
        (loadImageFailed)="loadImageFailed()"
        (cropperReady)="cropperReady()"
        [autoCrop]="false">
      </image-cropper>
    </div>
    <!-- Dialog Footer -->
    <ng-template pTemplate="footer">
      <div class="dialog-footer">
        <button pButton class="p-button-primary" (click)="simpleCropAndUpload()"> Recortar y Subir </button>
      </div>
    </ng-template>
  </p-dialog>
}
