import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { FormsModule, ReactiveFormsModule, FormBuilder } from '@angular/forms';
import { CommonModule } from '@angular/common';

import { ButtonModule } from 'primeng/button';
import { InputTextModule } from 'primeng/inputtext';
import { DynamicDialogRef } from 'primeng/dynamicdialog';
import { ComponentBuilder } from '../../ComponentBuilder';

@Component({
  selector: 'app-translation-switcher-builder',
  standalone: true,
  imports: [CommonModule, FormsModule, ReactiveFormsModule, ButtonModule, InputTextModule],
  templateUrl: './translationSwitcherBuilder.component.html',
  styleUrl: './translationSwitcherBuilder.component.css',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TranslationSwitcherBuilderComponent extends ComponentBuilder implements OnInit {
  constructor(
    protected override formBuilder: FormBuilder,
    // protected override toastrService: ToastService,
    protected override ref: DynamicDialogRef,
  ) {
    super(formBuilder, null);
  }
  // Este componente reutiliza completamente el form del padre y todos los métodos

  ngOnInit(): void {}
}
