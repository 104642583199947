import { createAction, props } from '@ngrx/store';

import { User, UserSettings, UserSubscription } from '../../classes';

export const loadUser = createAction('[User] load user');

export const resetUserInitialState = createAction('[User] set initial state');

export const loadUserSuccess = createAction('[User] load user success', props<{ user: User }>());

export const setUser = createAction('[User] set user', props<{ user: User }>());

export const setIsLoading = createAction('[User] set is loading', props<{ isloading: boolean }>());

export const setIsLoaded = createAction('[User] set is loaded', props<{ isLoaded: boolean }>());

export const updateUserProperties = createAction('[User] set user properties', props<{ user: Partial<User> }>());

export const updateSettings = createAction('[User] update settings', props<{ settings: UserSettings }>());

export const updateStep = createAction('[User] set user steps', props<{ step: string; value: string }>());

export const addStatPoints = createAction('[User] set stats', props<{ points: number }>());
