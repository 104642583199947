import { Injectable } from '@angular/core';
import { UserDataExchange, UserDataExchangeAbstractService } from '@dataclouder/conversation-system';
import { UserService } from './data-services/user.service';
import { LangCodeDescription } from '@dataclouder/lessons';

@Injectable({
  providedIn: 'root',
})
export class UserDataExchangeService implements UserDataExchangeAbstractService {
  constructor(private userService: UserService) {}

  getUserDataExchange(): UserDataExchange {
    const user = this.userService.getUserSnapshot().personalData;
    const settings = this.userService.getUserSnapshot().settings;
    return {
      name: user.firstname || 'Usuario',
      gender: user.gender,
      age: new Date().getFullYear() - new Date(user.birthday).getFullYear(),
      baseLang: settings.baseLanguage,
      targetLang: settings.targetLanguage,
    };
  }

  getParseDict(): { [key: string]: string } {
    // sustituye todos los {{char}} {{user}} {{target}} {{base}} por el valor de la propiedad

    const userData = this.getUserDataExchange();

    let target, base;
    try {
      target = LangCodeDescription[userData.targetLang];
      base = LangCodeDescription[userData.baseLang];
    } catch (error) {
      console.warn('Failed to get language descriptions', error);
    }

    // TODO: note check how the char is used, this parseDict need to be merged with the one for char data.
    return {
      // char: userData.name,
      user: userData.name,
      target: target || 'en',
      base: base || 'es',
    };
  }
}
