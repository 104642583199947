import { DialogRef, DIALOG_DATA } from '@angular/cdk/dialog';
import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Inject, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ButtonModule } from 'primeng/button';
import { DynamicDialogRef } from 'primeng/dynamicdialog';
import { UserService } from 'src/app/core/data-services/user.service';
import { StripeService } from 'src/app/core/stripe.service';
import { ToastService } from 'src/app/core/system/toast.service';

@Component({
  selector: 'app-component-sales-letter',
  standalone: true,
  imports: [CommonModule, ButtonModule],
  templateUrl: './sales-letter.component.html',
  styleUrl: './sales-letter.component.css',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SalesLetterComponent {
  constructor(
    private dialogRef: DynamicDialogRef,
    private stripeService: StripeService,
    private userService: UserService,
    private router: Router,
    private toastService: ToastService,
  ) {}

  isLoadingStripe = false;

  close(): void {
    this.dialogRef.close();
  }
  public async createStripeSession(months: number) {
    // check user is authenticated

    const user = await this.userService.getUserSnapshot();

    if (!user) {
      this.toastService.info(' 🎊 Una gran promo te espera 🎉 ', '🎈Por favor inicia sesión para continuar🎈');

      this.router.navigate(['auth/login']);
      this.close();
      return;
    }

    try {
      this.isLoadingStripe = true;
      await this.stripeService.payStripe(months);
    } finally {
      this.isLoadingStripe = false;
    }
  }
}
