import { Injectable } from '@angular/core';
import { ConversationChatSettings, LearningExample, TranscriptionsWhisper } from '../classes';
import { AIAPI, ChatRole, UtilsAPI, VerbTense } from '../enums';
import { HttpService } from '../system/http.service';
import { UserService } from './user.service';

export type AudioGenerated = { blobUrl: string; transcription: any };
export type TTSRequest = { text: string; voice: string; generateTranscription: boolean; speedRate: number; speed?: string; ssml?: string };

@Injectable({
  providedIn: 'root',
})
export class AiService {
  constructor(private httpService: HttpService, private userService: UserService) {}

  public async getServerStatus(): Promise<any> {
    return await this.httpService.getDataFromService(`${AIAPI.ServerStatus}`, 'python', true);
  }

  public async generateLearningExample(word: string): Promise<LearningExample> {
    const langString = this.userService.getUserLangOptions();

    return await this.httpService.getDataFromService(`${AIAPI.GenerateExample}/${word}?${langString}`, 'python');
  }

  public async generateWordAudio(word: string): Promise<any> {
    const langString = this.userService.getUserLangOptions();
    return await this.httpService.getDataFromService(`${AIAPI.GenerateWordAudio}/${word}?${langString}`, 'python');
  }

  public async getTextAudioFile(tts: TTSRequest): Promise<AudioGenerated> {
    const httpReq: any = await this.httpService.receiveFile(`${AIAPI.GetTTS}`, tts, 'python');
    const audioData = { blobUrl: null, transcription: null };

    const transcription = httpReq?.headers.get('transcription');

    if (transcription) {
      const data = JSON.parse(transcription);
      audioData.transcription = data;
    }

    const mp3 = window.URL.createObjectURL(httpReq.body);
    audioData.blobUrl = mp3;

    return audioData;
  }

  public async realTimeConversation(conversation: ConversationChatSettings): Promise<ConversationChatSettings> {
    return await this.httpService.postDataToService(`${AIAPI.RealTimeConversation}`, conversation);
  }

  public async generateAIExamples(word: string): Promise<any[]> {
    return await this.httpService.getDataFromService(`${AIAPI.GenerateExamples}/${word}`, 'python');
  }

  public async generateVerbExample(verb: string, tense: VerbTense): Promise<LearningExample> {
    const settings = { word: verb, tense };

    return await this.httpService.postDataToService(`${AIAPI.GenerateVerbExample}`, settings);
  }

  public async autocompleteLearningExample(word: string, learningExample: LearningExample): Promise<LearningExample> {
    const langString = this.userService.getUserLangOptions();

    return await this.httpService.postDataToService(`${AIAPI.AutocompleteWordLearningExample}/${word}?${langString}`, learningExample, 'python');
  }

  public async callChatCompletion(conversation: ConversationChatSettings): Promise<any> {
    // TODO: necesito filtrar solo la info de los mensaje que necesito, probamente no modificar el mensaje principal.

    let messages = conversation.messages.map((m) => ({ content: m.content, role: m.role }));
    messages = messages.filter((m) => m.role != ChatRole.AssistantHelper);
    const conversationFiltered = { ...conversation, messages };

    return await this.httpService.postDataToService(`${AIAPI.GenerateChat}`, conversationFiltered, 'python');
  }

  public async callInstruction(text: string): Promise<any> {
    // TODO change intruction before this method so i can reuse
    text = `Fix grammar and spelling errors in the following text: '${text}'`;
    return await this.httpService.postDataToService(`${UtilsAPI.SimpleInstruction}`, { text });
  }

  public async getAudioTranscriptions(audioBlob: Blob, metadata: any = null): Promise<TranscriptionsWhisper> {
    return await this.httpService.uploadAudioFile(`${AIAPI.Whisper}`, audioBlob, metadata, 'python');
  }

  public async generateVoiceForLearningExample(word: string, id: string): Promise<string> {
    return await this.httpService.postDataToService(`api/learningExamples/generateVoice/${word}/${id}`, 'python');
  }

  public async translate(text: string): Promise<string> {
    const data = { text, to: 'es', from: 'en' };
    return await this.httpService.postDataToService(`${UtilsAPI.Translation}`, data);
  }

  public async getListModels(provider: string): Promise<any> {
    return await this.httpService.getDataFromService(`${AIAPI.ListModels}/${provider}`, 'python');
  }
}
