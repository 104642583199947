import { Routes } from '@angular/router';

import { AuthGuardService } from './auth/services/auth-guard.service';
import { IsMobileGuardService } from './core/is-mobile-guard.service';
import { RoutesInformation } from './core/enums';
import { LandingComponent } from './information/landing/landing.component';
import { TermsComponent } from './information/terms/terms.component';
import { redirectToIfAuth } from 'projects/dataclouder/app-auth/src/public-api';
import { PrivacyPolicyComponent } from './information/privacy-policy/privacy-policy.component';
import { InformationComponent } from './information/information.component';

export const routes: Routes = [
  {
    path: '',
    loadComponent: () => import('./information/information.component').then((m) => m.InformationComponent),
    canActivate: [IsMobileGuardService],

    children: [
      {
        path: '',
        redirectTo: RoutesInformation.Main,
        pathMatch: 'full',
      },
      {
        path: 'auth',
        loadChildren: () => import('./auth/auth-routing').then((m) => m.RoutesAuth),
      },
      {
        path: RoutesInformation.Main,
        loadComponent: () => import('./information/landing/landing.component').then((m) => m.LandingComponent),
        canActivate: [redirectToIfAuth('app/dashboard')],
      },
      {
        path: `${RoutesInformation.Terms}`,
        loadComponent: () => import('./information/terms/terms.component').then((m) => m.TermsComponent),
      },
      {
        path: `${RoutesInformation.Terms}/:lang`,
        loadComponent: () => import('./information/terms/terms.component').then((m) => m.TermsComponent),
      },
      {
        path: `${RoutesInformation.Policy}`,
        loadComponent: () => import('./information/privacy-policy/privacy-policy.component').then((m) => m.PrivacyPolicyComponent),
      },
    ],
  },

  {
    path: 'app',
    loadChildren: () => import('./layout/layout-routing').then((m) => m.RoutesLayout),
    canActivate: [AuthGuardService],
  },

  {
    path: 'm',
    loadComponent: () => import('./layout-ionic/ionic-app/ionic-app.component').then((m) => m.IonicAppComponent),
    children: [
      {
        path: '',
        loadComponent: () => import('./layout-ionic/ionic-layout/layout-ionic.component').then((m) => m.IonicLayoutComponent), // Make sure to specify the component name here
        loadChildren: () => import('./pages/page.routing').then((m) => m.RoutesPage),
        canActivate: [AuthGuardService],
      },

      {
        path: 'welcome',
        loadComponent: () => import('./information/welcome/welcome.page').then((m) => m.WelcomePage),
        canActivate: [redirectToIfAuth('/m/dashboard')],
      },

      {
        path: 'auth',
        loadComponent: () => import('./layout-ionic/ionic-page-wraper/ionic-page-wraper.component').then((m) => m.IonicPageWraperComponent),
        loadChildren: () => import('./auth/auth-routing').then((m) => m.RoutesAuth),
      },

      {
        path: 'stack',
        loadComponent: () => import('./layout-ionic/stack-ionic/stack-ionic.component').then((m) => m.StackIonicComponent),
        loadChildren: () => import('./pages/page.routing').then((m) => m.RoutesPage),
      },
    ],
  },
];
