import { Component, OnInit } from '@angular/core';
import { UntypedFormArray, FormsModule, ReactiveFormsModule, FormBuilder } from '@angular/forms';


import { InputTextModule } from 'primeng/inputtext';
import { ButtonModule } from 'primeng/button';
import { DynamicDialogRef } from 'primeng/dynamicdialog';
import { MessageModule } from 'primeng/message';

import { ComponentBuilder } from '../../ComponentBuilder';
import { SelectorComponent } from '../selector.component';
import { LessonComponentConfiguration, LessonComponentEnum } from '../../lessons.clases';

@Component({
  selector: 'app-selector-builder',
  templateUrl: './selector-builder.component.html',
  styleUrls: ['./selector-builder.component.scss'],
  standalone: true,
  imports: [FormsModule, ReactiveFormsModule, SelectorComponent, InputTextModule, ButtonModule, MessageModule],
})
export class SelectorBuilderComponent extends ComponentBuilder implements OnInit {
  constructor(
    protected override formBuilder: FormBuilder,
    // protected override toastrService: ToastService,
    protected override ref: DynamicDialogRef,
  ) {
    super(formBuilder, null);
  }
  public sampleConfig: LessonComponentConfiguration = {
    id: '1',
    component: LessonComponentEnum.Selector,
    settings: {
      options: ['fourteen ninety-two', 'fourteen ninety-six', 'fifteen ninety-one'],
      response: 'fourteen ninety-two',
      hint: 'Pista para la respuesta',
      explanation: 'Explicación de la respuesta',
      responses: 'Opción 1, Opción 2, Opción 3',
      text: 'Texto de la pregunta',
    },
  };

  // public formGroup = this.formBuilder.group({
  //   options: this.formBuilder.array([]),
  //   response: ['', Validators.required],
  //   hint: [],
  //   explanation: [],
  // });

  ngOnInit(): void {
    this.formGroup.get('response');
  }

  public isRendered;

  //TODO Probablemente estos 3 pueden irse a una clase abstracta

  public pushControlToFormArray(controlName: string): void {
    this.formGroup.controls.options.push(this.formBuilder.control(''));
    // (this.formGroup.get(controlName) as UntypedFormArray).push(this.formBuilder.control(''));
    console.log(this.formGroup.controls.options);
  }

  public deleteFormArrayByIndex(controlName: string, index: number): void {
    (this.formGroup.get(controlName) as UntypedFormArray).removeAt(index);
  }

  get optionsForm(): UntypedFormArray {
    return this.formGroup.get('options') as UntypedFormArray;
  }
}
