<h5>Flashcard Information</h5>
<form [formGroup]="flashcardForm">
  <div style="display: flex; justify-content: space-between; gap: 10px">
    <div style="width: 100%">
      <label>Name</label>
      <input type="text" pInputText formControlName="name" placeholder="Enter flashcard name" class="w-full" />
    </div>

    <div style="width: 100%">
      <label>Tag</label>
      <input type="text" pInputText formControlName="tag" placeholder="Enter flashcard tag" class="w-full" />
    </div>
  </div>

  <!-- Two-column layout for front and back sides -->
  <div class="grid">
    <!-- Front side column -->
    <div class="col-6">
      <div class="p-2">
        <h3>Front Side</h3>
        <div style="position: relative">
          <img class="flascard-image" [src]="frontUrl" alt="" />
          @if(storageImgSettings) {
          <dc-cropper-modal
            style="position: absolute; bottom: 10px; left: 10px"
            buttonLabel="Seleccionar frente"
            [imgStorageSettings]="storageImgSettings"
            (imageUploaded)="updateUploadedImage($event, 'front')"></dc-cropper-modal>
          }
        </div>

        <div formGroupName="front" class="form-field">
          <div>
            <label>Content Type</label>
            <p-dropdown
              [options]="contentTypes"
              formControlName="type"
              optionLabel="label"
              optionValue="value"
              placeholder="Select content type"
              class="w-full"></p-dropdown>
          </div>
          <div>
            <label>Content</label>
            <textarea pTextarea formControlName="content" rows="1" class="w-full" placeholder="Enter front content"></textarea>
          </div>
        </div>
      </div>
    </div>

    <!-- Back side column -->
    <div class="col-6">
      <div class="p-2">
        <h3>Back Side</h3>
        <div style="position: relative">
          <img class="flascard-image" [src]="backUrl" alt="" />
          @if(storageImgSettings) {
          <dc-cropper-modal
            style="position: absolute; bottom: 10px; left: 10px"
            buttonLabel="Seleccionar reverso"
            [imgStorageSettings]="storageImgSettings"
            (imageUploaded)="updateUploadedImage($event, 'back')"></dc-cropper-modal>
          }
        </div>

        <div formGroupName="back" class="form-field">
          <div>
            <label>Content Type</label>
            <p-dropdown
              [options]="contentTypes"
              formControlName="type"
              optionLabel="label"
              optionValue="value"
              placeholder="Select content type"
              class="w-full"></p-dropdown>
          </div>
          <div>
            <label>Content</label>
            <textarea pTextarea formControlName="content" rows="1" class="w-full" placeholder="Enter back content"></textarea>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="flex justify-content-end">
    <p-button (click)="save()" label="Save Flashcard" [disabled]="!flashcardForm.valid" icon="pi pi-check" iconPos="right"></p-button>
  </div>
</form>
