export const JapaneseAdditionalFields = [
  { key: 'kanji', type: 'input', props: { label: 'Kanji', placeholder: 'Kanji', required: false } },
  { key: 'hiragana', type: 'input', props: { label: 'Hiragana', placeholder: 'Hiragana', required: false } },
  { key: 'katakana', type: 'input', props: { label: 'Katakana', placeholder: 'Katakana', required: false } },
  { key: 'romaji', type: 'input', props: { label: 'Romaji', placeholder: 'Romaji', required: false } },
  { key: 'kanjiExample', type: 'input', props: { label: 'Kanji Example', placeholder: 'Kanji Example', required: false } },
  { key: 'hiraganaExample', type: 'input', props: { label: 'Hiragana Example', placeholder: 'Hiragana Example', required: false } },
  { key: 'katakanaExample', type: 'input', props: { label: 'Katakana Example', placeholder: 'Katakana Example', required: false } },
  { key: 'romajiExample', type: 'input', props: { label: 'Romaji Example', placeholder: 'Romaji Example', required: false } },
  { key: 'spanishTranslation', type: 'input', props: { label: 'Spanish Translation', placeholder: 'Spanish Translation', required: false } },
  { key: 'englishTranslation', type: 'input', props: { label: 'English Translation', placeholder: 'English Translation', required: false } },
];
