import { Injectable, inject } from '@angular/core';
import {
  Auth,
  authState,
  createUserWithEmailAndPassword,
  FacebookAuthProvider,
  GoogleAuthProvider,
  IdTokenResult,
  sendPasswordResetEmail,
  signInWithEmailAndPassword,
  signInWithPopup,
  sendEmailVerification,
  signInWithCredential,
} from '@angular/fire/auth';
import { Router } from '@angular/router';
import { Capacitor } from '@capacitor/core';
import { Store } from '@ngrx/store';
import { NGXLogger } from 'ngx-logger';
import { from, of } from 'rxjs';
import { take, concatMap } from 'rxjs/operators';

import { RouteNames } from './enums';
import { hydrateStorage, StorageStateEnum } from './store/app.state';
import { TopicsActions } from './store/topics';
import { UserActions } from './store/user';
import { VerbActions } from './store/verbs';
import { WordActions } from './store/words';
import { MobileService } from './system/mobile.service';

@Injectable({
  providedIn: 'root',
})
export class FirebaseAuthService {
  private store = inject(Store);
  private router = inject(Router);
  private logger = inject(NGXLogger);
  private auth = inject(Auth);
  private mobileService = inject(MobileService);

  /** Inserted by Angular inject() migration for backwards compatibility */
  constructor(...args: unknown[]);

  // public providerData: { phoneNumber: string, displayName: string, photoURL: string, providerId: string, uid: string } | any;

  constructor() {}
  public authState$ = authState(this.auth);

  public tokenId$ = this.authState$.pipe(
    take(1),
    concatMap((auth: any) => {
      if (auth === null) {
        return of(null);
      }
      return from(auth.getIdToken());
    }),
  );

  public refreshToken(): void {
    this.authState$.pipe(take(1)).subscribe((auth) => {
      auth.getIdToken(true).then((_) => {
        this.logger.info('token refreshed');
      });
    });
  }

  public async signInWithCredential(credential): Promise<any> {
    try {
      const data = await signInWithCredential(this.auth, credential);
      console.log(data);
      const nextRoute = this.mobileService.isMobileRoute() ? RouteNames.Mobile : RouteNames.Home;
      this.router.navigateByUrl(`/${nextRoute}`);
    } catch (err: any) {
      this.logger.warn('Ocurrió un problema al iniciar sesión', err);
      throw new Error(err.message);
    }
  }

  public async logOut(): Promise<void> {
    // TODO: verificar que al cerrar sesión no recuerde los datos del estado.
    // TODO! revisar como reiniciar los estados que sea automáticamente y no aquí
    this.store.dispatch(UserActions.resetUserInitialState());
    this.store.dispatch(VerbActions.resetInitialState());
    this.store.dispatch(WordActions.resetInitialState());
    this.store.dispatch(TopicsActions.setInitialState());

    hydrateStorage(StorageStateEnum.User, null);
    hydrateStorage(StorageStateEnum.Words, null);
    hydrateStorage(StorageStateEnum.Verbs, null);

    const response = await this.auth.signOut();
    this.logger.debug('Cerrando sesión', response);
    // if (this.mobileService.isMobileRoute()) {
    //   this.router.navigateByUrl('m/auth/login');
    // } else {
    //   this.router.navigateByUrl('/auth/login');
    // }
    this.router.navigateByUrl('/');
  }

  public async signWithProvider(privider: 'google' | 'facebook'): Promise<void> {
    let authResult = null;
    if (privider === 'google') {
      this.logger.info('Iniciando auth con google');
      authResult = await signInWithPopup(this.auth, new GoogleAuthProvider());
    } else if (privider === 'facebook') {
      authResult = await signInWithPopup(this.auth, new FacebookAuthProvider());
    } else {
      throw new Error('No se tiene un provedor válido');
    }
    console.log(authResult);

    const nextRoute = Capacitor.isNativePlatform() ? RouteNames.Mobile : RouteNames.App;
    this.router.navigateByUrl(`/${nextRoute}`);
  }

  public async register(email: string, password: string): Promise<void> {
    try {
      const credentials = await createUserWithEmailAndPassword(this.auth, email, password);
      sendEmailVerification(credentials.user);

      this.router.navigateByUrl(`${RouteNames.App}/${RouteNames.Home}`);
    } catch (err) {
      console.log('Ocurrió un error al registrar el usuario', err);
      throw new Error(err.message);
    }
  }

  public sendEmailVerification(): void {
    sendEmailVerification(this.auth.currentUser);
  }

  public async signWithEmailPassword(email: string, password: string): Promise<void> {
    try {
      await signInWithEmailAndPassword(this.auth, email, password);
      const nextRoute = Capacitor.isNativePlatform() ? RouteNames.Mobile : `app/${RouteNames.Home}`;
      this.router.navigateByUrl(`${nextRoute}`);
    } catch (err: any) {
      this.logger.warn('Ocurrió un problema al iniciar sesión', err);
      throw new Error(err.message);
    }
  }

  // NotUsed
  public async getTokenResult(): Promise<IdTokenResult> {
    return this.auth.currentUser.getIdTokenResult();
  }

  public sendPasswordResetEmail(email: string): Promise<void> {
    return sendPasswordResetEmail(this.auth, email);
  }
}
