import { Component, Input, OnInit } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { InputTextModule } from 'primeng/inputtext';

import { ComponentWithForm } from '../ComponentWithForm';
import { LessonComponentConfiguration, LessonComponentInterface } from '../lessons.clases';

@Component({
  selector: 'app-text-writer',
  templateUrl: './text-writer.component.html',
  styleUrls: ['./text-writer.component.scss'],
  standalone: true,
  imports: [FormsModule, ReactiveFormsModule, InputTextModule],
})
export class TextWriterComponent extends ComponentWithForm implements OnInit, LessonComponentInterface {
  @Input() config: LessonComponentConfiguration;

  constructor() {
    super();
  }

  public size: number;
  // esta por decidir como va a funcionar los hits, explanation and hint.
  public hint: string;

  override ngOnInit(): void {
    super.ngOnInit();
    this.size = this.getSize();
  }

  private getSize(): number {
    return this.config.settings.response.length < 20 ? this.config.settings.response.length : 20;
  }

  public evaluate() {
    let result;
    if (this.control.value == null) {
      result = null;
    } else {
      result = this.control.value.toLowerCase().trim() == this.config.settings.response.toLowerCase().trim();
    }

    if (result) {
      this.status = 'success';
    } else {
      this.status = 'danger';

      this.hint = this.config.settings.explanation;
    }

    return result;
  }

  public override validate() {
    if (this.control.invalid) {
      this.status = 'warning';
      this.hint = this.config.settings.hint;
    }
    return true;
  }
}
