import { InjectionToken, Provider, Type } from '@angular/core';
import { ChatUserSettings } from './agent.models';

export interface UserDataExchange {
  name: string;
  gender: string;
  age: number;
  baseLang?: string; // For language app
  targetLang?: string; // For language app
}

export const USER_DATA_EXCHANGE = new InjectionToken<UserDataExchange>('user.data.exchange');

export function provideUserDataExchange(serviceImplementation: Type<UserDataExchangeAbstractService>): Provider[] {
  return [
    {
      provide: USER_DATA_EXCHANGE,
      useExisting: serviceImplementation,
    },
  ];
}

export abstract class UserDataExchangeAbstractService {
  abstract getUserDataExchange(): UserDataExchange;
  abstract getParseDict(): { [key: string]: string };
  abstract getUserChatSettings(): ChatUserSettings;
}
