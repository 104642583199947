import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';

@Component({
  selector: 'app-component-form',
  template: '<div>no template</div>',
})
export abstract class ComponentWithForm implements OnInit {
  // @Input() settings: LessonCompSettings;

  public control: UntypedFormControl = new UntypedFormControl();
  public status: string;

  ngOnInit(): void {
    this.control.valueChanges.subscribe(() => {
      this.status = 'basic';
    });
  }

  public validate(): void {
    // TODO: generic method to evaluate
  }

  public abstract evaluate(): boolean;
}
