import { Component, HostListener, OnInit, inject } from '@angular/core';
import { NavigationEnd, Router, RouterOutlet } from '@angular/router';
import { SwUpdate, VersionReadyEvent } from '@angular/service-worker';

// import { AngularFireMessaging } from '@angular/fire/compat/messaging';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { NGXLogger } from 'ngx-logger';
import { MessageService, PrimeTemplate } from 'primeng/api';
import { ButtonDirective } from 'primeng/button';
import { ProgressBarModule } from 'primeng/progressbar';
import { ToastModule } from 'primeng/toast';
import { filter, map } from 'rxjs';

import { AuthGuardService } from './auth/services/auth-guard.service';
import { UserService } from './core/data-services/user.service';
import { UtilsDataService } from './core/data-services/utils-data.service';
import { UserSelectors } from './core/store/user';
import { MobileService } from './core/system/mobile.service';
import { ToastAlertService } from './core/system/toast-alerts.service';
import { ToastService } from './core/system/toast.service';
import { LoadingBarComponent } from '../../projects/dataclouder/ngx-core/src/lib/components/loading-bar/loading-bar';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  providers: [MessageService],
  standalone: true,
  imports: [RouterOutlet, ToastModule, PrimeTemplate, ButtonDirective, ProgressBarModule, LoadingBarComponent],
})
export class AppComponent implements OnInit {
  private messageService = inject(MessageService);
  private toastService = inject(ToastService);
  private toastAlertService = inject(ToastAlertService);
  private store = inject(Store);
  guard = inject(AuthGuardService);
  private swUpdate = inject(SwUpdate);
  private mobileService = inject(MobileService);
  private logger = inject(NGXLogger);
  private router = inject(Router);
  private toastrService = inject(ToastService);
  private utilsDataService = inject(UtilsDataService);
  private translate = inject(TranslateService);
  private userService = inject(UserService);

  public isLoading = this.store.select(UserSelectors.getIsLoading);

  /** Inserted by Angular inject() migration for backwards compatibility */
  constructor(...args: unknown[]);

  constructor() {
    const messageService = this.messageService;
    const toastService = this.toastService;
    const toastAlertService = this.toastAlertService;

    // this.translate.setDefaultLang('es');

    toastService.messageService = messageService;
    toastAlertService.setMessageService(messageService);

    // this.initGoogleTagManager();
  }

  public async ngOnInit() {
    this.updatePWA();
    this.utilsDataService.getServerStatus();

    // 1) checa configuración de usuario, 2) checa configuración de navegador, default es español
    const user = this.userService.getUserSnapshot();
    if (user?.settings?.baseLanguage) {
      this.translate.use(user.settings.baseLanguage);
    } else {
      if (navigator?.language?.includes('en')) {
        this.translate.use('en');
      } else {
        this.translate.use('es');
      }
    }

    // console.log('user', user.settings.baseLanguage);

    // todo on refresh token does not exist any more check what to do.
    // this.messaging.onTokenRefresh((token) => {
    //   console.log('Tengo un token refrescado');
    // });
  }

  @HostListener('window:beforeinstallprompt', ['$event'])
  private onBeforeInstallPrompt(event: Event) {
    this.logger.log('event install pwa app ');
    event.preventDefault();
    this.mobileService.eventInstall = event;
  }

  private updatePWA(): void {
    // TODO available estará obsoleto pronto, ver como replicar esta funcionalidad.
    // this.swUpdate.versionUpdates.subscribe((version) => {

    //   alert('Nueva versión, se aplicarán los cambios');
    //   window.location.reload();
    // });
    // First step check new versionUpdates works like bevore
    // TODO revisar que esto esta funcionando
    this.swUpdate.versionUpdates
      .pipe(
        filter((evt: any): evt is VersionReadyEvent => evt.type === 'VERSION_READY'),
        map((evt: any) => ({
          type: 'UPDATE_AVAILABLE',
          current: evt.currentVersion,
          available: evt.latestVersion,
        })),
      )
      .subscribe((value) => {
        this.toastrService.info('Descargado...', 'Se tiene una pequeña actualización');

        setTimeout(() => {
          window.location.reload();
        }, 2000);
      });
  }

  private initGoogleTagManager() {
    // this.gtmService.addGtmToDom();

    this.router.events.forEach((item: any) => {
      if (item instanceof NavigationEnd) {
        const gtmTag = {
          event: 'page',
          pageName: item.url,
        };
        // this.gtmService.pushTag(gtmTag);
      }
    });
  }

  // private requestPermission() {
  //   this.messaging.requestToken.subscribe(token => {
  //     console.log('Tengo el token del usuario ', token);
  //     console.log('aqui es donde hago el proceso de guardar el token');
  //     alert('el token es : ' + token);
  //   }, (err) => {
  //     console.error('Unable to get permission to notify.', err);
  //   });

  // }

  // private listenToNotifications() {
  //   this.messaging.messages.subscribe(message => {
  //     console.log('Guardando notificaciones', message);
  //     // Aqui es donde puedo iniciar las llamadas a los routers etc. o enviar al producto etc.
  //     // this.listenToNotifications();
  //   });
  // }

  public toSocialNetwork(social: string) {
    let url;
    if (social === 'instagram') {
      url = 'https://www.instagram.com/polilan_app';
    } else if (social === 'tiktok') {
      url = 'https://www.tiktok.com/@polilan_app';
    } else if (social === 'youtube') {
      url = 'https://www.youtube.com/@polilan_app';
    }

    window.open(url, '_blank');
  }
}
