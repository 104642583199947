import { ScenarioType } from './classes';
import { AudioSpeed, PlanType, TextEngines } from './enums';

export const DEFAULT_CONVERSATION_CARD = 'https://storage.googleapis.com/appingles-pro.appspot.com/default_image.png';
export const DEFAULT_CARD = 'assets/images/default_card.png';
export const DEFAULT_BACKGROUND = 'https://storage.googleapis.com/appingles-pro.appspot.com/default_image_front.png';

export const AudioSpeedRating = {
  1: AudioSpeed.VerySlow,
  2: AudioSpeed.Slow,
  3: AudioSpeed.Regular,
  4: AudioSpeed.Fast,
  5: AudioSpeed.VeryFast,
};

export const AudioSpeedRatingInverse = {
  [AudioSpeed.VerySlow]: 1,
  [AudioSpeed.Slow]: 2,
  [AudioSpeed.Regular]: 3,
  [AudioSpeed.Fast]: 4,
  [AudioSpeed.VeryFast]: 5,
};

export const GenderOptions = [
  { name: 'Hombre', code: 'male' },
  { name: 'Female', code: 'female' },
  { name: 'Otro', code: 'other' },
];

export const AudioSpeedOptions = [
  { name: 'Muy Lento', code: AudioSpeed.VerySlow },
  { name: 'Lento', code: AudioSpeed.Slow },
  { name: 'Normal', code: AudioSpeed.Regular },
  { name: 'Rápida', code: AudioSpeed.Fast },
];

export const SpeedDescription = {
  1: 'Muy Lento',
  2: 'Lento',
  3: 'Normal',
  4: 'Rápida',
  5: 'Muy Rápida',
};

export const TargeLanguagesOptions = [
  { name: 'Español 🇪🇦 🇲🇽 ', code: 'es' },
  { name: 'Inglés 🇺🇸 🇬🇧 ', code: 'en' },
  { name: 'Portugués 🇧🇷 🇵🇹', code: 'pt' },
  { name: 'Italiano 🇮🇹', code: 'it' },
  { name: 'Frances 🇫🇷', code: 'fr' },
];

export const LanguageLevelOptions = [
  { name: 'Principiante', code: '1' },
  { name: 'Principiante Intermedio', code: '2' },
  { name: 'Intermedio', code: '3' },
  { name: 'Intermedio Avanzado', code: '4' },
  { name: 'Avanzado', code: '5' },
];

export const BaseLanguagesOptions = [
  { name: 'Español 🇪🇦 🇲🇽 ', code: 'es' },
  { name: 'Inglés 🇺🇸 🇬🇧 ', code: 'en' },
];

export const VoiceOptions = [
  {
    provider: 'openai',
    name: 'Fable',
    id: 'fable',
    exampleUrl: 'https://storage.googleapis.com/appingles-pro.appspot.com/voice_demos/openai_fable.mp3',
  },
  {
    provider: 'openai',
    name: 'Onyx',
    id: 'onyx',
    exampleUrl: 'https://storage.googleapis.com/appingles-pro.appspot.com/voice_demos/open_onyx.mp3',
  },
  {
    provider: 'openai',
    name: 'Alloy',
    id: 'alloy',
    exampleUrl: 'https://storage.googleapis.com/appingles-pro.appspot.com/voice_demos/openai_alloy.mp3',
  },
  {
    provider: 'openai',
    name: 'Nova',
    id: 'nova',
    exampleUrl: 'https://storage.googleapis.com/appingles-pro.appspot.com/voice_demos/openai_nova.mp3',
  },
  {
    provider: 'openai',
    name: 'Shimmer',
    id: 'shimmer',
    exampleUrl: 'https://storage.googleapis.com/appingles-pro.appspot.com/voice_demos/openai_shimmer.mp3',
  },
  {
    provider: 'google',
    name: 'Woman Studio O',
    id: 'en-US-Studio-O',
    exampleUrl: 'https://storage.googleapis.com/appingles-pro.appspot.com/voice_demos/google_Studio-O.mp3',
  },
  {
    provider: 'google',
    name: 'Man Studio Q',
    id: 'en-US-Studio-Q',
    exampleUrl: 'https://storage.googleapis.com/appingles-pro.appspot.com/voice_demos/google_Studio-Q.mp3',
  },
  {
    provider: 'elevenlabs',
    name: 'Charlotte',
    id: 'XB0fDUnXU5powFXDhCwa',
    exampleUrl: 'https://storage.googleapis.com/appingles-pro.appspot.com/voice_demos/elevenlab_Charlotte.mp3',
  },
  {
    provider: 'elevenlabs',
    name: 'Grace',
    id: 'oWAxZDx7w5VEj9dCyTzz',
    exampleUrl: 'https://storage.googleapis.com/appingles-pro.appspot.com/voice_demos/elevenlab_Grace.mp3',
  },
  {
    provider: 'elevenlabs',
    name: 'Nicole',
    id: 'piTKgcLEGmPE4e6mEKli',
    exampleUrl: 'https://storage.googleapis.com/appingles-pro.appspot.com/voice_demos/elevenlab_Nicole.mp3',
  },
  {
    provider: 'elevenlabs',
    name: 'Emily',
    id: 'LcfcDJNUP1GQjkzn1xUU',
    exampleUrl: 'https://storage.googleapis.com/appingles-pro.appspot.com/voice_demos/elevenlab_Emily.mp3',
  },
  {
    provider: 'elevenlabs',
    name: 'Thomas',
    id: 'GBv7mTt0atIp3Br8iCZE',
    exampleUrl: 'https://storage.googleapis.com/appingles-pro.appspot.com/voice_demos/elevenlab_Thomas.mp3',
  },
  {
    provider: 'elevenlabs',
    name: 'Chris',
    id: 'iP95p4xoKVk53GoZ742B',
    exampleUrl: 'https://storage.googleapis.com/appingles-pro.appspot.com/voice_demos/elevenlab_Chris.mp3',
  },
  {
    provider: 'elevenlabs',
    name: 'Harry',
    id: 'SOYHLrjzK2X1ezoPC6cr',
    exampleUrl: 'https://storage.googleapis.com/appingles-pro.appspot.com/voice_demos/elevenlab_Harry.mp3',
  },
  {
    provider: 'elevenlabs',
    name: 'Gigi',
    id: 'jBpfuIE2acCO8z3wKNLl',
    exampleUrl: 'https://storage.googleapis.com/appingles-pro.appspot.com/voice_demos/elevenlab_Gigi.mp3',
  },
];

export const VoiceTTSOptions = [
  // French
  {
    provider: 'google',
    name: '👨‍🦰 Man ♂️ 🇫🇷 fr-FR-Neural2-B',
    id: 'fr-FR-Neural2-B',
    gender: 'male',
    lang: 'fr-FR',
    exampleUrl: 'https://storage.googleapis.com/appingles-pro.appspot.com/voice_demos/google_fr-FR-Neural2-B.mp3',
  },
  {
    provider: 'google',
    name: '👩 Woman ♀️ 🇫🇷 fr-FR-Neural2-E',
    id: 'fr-FR-Neural2-E',
    gender: 'female',
    lang: 'fr-FR',
    exampleUrl: 'https://storage.googleapis.com/appingles-pro.appspot.com/voice_demos/google_fr-FR-Neural2-E.mp3',
  },
  // Italian
  {
    provider: 'google',
    name: '👩 Woman ♀️ 🇮🇹 it-IT-Neural2-A',
    id: 'it-IT-Neural2-A',
    gender: 'female',
    lang: 'it-IT',
    exampleUrl: 'https://storage.googleapis.com/appingles-pro.appspot.com/voice_demos/google_it-IT-Neural2-A.mp3',
  },
  {
    provider: 'google',
    name: '👨‍🦰 Man ♂️  🇮🇹 it-IT-Neural2-C',
    id: 'it-IT-Neural2-C',
    gender: 'male',
    lang: 'it-IT',
    exampleUrl: 'https://storage.googleapis.com/appingles-pro.appspot.com/voice_demos/google_it-IT-Neural2-C.mp3',
  },
  // Portuguese
  {
    provider: 'google',
    name: '👩 Woman ♀️ 🇧🇷 pt-BR-Neural2-C',
    id: 'pt-BR-Neural2-C',
    gender: 'female',
    lang: 'pt-BR',
    exampleUrl: 'https://storage.googleapis.com/appingles-pro.appspot.com/voice_demos/google_pt-BR-Neural2-C.mp3',
  },
  {
    provider: 'google',
    name: '👨‍🦰 Man ♂️ 🇧🇷 pt-BR-Neural2-B',
    id: 'pt-BR-Neural2-B',
    gender: 'male',
    lang: 'pt-BR',
    exampleUrl: 'https://storage.googleapis.com/appingles-pro.appspot.com/voice_demos/google_pt-BR-Neural2-B.mp3',
  },
  // Spanish
  {
    provider: 'google',
    name: '👨‍🦰 Man 🇲🇽 es-US-Neural2-B',
    id: 'es-US-Neural2-B',
    gender: 'male',
    lang: 'es-US',
    exampleUrl: 'https://storage.googleapis.com/appingles-pro.appspot.com/voice_demos/google_es-US-Neural2-B.mp3',
  },
  {
    provider: 'google',
    name: '👩 Woman ♀️ 🇲🇽 es-US-Neural2-A',
    id: 'es-US-Neural2-A',
    gender: 'female',
    lang: 'es-US',
    exampleUrl: 'https://storage.googleapis.com/appingles-pro.appspot.com/voice_demos/google_es-US-Neural2-A.mp3',
  },
  {
    provider: 'google',
    name: '👨‍🦰 Man ♂️ 🇲🇽 es-US-Neural2-C',
    id: 'es-US-Neural2-C',
    gender: 'male',
    lang: 'es-US',
    exampleUrl: 'https://storage.googleapis.com/appingles-pro.appspot.com/voice_demos/google_es-US-Neural2-C.mp3',
  },

  {
    provider: 'google',
    name: '👨‍🦰 Man ♂️ 🇪🇸 es-ES-Neural2-B',
    id: 'es-ES-Neural2-B',
    gender: 'male',
    lang: 'es-ES',
    exampleUrl: 'https://storage.googleapis.com/appingles-pro.appspot.com/voice_demos/google_es-ES-Neural2-B.mp3',
  },
  {
    provider: 'google',
    name: '👩 Woman ♀️ 🇪🇸 es-ES-Neural2-C',
    id: 'es-ES-Neural2-C',
    gender: 'male',
    lang: 'es-ES',
    exampleUrl: 'https://storage.googleapis.com/appingles-pro.appspot.com/voice_demos/google_es-ES-Neural2-C.mp3',
  },
  {
    provider: 'google',
    name: '👨‍🦰 Man ♂️ 🇪🇸 es-ES-Neural2-F',
    id: 'es-ES-Neural2-F',
    gender: 'male',
    lang: 'es-ES',
    exampleUrl: 'https://storage.googleapis.com/appingles-pro.appspot.com/voice_demos/google_es-ES-Neural2-F.mp3',
  },
  {
    provider: 'google',
    name: '👩 Woman ♀️ 🇪🇸 es-ES-Neural2-E',
    id: 'es-ES-Neural2-E',
    gender: 'female',
    lang: 'es-ES',
    exampleUrl: 'https://storage.googleapis.com/appingles-pro.appspot.com/voice_demos/google_es-ES-Neural2-E.mp3',
  },

  // English
  {
    provider: 'google',
    name: '👩 Woman ♀️ 🇺🇸 en-US-Journey-F',
    id: 'en-US-Journey-F',
    gender: 'female',
    lang: 'en-US',
    exampleUrl: 'https://storage.googleapis.com/appingles-pro.appspot.com/voice_demos/google_en-US-Journey-F.mp3',
  },
  {
    provider: 'google',
    name: '👩 Woman ♀️ 🇺🇸 en-US-Journey-O',
    id: 'en-US-Journey-O',
    gender: 'female',
    lang: 'en-US',
    exampleUrl: 'https://storage.googleapis.com/appingles-pro.appspot.com/voice_demos/google_en-US-Journey-O.mp3',
  },
  {
    provider: 'google',
    name: '👩 Woman ♀️ 🇺🇸 en-US-Journey-D',
    id: 'en-US-Journey-D',
    gender: 'female',
    lang: 'en-US',
    exampleUrl: 'https://storage.googleapis.com/appingles-pro.appspot.com/voice_demos/google_en-US-Journey-D.mp3',
  },

  {
    provider: 'google',
    name: '👩 Woman ♀️ 🇺🇸 en-US-Journey-N',
    id: 'en-US-News-N',
    gender: 'female',
    lang: 'en-US',
    exampleUrl: 'https://storage.googleapis.com/appingles-pro.appspot.com/voice_demos/google_en-US-News-N.mp3',
  },
  {
    provider: 'google',
    name: '👩 Woman ♀️ 🇺🇸 en-US-News-L',
    id: 'en-US-News-L',
    gender: 'female',
    lang: 'en-US',
    exampleUrl: 'https://storage.googleapis.com/appingles-pro.appspot.com/voice_demos/google_en-US-News-L.mp3',
  },

  {
    provider: 'google',
    name: '👩 Woman ♀️ 🇺🇸 en-US-Neural2-F',
    id: 'en-US-Neural2-F',
    gender: 'female',
    lang: 'en-US',
    exampleUrl: 'https://storage.googleapis.com/appingles-pro.appspot.com/voice_demos/google_en-US-Neural2-F.mp3',
  },
  {
    provider: 'google',
    name: '👩 Woman ♀️ 🇺🇸 en-US-Neural2-H',
    id: 'en-US-Neural2-H',
    gender: 'female',
    lang: 'en-US',
    exampleUrl: 'https://storage.googleapis.com/appingles-pro.appspot.com/voice_demos/google_en-US-Neural2-H.mp3',
  },
  {
    provider: 'google',
    name: '👨‍🦰 Man ♂️ 🇺🇸 en-US-Neural2-J',
    id: 'en-US-Neural2-J',
    gender: 'male',
    lang: 'en-US',
    exampleUrl: 'https://storage.googleapis.com/appingles-pro.appspot.com/voice_demos/google_en-US-Neural2-J.mp3',
  },
  {
    provider: 'google',
    name: '👨‍🦰 Man ♂️ 🇺🇸 en-US-Neural2-I',
    id: 'en-US-Neural2-I',
    gender: 'male',
    lang: 'en-US',
    exampleUrl: 'https://storage.googleapis.com/appingles-pro.appspot.com/voice_demos/google_en-US-Neural2-I.mp3',
  },
];

export const TextEngineOptions = [
  { name: 'Text Plano', code: TextEngines.Plantext },
  { name: 'MarkDown Client Multi Messages', code: TextEngines.MarkdownMultiMessages },
  { name: 'Markdown Server SSML', code: TextEngines.MarkdownSSML },
];

export const ScenarioTypeOptions = [
  { name: 'Conversación general', code: ScenarioType.General },
  { name: 'Challenge', code: ScenarioType.Challenge },
  { name: 'Reflexión', code: ScenarioType.Reflection },
  { name: 'Ejemplo de aprendizaje', code: ScenarioType.LearningExample },
];

type MasterRulesType = { max_words: number | null; max_verbs: number | null; max_conversations: number; max_le_requests: number };

export const MasterRules: Record<string, MasterRulesType> = {
  [PlanType.Basic]: {
    max_words: 25,
    max_verbs: 20,
    max_conversations: 0, // cambiar a 1
    max_le_requests: 20,
  },
  [PlanType.Follower]: {
    max_words: null,
    max_verbs: null,
    max_conversations: 3,
    max_le_requests: 30,
  },
  [PlanType.Beta]: {
    max_words: null,
    max_verbs: null,
    max_conversations: 50,
    max_le_requests: 70,
  },
  //     # not used yet
  [PlanType.Premium]: {
    max_words: null,
    max_verbs: null,
    max_conversations: 100,
    max_le_requests: 200,
  },
};
