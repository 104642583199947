import { Injectable } from '@angular/core';
import { AIAPI, ChatRole, Endpoints, UtilsAPI, VerbTense } from '../enums';
import { HttpService } from '../system/http.service';
import { UserService } from './user.service';
import { AudioSpeedRating } from '../constants';
import { ToastService } from '../system/toast.service';
import {
  ConversationPromptSettings,
  AgentCardsAbstractService,
  ConversationUserSettings,
  IAgentCard,
  ConversationMessagesDTO,
  TranscriptionsWhisper,
} from '@dataclouder/conversation-system';
import { FiltersConfig } from '@dataclouder/core-components';

export type AudioGenerated = { blobUrl: string; transcription: any };
export type TTSRequest = { text: string; voice: string; generateTranscription: boolean; speedRate: number; speed?: string; ssml?: string };
export type QueryResponse = { count: number; page: number; rows: IAgentCard[]; rowsPerPage: number; skip: number };

@Injectable({
  providedIn: 'root',
})
export class ConversationCardAIService implements AgentCardsAbstractService {
  constructor(private httpService: HttpService, private userService: UserService, private toastService: ToastService) {}

  public async findConversationByTitle(title: string): Promise<IAgentCard> {
    const filters: FiltersConfig = { filters: { title } };
    const response = await this.httpService.postDataToService<QueryResponse>(`${Endpoints.ConversationCard.ConversationQuery}`, filters);
    return response.rows[0];
  }

  public async getAudioTranscriptions(audioBlob: Blob, metadata: any = null): Promise<TranscriptionsWhisper> {
    return await this.httpService.uploadAudioFile(`${AIAPI.Whisper}`, audioBlob, metadata, 'python');
  }

  public async findAgentCards(paginator: FiltersConfig) {
    const response = await this.httpService.postDataToService(`${Endpoints.ConversationCard.ConversationQuery}`, paginator);

    return response;
  }

  public async getListModels(provider: string): Promise<any> {
    const data = await this.httpService.getDataFromService(`${Endpoints.ConversationCard.ListModels}?provider=${provider}`, 'python');
    return data;
  }

  public async callChatCompletion(conversation: ConversationPromptSettings | ConversationMessagesDTO): Promise<any> {
    let messages = conversation.messages.map((m) => ({ content: m.content, role: m.role }));
    messages = messages.filter((m) => m.role != ChatRole.AssistantHelper);
    const conversationFiltered = { ...conversation, messages };

    // return await this.httpService.postDataToService(`${AIAPI.GenerateChat}`, conversationFiltered, 'python');
    return await this.httpService.postDataToService(`${Endpoints.ConversationCard.AgentChat}`, conversationFiltered, 'python');
  }

  async translateConversation(currentLang: string, targetLang: string, idCard: string): Promise<ConversationUserSettings> {
    const response = await this.httpService.postDataToService(
      `${Endpoints.ConversationCard.TranslateConversation}`,
      { currentLang, targetLang, idCard },
      'python',
    );

    return response;
  }

  async getConversationUserChatSettings(): Promise<any> {
    const settings = (await this.userService.getUserSnapshot().settings.conversation) as any;
    return settings;
  }

  async saveConversationSettings(settings: any) {
    const response = await this.httpService.postDataToService('api/conversation-ai/settings', settings);
    return response;
  }

  async saveConversationCard(conversation: IAgentCard): Promise<IAgentCard> {
    if (conversation.id || conversation._id) {
      return await this.httpService.putDataToService(`${Endpoints.ConversationCard.Conversation}/${conversation._id}`, conversation);
    } else {
      return await this.httpService.postDataToService(`${Endpoints.ConversationCard.Conversation}`, conversation);
    }
  }

  async deleteConversationCard(id: string): Promise<any> {
    return await this.httpService.deleteDataFromService(`${Endpoints.ConversationCard.Conversation}/${id}`);
  }

  async findConversationCardByID(id: string): Promise<any> {
    return await this.httpService.getDataFromService(`${Endpoints.ConversationCard.Conversation}/${id}`);
  }

  async filterConversationCards(filters: FiltersConfig): Promise<any> {
    return await this.httpService.postDataToService(`${Endpoints.ConversationCard.ConversationQuery}`, filters);
  }

  async getAllConversationCards(): Promise<any[]> {
    return await this.httpService.getDataFromService(`${Endpoints.ConversationCard.Conversation}`);
  }

  // TODO: necesito ponerle un tipo al return.

  public async getTextAudioFile(tts: TTSRequest): Promise<AudioGenerated> {
    const httpReq: any = await this.httpService.receiveFile(`${AIAPI.GetTTS}`, tts, 'python');
    const audioData = { blobUrl: null, transcription: null };

    const transcription = httpReq?.headers.get('transcription');

    if (transcription) {
      const data = JSON.parse(transcription);
      audioData.transcription = data;
    }

    const mp3 = window.URL.createObjectURL(httpReq.body);
    audioData.blobUrl = mp3;

    return audioData;
  }

  async saveConversationUserChatSettings(newSettings: any): Promise<any> {
    // TODO: deberia tener un metodo updateUserSettings() pero mientras tengo que pasar todo el objeto settings en vez de solo conversation
    const settings = { ...this.userService.getUserSnapshot().settings };
    const conversation: any = newSettings;

    conversation.speed = AudioSpeedRating[conversation.speed ?? 3];

    settings.conversation = conversation;
    await this.userService.updateUser({ id: this.userService.getUserSnapshot().id, settings });

    this.toastService.success('Se actualizaron las configuraciones', 'Listo');

    // this.conversationSettingsChanged.emit();

    // TODO: save chatOptions
    // return await this.httpService.postDataToUrl('http://localhost:8080', 'api/conversation-ai/user-chat-settings', settings);
  }
}
