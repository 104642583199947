// NOTE: what to do? IAgent Deponds on ngx-agent-cards but lessons don't
// import { IAgentCard } from '@dataclouder/ngx-agent-cards';

import { InjectionToken, Provider, Type } from '@angular/core';

export type NotionDBResponse = {
  success: boolean;
  databases: { id: string; title: string; url: string; created_time: string }[];
  error?: string;
  count: number;
};

export type NotionPageResponse = {
  success: boolean;
  pages: { id: string; title: string; url: string; created_time: string }[];
  error?: string;
  count: number;
};

export const NOTION_SERVICE_TOKEN = new InjectionToken<NotionAbstractService>('notion.service');

export abstract class NotionAbstractService {
  abstract getDBAvailible(): Promise<NotionDBResponse>;
  abstract getPagesAvailable(): Promise<NotionPageResponse>;
  abstract createNotionPage(card: any): Promise<{ success: boolean; error: string; page: any }>;
  abstract getPageInSpecificFormat(pageId: string, format: NotionExportType): Promise<any>;
}

export function provideNotionService(serviceImplementation: Type<NotionAbstractService>): Provider[] {
  return [
    {
      provide: NOTION_SERVICE_TOKEN,
      useExisting: serviceImplementation,
    },
  ];
}

export enum NotionExportType {
  HTML = 'html',
  MARKDOWN = 'markdown',
  PLAIN_TEXT = 'plain_text',
  SIMPLE_BLOCKS = 'simple_blocks',
}
