
import { Component, OnInit, Input, AfterViewInit, OnDestroy, input, inject } from '@angular/core';
import { UntypedFormArray, FormBuilder, FormGroup, FormArray, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { MultiImagesStorageService } from '@dataclouder/ngx-cloud-storage';
import { extractJsonFromString } from '@dataclouder/ngx-core';
import { FormlyFieldConfig, FormlyModule } from '@ngx-formly/core';
import { NGXLogger } from 'ngx-logger';
import { ButtonModule } from 'primeng/button';
import { DividerModule } from 'primeng/divider';
import { DropdownModule } from 'primeng/dropdown';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { InputTextModule } from 'primeng/inputtext';
import { TextareaModule } from 'primeng/textarea';
import { Subject, takeUntil } from 'rxjs';
import { JapaneseExtraString, LangCodeDescription, LangWord } from 'src/app/core/lexicore.classes';

import { ArrayFormHandlerComponent } from './array-form-handler/array-form-handler.component';
import { LanguageTranlationFormComponent } from './language-tranlation-form/language-tranlation-form.component';
import { LearningExampleFormComponent, LearningForm } from './learning-example-form/learning-example-form.component';
import { JapaneseAdditionalFields } from './word-form-managers/language-extras';
import { WordFlashcardFormComponent } from './word-form-managers/word-flashcard-form';
import { AdminService } from '../../../core/data-services/admin.service';
import { AiService } from '../../../core/data-services/ai.service';
import { TopicService } from '../../../core/data-services/topic.service';
import { UserService } from '../../../core/data-services/user.service';
import { WordService } from '../../../core/data-services/word.service';
import { WordFormMode, useFrequencyOptions, wordTypeOptions } from '../../../core/enums';
import { LangWordStringInterface } from '../../../core/lexicore.classes';
import { AudioService } from '../../../core/system/audio.service';
import { ToastService } from '../../../core/system/toast.service';

@Component({
  selector: 'app-word-form',
  templateUrl: './word-form.component.html',
  styleUrls: ['./word-form.component.scss'],
  standalone: true,
  imports: [
    FormsModule,
    ReactiveFormsModule,
    DropdownModule,
    InputTextModule,
    TextareaModule,
    ButtonModule,
    LearningExampleFormComponent,
    FormlyModule,
    DividerModule,
    ArrayFormHandlerComponent,
    LanguageTranlationFormComponent,
    WordFlashcardFormComponent
],
})
export class WordFormComponent implements OnInit, AfterViewInit, OnDestroy {
  private topicService = inject(TopicService);
  private wordService = inject(WordService);
  private logger = inject(NGXLogger);
  private toastrService = inject(ToastService);
  private audioService = inject(AudioService);
  private multiImagesStorageService = inject(MultiImagesStorageService);
  protected formBuilder = inject(FormBuilder);
  private adminService = inject(AdminService);
  private aiService = inject(AiService);
  private dynamicDialogConfig = inject(DynamicDialogConfig);
  ref = inject(DynamicDialogRef);
  private userService = inject(UserService);

  @Input() langWord: LangWord = new LangWord();
  readonly topicId = input<string>(null); // Cuando el formulario lo activa un topic id inicia desde aquí
  @Input() mode: WordFormMode; // admin, suggestion, regular,

  extensionForm = new FormGroup({});

  extraFields: FormlyFieldConfig[] | null = null;

  public formGroup = this.formBuilder.group({
    word: ['', Validators.required],
    translation: [''],
    useFrequency: [1],
    respelledPhonetic: [''],
    wordCategory: [''],
    level: [1],
    ipaPhonetic: [''],
    hispanicPhonetic: [''],
    examples: this.formBuilder.array<FormGroup<string>>([]),
    synonyms: this.formBuilder.array<FormGroup<string>>([]),
    definitions: this.formBuilder.array<FormGroup<string>>([]),
    otherTranslations: this.formBuilder.array<FormGroup<string>>([]),
    learningExamples: this.formBuilder.array<LearningForm>([]),
    en: this.formBuilder.group({
      translation: [''],
      otherTranslations: this.formBuilder.array<FormGroup<string>>([]),
    }),
    es: this.formBuilder.group({
      translation: [''],
      otherTranslations: this.formBuilder.array<FormGroup<string>>([]),
    }),
    extra: this.extensionForm,
  });

  public loadingWord = false;
  public isSaving = false;
  public buttonText = 'Guardar Palabra';

  public modalRef;

  public useFrequencyOptions = useFrequencyOptions;
  public wordTypeOptions = wordTypeOptions;

  private destroy$ = new Subject<void>();

  private readonly ARRAY_CONTROLS = ['examples', 'synonyms', 'definitions', 'otherTranslations'] as const;
  private readonly NESTED_ARRAY_CONTROLS = ['en', 'es'] as const;

  /** Inserted by Angular inject() migration for backwards compatibility */
  constructor(...args: unknown[]);

  constructor() {
    if (this.dynamicDialogConfig.data) {
      this.langWord = this.dynamicDialogConfig.data.englishWord;
      this.mode = this.dynamicDialogConfig.data.mode;
    }
  }

  public ngOnInit(): void {
    const { baseLanguage, targetLanguage } = this.userService.getUserSnapshot().settings;

    if (targetLanguage === 'ja') {
      this.extraFields = JapaneseAdditionalFields;
      console.log('langWord', this.langWord, baseLanguage);
      if (this.langWord.extra) {
        this.extensionForm.patchValue(this.langWord.extra);
      }
      console.log('langWord', this.formGroup.value, baseLanguage);
    }
    if (this.langWord[baseLanguage]) {
      console.log('langWord', this.langWord, baseLanguage);
      this.langWord = { ...this.langWord, ...this.langWord[baseLanguage] };
      console.log('word', this.langWord);
    }

    if (this.mode === WordFormMode.Admin) {
      this.formGroup.addControl('learningExamples', new FormArray([]));

      if (this.langWord.learningExamples) {
        this.langWord.learningExamples.forEach((_) => {
          this.addLearningExampleForm();
        });
      }
    }

    if (this.langWord != null) {
      this.setFormData(this.langWord);
    }
  }

  ngAfterViewInit(): void {
    // No puedo usar takeUntilDestroyed porque hay que hacerlo en el contructor, es mucho problema injectar referencia, y detecto changes after view init, por eso a la antigua.
    const controlNames = ['examples', 'synonyms', 'definitions', 'otherTranslations'];

    controlNames.forEach((controlName) => {
      this.formGroup.controls[controlName].valueChanges.pipe(takeUntil(this.destroy$)).subscribe(() => {
        this.formGroup.controls[controlName].markAsDirty();
      });
    });

    const { targetLanguage } = this.userService.getUserSnapshot().settings;

    if (targetLanguage === 'ja') {
      if (this.langWord.extra) {
        this.extensionForm.patchValue(this.langWord.extra);
      }
    }

    console.log('formGroup final value', this.formGroup.value);
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  get otherTranslationsFormArray(): FormArray {
    return this.formGroup.controls.otherTranslations;
  }
  get synonymsFormArray() {
    return this.formGroup.controls.synonyms;
  }

  get definitionsFormArray() {
    return this.formGroup.controls.definitions;
  }

  get exampleFormArray() {
    return this.formGroup.controls.examples;
  }

  private getChangedValues(): any {
    const modifiedValues = {};

    Object.keys(this.formGroup.controls).forEach((controlName) => {
      const control = this.formGroup.get(controlName);
      if (control.dirty) {
        if (controlName === 'learningExamples') {
          this.toastrService.info('Asegúrate de guardar los ejemplos de aprendizaje', 'Los ejemplos se guardan por separado');
          return;
        }
        modifiedValues[controlName] = control.value;
      }
    });

    if (Object.keys(modifiedValues).length === 0) {
      this.toastrService.info('No se ha modificado la palabra', 'No hay cambios');
      return null;
    }
    return modifiedValues;
  }

  public async save(): Promise<void> {
    const isConfirm = confirm('Medida de seguridad, se  van a sobreescribir los cambios, ¿estás seguro de que quieres guardar la palabra?');
    if (!isConfirm) {
      return;
    }
    const wordForm: any = this.formGroup.getRawValue();

    const wordFormChanged = this.getChangedValues();
    if (!wordFormChanged) {
      return;
    }

    if (this.langWord) {
      this.logger.debug('editando', this.langWord);
      wordFormChanged.word = this.langWord.word;
    }
    let response = null;

    // TODO: IMPORTANTE DE VERDAD. esto puede desestabilizar mi base.
    // TODO: solo permitia sobreeescribir ciertas palabras.
    // AYUDARIA MUCHO Que si genero, hacer la comprobación manual que tenia antes.

    try {
      this.isSaving = true;
      const topicId = this.topicId();
      if (this.mode === WordFormMode.Suggestion) {
        response = this.wordService.promoteWord(wordForm);
      } else if (topicId) {
        response = await this.topicService.saveTopicWord(wordForm, topicId);
      } else if (this.mode === WordFormMode.Admin) {
        if ('translation' in wordFormChanged || 'otherTranslations' in wordFormChanged) {
          const base = this.userService.getUserSnapshot().settings.baseLanguage;
          wordFormChanged[base] = { translation: this.langWord.translation, otherTranslations: this.langWord.otherTranslations };
          if (wordFormChanged.translation) {
            wordFormChanged[base].translation = wordFormChanged.translation;
            delete wordFormChanged.translation;
          }
          if (wordFormChanged.otherTranslations) {
            wordFormChanged[base].otherTranslations = wordFormChanged.otherTranslations;
            delete wordFormChanged.otherTranslations;
          }
        }

        const lastToPost = { ...wordFormChanged, ...wordForm };

        response = await this.adminService.updateWord(lastToPost);
        this.toastrService.success(`Se guardó la palabra ${this.langWord.word}`, 'Guardado');
      }
    } catch (err) {
    } finally {
      this.isSaving = false;
      this.dismiss(response);
    }
  }

  public pushControlToFormArray(controlName: string): void {
    (this.formGroup.get(controlName) as UntypedFormArray).push(this.formBuilder.control(''));
  }

  private addEmptyArrayControl(englishWord: LangWord, property: string): void {
    if (englishWord[property] && Array.isArray(englishWord[property])) {
      englishWord[property].forEach((_) => {
        this.pushControlToFormArray(property);
      });
    } else {
      englishWord[property] = [];
    }
  }

  public deleteLearningExample(index: number): void {
    const confirm = window.confirm('¿Estás seguro de que quieres eliminar este ejemplo de aprendizaje?');
    if (!confirm) {
      return;
    }

    this.deleteFormArrayByIndex('learningExamples', index);
  }

  public deleteFormArrayByIndex(controlName: string, index: number): void {
    // sería ideal que los learning examples se eliminen uno por uno, asi puedo borrar la imagen en el storage, si no se sobreescribe el objeto y se queda atrapada.
    console.log('Eliminado del control', controlName, index);
    (this.formGroup.get(controlName) as UntypedFormArray).removeAt(index);
  }

  private initializeArrayControls(data: Partial<LangWord>): void {
    // Initialize main array controls
    this.ARRAY_CONTROLS.forEach((property) => {
      const arrayData = data[property] || [];
      const formArray = this.formGroup.get(property) as FormArray;

      // Clear existing controls
      while (formArray.length) {
        formArray.removeAt(0);
      }

      // Add new controls based on data
      arrayData.forEach(() => {
        formArray.push(this.formBuilder.control(''));
      });
    });

    // Initialize nested array controls for each language
    this.NESTED_ARRAY_CONTROLS.forEach((lang) => {
      const langData = (data[lang] || { otherTranslations: [] }) as { otherTranslations?: string[] };
      const langGroup = this.formGroup.get(lang) as FormGroup;
      const otherTranslationsArray = langGroup.get('otherTranslations') as FormArray;

      // Clear existing controls
      while (otherTranslationsArray.length) {
        otherTranslationsArray.removeAt(0);
      }

      // Add new controls based on data
      const translationsData = langData.otherTranslations || [];
      translationsData.forEach(() => {
        otherTranslationsArray.push(this.formBuilder.control(''));
      });
    });
  }

  public setFormData(dataObj: Partial<LangWord>): void {
    const data = { ...dataObj };

    // Initialize array controls first
    this.initializeArrayControls(data);

    // Then patch the form with all values
    this.formGroup.patchValue(data);
  }

  protected dismiss(response): void {
    this.ref.close(response);
  }

  get learningExamplesForm() {
    return this.formGroup.controls.learningExamples;
  }

  public addLearningExampleForm(data?: any): void {
    // Agrega un nuevo form, vacio o con data
    const learningForm = this.formBuilder.group({
      id: [data?.id || ''],
      example: [data?.example || ''],
      meaning: [data?.meaning || ''],
      scenario: [data?.scenario || ''],
      image: [data?.image || ''],
      tense: [data?.tense || ''],
      attribution: [data?.attribution || ''],
      audio: [data?.audio || ''],
      voiceType: [data?.voiceType || ''],
      difficultyLevel: [data?.difficultyLevel || ''],
      isPublic: [data?.isPublic || false],
      es: this.formBuilder.group({
        translation: [data?.translation || ''],
        meaningTranslation: [data?.spanishExplanation || ''],
      }),

      en: this.formBuilder.group({
        translation: [data?.translation || ''],
        meaningTranslation: [data?.spanishExplanation || ''],
      }),
    });

    this.learningExamplesForm.push(learningForm);
  }

  public playText(audio: any): void {
    if (audio.path) {
      this.audioService.playAudioWithStoragePath(audio.path);
    }
  }

  public async uploadImage(imageBlob: Blob): Promise<void> {
    const path = `words/${this.langWord.word}/images`;

    const imageUploaded = await this.multiImagesStorageService.uploadImage(imageBlob, path);
    this.modalRef.close();
    console.log(imageUploaded);
  }

  public async generateExample(): Promise<void> {
    const isConfirm = confirm('Se generará un ejemplo nuevo completamente aleatorio, si quieres dar la idea crea primero el ejemplo y autompleta con ai');
    if (!isConfirm) {
      return;
    }
    const word = this.formGroup.get('word').value;
    const example = await this.aiService.generateLearningExample(word);
    this.addLearningExampleForm(example);
    console.log(example);
  }

  public addEmptyExample(): void {
    this.addLearningExampleForm();

    setTimeout(() => {
      this.scrollToBottom('p-dialog-content');
    }, 250);
  }

  private scrollToBottom(elementId) {
    const element = document.getElementsByClassName(elementId)[0];
    if (!element) {
      return;
    }
    element.scrollTo({
      top: element.scrollHeight,
      behavior: 'smooth',
    });
  }

  public addFlashcard(): void {
    this.pushControlToFormArray('flashcards');
  }

  public isGenerating = false;

  public async completeWordWithAI(): Promise<void> {
    this.isGenerating = true;
    this.toastrService.info('Buscando información con IA...', 'Generando palabra');

    const { targetLanguage } = this.userService.getUserSnapshot().settings;
    const target = LangCodeDescription[targetLanguage];

    let prompt = `
    You are a helpful assistant that completes a word form with the missing information.
    The word is: ${this.formGroup.get('word').value || ''}
    all the information you give should be in ${target}
    return the information in JSON following the interface:
    ${LangWordStringInterface}
    `;

    if (targetLanguage === 'ja') {
      prompt += `
      also include in the same JSON this data and small examples for beginners in japanese:
      ${JapaneseExtraString}
      `;
    }
    const response = await this.aiService.callInstruction(prompt, { provider: 'google' });
    const word = extractJsonFromString(response.content);
    console.log(word);
    this.setFormData(word);
    this.toastrService.success('Palabra generada con éxito', 'Por favor, verifica la información y guarda la palabra');
    this.isGenerating = false;
  }
}
