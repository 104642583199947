import { Injectable, inject } from '@angular/core';

// import { Lesson, FiltersConfig } from '../classes';
import { FiltersConfig } from '@dataclouder/ngx-core';
import { ILesson, LessonsAbstractService } from '@dataclouder/ngx-lessons';

import { Endpoints, LessonApi, UserWebApi } from '../enums';
import { UserService } from './user.service';
import { HttpService } from '../system/http.service';
import { ToastService } from '../system/toast.service';

type LessonPaginator = { rows: ILesson[]; count: number };

@Injectable({
  providedIn: 'root',
})
export class LessonsService implements LessonsAbstractService {
  private httpService = inject(HttpService);
  private userService = inject(UserService);
  private toastrService = inject(ToastService);

  /** Inserted by Angular inject() migration for backwards compatibility */
  constructor(...args: unknown[]);

  constructor() {}

  updateLesson(lesson: ILesson): Promise<any> {
    throw new Error('Method not implemented.');
  }

  generateLesson(lesson: ILesson): Promise<any> {
    throw new Error('Method not implemented.');
  }

  public async postLesson(lesson: ILesson) {
    const langParams = this.userService.getUserLangOptions();

    return this.httpService.postDataToService<ILesson>(`${LessonApi.CreateLesson}?${langParams}`, lesson);
  }

  public async getLesson(id: string) {
    return this.httpService.getDataFromService<ILesson>(`${Endpoints.Lessons.Lesson}/${id}`);
  }

  public async getLessons(filterConfig: FiltersConfig = null, isPublished = true) {
    filterConfig.returnProps = {
      targetLang: 1,
      baseLang: 1,
      level: 1,
      title: 1,
      description: 1,
      createdDate: 1,
      tags: 1,
      media: 1,
    };
    const user = this.userService.getUserSnapshot();
    let appFilters: Record<string, any> = { targetLang: user.settings.targetLanguage, baseLang: user.settings.baseLanguage, isPublished };

    if (this.userService.isAdmin()) {
      this.toastrService.info('Eres administrador', 'puede ver todas las lecciones publicadas y no publicadas');
      appFilters = { targetLang: user.settings.targetLanguage, baseLang: user.settings.baseLanguage };
    }

    if (filterConfig) {
      filterConfig.filters = { ...appFilters, ...filterConfig.filters };
      filterConfig.sort = { level: 1, ...filterConfig.sort };
      return this.httpService.postDataToService<LessonPaginator>(Endpoints.Lessons.QueryLessons, filterConfig);
    } else {
      return this.httpService.postDataToService<LessonPaginator>(Endpoints.Lessons.QueryLessons, null);
    }
  }

  public async getPublicLessons() {
    return this.httpService.getDataFromService<ILesson[]>(LessonApi.GetPublicLessons);
  }

  public async deleteLesson(id: string) {
    return this.httpService.deleteDataFromService(`${LessonApi.DeleteLesson}/${id}`);
  }

  public saveTakenLesson(lesson: { lessonId: string; status: string; score: number }) {
    return this.httpService.postDataToService(UserWebApi.LessonTaken, lesson);
  }

  public async generateAudiosForLesson(lessonId: string) {
    // TODO: get the data
    return this.httpService.getDataFromService(`${LessonApi.GenerateMedia}/${lessonId}`, 'python');
  }

  public async postGenerateByAI(lessonId: string) {
    return this.httpService.postDataToService(`${LessonApi.GenerateLesson}`, { id: lessonId }, 'python');
  }

  public extractTextFromHtml(html: string) {
    const r1 = new RegExp('~(.+?)~', 'g');

    const lessonHtml = html.replace(r1, (_matching, jsonCoded) => {
      const data = JSON.parse(jsonCoded);
      return `<span>${data?.settings?.text}</span>`;
    });
    console.log('lessonHtml', lessonHtml);
    const onlyText = this.extractTextFromHTML(lessonHtml);
    return onlyText;
  }

  private extractTextFromHTML(htmlString) {
    // Remove HTML tags
    let text = htmlString.replace(/<[^>]*>/g, ' ');

    // Remove style and script content
    text = text.replace(/<style[^>]*>[\s\S]*?<\/style>/gi, ' ');
    text = text.replace(/<script[^>]*>[\s\S]*?<\/script>/gi, ' ');

    // Decode HTML entities
    text = text.replace(/&nbsp;/g, ' ');
    text = text.replace(/&amp;/g, '&');
    text = text.replace(/&lt;/g, '<');
    text = text.replace(/&gt;/g, '>');

    // Remove extra whitespace
    text = text.replace(/\s+/g, ' ').trim();

    return text;
  }

  public getRecommendedLessons() {
    return this.httpService.getDataFromService<ILesson[]>(LessonApi.Recommendations);
  }
}
