import { Injectable, inject } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { mergeMap, map, catchError, take, tap, filter } from 'rxjs/operators';

import { PhraseActions, PhraseSelectors } from '.';
import { PhraseService } from '../../data-services/phrase.service';
import { StoreUtilService } from '../store-util.service';



@Injectable()
export class PhrasesEffects {
  private storeUtilService = inject(StoreUtilService);
  private actions$ = inject(Actions);
  private phraseService = inject(PhraseService);

  /** Inserted by Angular inject() migration for backwards compatibility */
  constructor(...args: unknown[]);


  constructor() {
  }

  loadPhrases$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(PhraseActions.loadPhrases),
      filter(() => !this.isLoaded()),
      mergeMap(() => this.phraseService.getPhrases$().pipe(
        map(phrases => PhraseActions.loadPhrasesSuccess({ phrases })),
      )
      )
    )
  });

  private isLoaded(): boolean {
    const isloaded = this.storeUtilService.getSnapshot<boolean>(PhraseSelectors.getIsLoaded);
    return isloaded;
  }

}
