import { createSelector, createFeatureSelector } from '@ngrx/store';

import { PhrasesState } from '.';


const featurePhrases = createFeatureSelector<PhrasesState>('phrases');

export const getPhrases = createSelector(
  featurePhrases,
  (state: PhrasesState) => state.phrases
);

export const getIsLoading = createSelector(
  featurePhrases,
  (state: PhrasesState) => state.isLoading
);

export const getIsLoaded = createSelector(
  featurePhrases,
  (state: PhrasesState) => state.isLoaded
);
