<div> path: {{ storagePath }} </div>

<div class="options">
  @if (!isUploaded) {
    <div>
      <input type="file" (change)="fileChangeEvent($event)" />
      @if (!fileMetadata) {
        <em>Carga una imagen para comenzar</em>
      }
    </div>
  }

  @if (fileMetadata) {
    <span>
      <span style="margin: 1px 20px"> tipo: {{ fileMetadata.type }} </span>
      <span style="margin: 1px 20px"> tamaño {{ fileMetadata.size }} </span>
      <br />
      <input
        [disabled]="imageSettings()?.fileName"
        style="margin: 1px 20px; width: 400px"
        [(ngModel)]="renameFile"
        type="text"
        placeholder="Rename File"
        (ngModelChange)="reloadPath()" />
      <button class="btn-crop" (click)="simpleCropAndUpload()"> Recortar y Subir </button>
    </span>
  }
</div>

@if (!isUploaded) {
  <image-cropper [imageChangedEvent]="imageChangedEvent"
    [maintainAspectRatio]="true"
    [aspectRatio]="aspectRatio"
    format="webp"
    [resizeToWidth]="450"
    (imageCropped)="onInnerImageCropped($event)"
    (loadImageFailed)="loadImageFailed()"
    (imageLoaded)="imageLoaded($event)"
  [autoCrop]="false" />
}
<!-- <image-cropper></image-cropper> -->

@if (croppedImage && !isUploaded) {
  <button [disabled]="isLoading" nbButton status="info"> upload </button>
}
