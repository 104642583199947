import { createAction, props } from '@ngrx/store';

import { ProgressWordEvaluation } from '../../classes';
import { LangWord } from '../../lexicore.classes';

export const loadWords = createAction('[Words] load words');

export const setInitialState = createAction('[Words] set initial state');

export const resetInitialState = createAction('[Words] reset initial state');

export const setIsLoading = createAction('[Words] set is loading', props<{ isloading: boolean }>());

export const loadWordsSuccess = createAction('[Words] load words success', props<{ words: Array<LangWord> }>());

export const createWordSuccess = createAction('[Words] create word success', props<{ word: LangWord }>());

export const addWords = createAction('[Words] add words', props<{ word: LangWord[] }>());

export const updateWordSuccess = createAction('[Words] update word success', props<{ word: LangWord }>());

export const deleteWordSuccess = createAction('[Words] delete Word sucess', props<{ word: string }>());

export const updateProgressWord = createAction('[Words] update progress word success', props<{ progress: Array<ProgressWordEvaluation> }>());

export const updateWordProperty = createAction('[Words] update word property', props<{ word: string; property: string; value: any }>());
