import { Injectable, inject } from '@angular/core';
import { ConversationDTO, IAgentResponseDTO, IAIModel, IConversationSettings, TranscriptionsWhisper } from '@dataclouder/ngx-agent-cards';

import { LearningExample } from '../classes';
import { AIAPI, ChatRole, Endpoints, VerbTense } from '../enums';
import { UserService } from './user.service';
import { LangCodeDescription } from '../lexicore.classes';
import { HttpService } from '../system/http.service';

export type AudioGenerated = { blobUrl: string; transcription: any };
export type TTSRequest = { text: string; voice: string; generateTranscription: boolean; speedRate: number; speed?: string; ssml?: string };

@Injectable({
  providedIn: 'root',
})
export class AiService {
  private httpService = inject(HttpService);
  private userService = inject(UserService);

  /** Inserted by Angular inject() migration for backwards compatibility */
  constructor(...args: unknown[]);

  constructor() {}

  public async getServerStatus(): Promise<any> {
    return await this.httpService.getDataFromService(`${AIAPI.ServerStatus}`, 'python', true);
  }

  public async generateLearningExample(word: string): Promise<LearningExample> {
    const langString = this.userService.getUserLangOptions();

    return await this.httpService.getDataFromService(`${AIAPI.GenerateExample}/${word}?${langString}`, 'python');
  }

  public async generateWordAudio(word: string): Promise<any> {
    const langString = this.userService.getUserLangOptions();
    return await this.httpService.getDataFromService(`${AIAPI.GenerateWordAudio}/${word}?${langString}`, 'python');
  }

  public async getTextAudioFile(tts: TTSRequest): Promise<AudioGenerated> {
    const httpReq: any = await this.httpService.postFile(`${AIAPI.GetTTS}`, tts, 'python');
    const audioData = { blobUrl: null, transcription: null };

    const transcription = httpReq?.headers.get('transcription');

    if (transcription) {
      const data = JSON.parse(transcription);
      audioData.transcription = data;
    }

    const mp3 = window.URL.createObjectURL(httpReq.body);
    audioData.blobUrl = mp3;

    return audioData;
  }

  public async realTimeConversation(conversation: IConversationSettings): Promise<IConversationSettings> {
    return await this.httpService.postDataToService(`${AIAPI.RealTimeConversation}`, conversation);
  }

  public async generateAIExamples(word: string): Promise<any[]> {
    return await this.httpService.getDataFromService(`${AIAPI.GenerateExamples}/${word}`, 'python');
  }

  public async generateVerbExample(verb: string, tense: VerbTense): Promise<LearningExample> {
    const settings = { word: verb, tense };

    return await this.httpService.postDataToService(`${AIAPI.GenerateVerbExample}`, settings);
  }

  public async autocompleteLearningExample(word: string, learningExample: LearningExample): Promise<LearningExample> {
    const langString = this.userService.getUserLangOptions();

    return await this.httpService.postDataToService(`${AIAPI.AutocompleteWordLearningExample}/${word}?${langString}`, learningExample, 'python');
  }

  public async callChatCompletion(conversation: ConversationDTO): Promise<any> {
    // TODO: necesito filtrar solo la info de los mensaje que necesito, probamente no modificar el mensaje principal.

    let messages = conversation.messages.map((m) => ({ content: m.content, role: m.role }));
    messages = messages.filter((m) => m.role != ChatRole.AssistantHelper);
    const conversationFiltered = { ...conversation, messages };

    return await this.httpService.postDataToService(`${AIAPI.GenerateChat}`, conversationFiltered, 'python');
  }
  public async callInstruction(prompt: string, model: IAIModel = {}): Promise<IAgentResponseDTO> {
    const messages = [{ content: prompt, role: ChatRole.User }];
    const conversationDto = { messages, model };
    return await this.httpService.postDataToService(`${Endpoints.ConversationCard.AgentChat}`, conversationDto, 'python');
  }

  public async getAudioTranscriptions(audioBlob: Blob, metadata: any = null): Promise<TranscriptionsWhisper> {
    return await this.httpService.uploadAudioFile(`${AIAPI.Whisper}`, audioBlob, metadata, 'python');
  }

  public async generateVoiceForLearningExample(word: string, id: string): Promise<string> {
    return await this.httpService.postDataToService(`api/learningExamples/generateVoice/${word}/${id}`, 'python');
  }

  public async translate(text: string): Promise<string> {
    const data = { text, target_lang: LangCodeDescription.es, source_lang: LangCodeDescription.en };
    return await this.httpService.postDataToService(`${Endpoints.ConversationCard.TranslateText}`, data, 'python');
  }

  public async getListModels(provider: string): Promise<any> {
    return await this.httpService.getDataFromService(`${AIAPI.ListModels}/${provider}`, 'python');
  }
}
