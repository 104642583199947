
import { Component, inject } from '@angular/core';
import { MessageService } from 'primeng/api';
import { ButtonModule } from 'primeng/button';
import { ProgressBar, ProgressBarModule } from 'primeng/progressbar';
import { ToastModule } from 'primeng/toast';
import { LoadingBarService } from './loading-bar.service';

@Component({
  selector: 'dc-loading-bar',
  templateUrl: './loading-bar.html',
  styleUrls: ['./loading-bar.css'],
  standalone: true,
  providers: [MessageService],
  imports: [ButtonModule, ToastModule, ProgressBarModule, ButtonModule, ProgressBar],
})
export class LoadingBarComponent {
  loadingBarService = inject(LoadingBarService);

  /** Inserted by Angular inject() migration for backwards compatibility */
  constructor(...args: unknown[]);

  constructor() {}
}
