import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class UtilsService {
  //Convierte un objeto de clase a Json
  public toPlainObject(objectClass: any) {
    return JSON.parse(JSON.stringify(objectClass));
  }

  objectToQueryString(obj: any): string {
    const keyValuePairs = [];
    for (const key in obj) {
      if (obj.hasOwnProperty(key)) {
        keyValuePairs.push(encodeURIComponent(key) + '=' + encodeURIComponent(obj[key]));
      }
    }
    return keyValuePairs.join('&');
  }

  public sleepAsync(miliSeconds: number): Promise<void> {
    return new Promise((resolve) => setTimeout(resolve, miliSeconds));
  }

  public removeAccents(str): string {
    const accents = 'ÀÁÂÃÄÅàáâãäåÒÓÔÕÕÖØòóôõöøÈÉÊËèéêëðÇçÐÌÍÎÏìíîïÙÚÛÜùúûüÑñŠšŸÿýŽž';
    const accentsOut = 'AAAAAAaaaaaaOOOOOOOooooooEEEEeeeeeCcDIIIIiiiiUUUUuuuuNnSsYyyZz';
    str = str.split('');
    const strLen = str.length;
    let i, x;
    for (i = 0; i < strLen; i++) {
      if ((x = accents.indexOf(str[i])) != -1) {
        str[i] = accentsOut[x];
      }
    }
    return str.join('');
  }

  public removeSpecialCharacters(str: string) {
    const noSpecialCharacters = str.replace(/[^a-zA-Z0-9 ]/g, '');
    return noSpecialCharacters;
  }

  public getRandom(options: Array<any>) {
    return options[Math.floor(Math.random() * options.length)];
  }

  capitalizeWord(word) {
    return word.charAt(0).toUpperCase() + word.slice(1);
  }

  public downloadConversation(jsonObject: Record<string, any>, fileName: string) {
    const jsonData = JSON.stringify(jsonObject, null, 2);
    const blob = new Blob([jsonData], { type: 'application/json' });
    const url = URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = `${fileName}.json`;
    a.click();
    window.URL.revokeObjectURL(url);
  }

  constructor() {}
}
