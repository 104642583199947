import { Injectable, inject } from '@angular/core';
import { DCFlashcard, FlashcardAbstractService } from '@dataclouder/dc-flashcard';
import { Endpoints } from 'src/app/core/enums';

import { UserService } from './data-services/user.service';
import { HttpService } from './system/http.service';

@Injectable({
  providedIn: 'root',
})
export class FlashcardService implements FlashcardAbstractService {
  private httpService = inject(HttpService);
  private userService = inject(UserService);

  /** Inserted by Angular inject() migration for backwards compatibility */
  constructor(...args: unknown[]);

  constructor() {}

  public saveFlashcard(flashcard: DCFlashcard): Promise<DCFlashcard> {
    const lang = this.userService.getUserLangOptions();
    return this.httpService.postDataToService(`${Endpoints.Flashcard.SaveFlashcard}?${lang}`, flashcard);
  }
}
