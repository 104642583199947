<div class="form-input-card">
  <div>{{ title }}</div>
  <div [innerHTML]="message">

    <br />
    @if (subtext) {
      <div class="subtext">
        {{ subtext }}
      </div>
    }
  </div>

  <div>
    <div style="float: right">
      @if (oneBotton) {
        <p-button label="Ok" (click)="confirm()"></p-button>
      }

    </div>

    @if (!oneBotton) {
      <div class="options">
        <p-button [label]="cancelText" (click)="cancel()" severity="secondary"></p-button>
        <p-button [label]="acceptText" (click)="confirm()" severity="danger"></p-button>
      </div>
    }
  </div>
</div>