import { NgxLoggerLevel } from 'ngx-logger';

export const environment = {
  version: '0.0.13',
  envName: 'PRO',
  production: true,
  backendPythonUrl: 'https://python-server-567696811537.us-central1.run.app',
  backendNodeUrl: 'https://node-server-567696811537.us-central1.run.app',
  clientId: '567696811537-o9kifuiqvrbt0omj0j65ek9cth1kmujt.apps.googleusercontent.com',

  mobile: {
    appleAppId: 'com.polilan.app',
    appleRedirectURI: 'https://appingles-pro.firebaseapp.com/__/auth/handler',
    androidClientId: '567696811537-ie7v0iuq7hvnrs00d2eduh9vp0s8dg95.apps.googleusercontent.com', // No la uso
    iosClientId: '567696811537-9oujl8nolm918ei8onjtvhi2ushvk20n.apps.googleusercontent.com',
  },

  firebase: {
    apiKey: 'AIzaSyAghv8OBPBJGayE8DpkdwHFkG03H9HlHes',
    authDomain: 'appingles-pro.firebaseapp.com',
    databaseURL: 'https://appingles-pro.firebaseio.com',
    projectId: 'appingles-pro',
    storageBucket: 'appingles-pro.appspot.com',
    messagingSenderId: '567696811537',
    appId: '1:567696811537:web:085f5912ef28ef71bbb3b0',
  },
  logLevel: NgxLoggerLevel.INFO,
  // googleAnalytics: "G-88TL1EH47G", // TODO poner etiqueta de prod
  gtm: 'GTM-KKW6C7Z',
};
