import { Injectable } from '@angular/core';
import { ToastAlertsAbstractService, ToastData } from '@dataclouder/core-components';
// import { ToastAlertsAbstractService, ToastData } from 'projects/dataclouder/conversation-system/src/public-api';
import { MessageService } from 'primeng/api';

@Injectable({
  providedIn: 'root',
})
export class ToastAlertService extends ToastAlertsAbstractService {
  public messageService: MessageService;

  public setMessageService(messageService: MessageService): void {
    this.messageService = messageService;
  }

  public success(data: ToastData) {
    this.messageService.add({
      key: 'main-toast',
      severity: 'success',
      summary: data.title,
      detail: data.subtitle,
      life: 4000,
    });
  }

  info(data: ToastData): void {
    this.messageService.add({
      key: 'main-toast',
      severity: 'info',
      summary: data.title,
      detail: data.subtitle,
      life: 4000,
    });
  }

  warn(data: ToastData): void {
    this.messageService.add({
      key: 'main-toast',
      severity: 'warn',
      summary: data.title,
      detail: data.subtitle,
      life: 4500,
    });
  }

  error(data: ToastData): void {
    try {
      this.messageService.add({
        key: 'main-toast',
        severity: 'error',
        summary: data.title,
        detail: data.subtitle,
        life: 4000,
      });
    } catch (error) {
      console.error('Error en ToastService.error');
    }
  }
}
