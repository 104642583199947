/**
 * Extracts and parses the first JSON object or array found within a string.
 *
 * @param content - The input string that may contain JSON data
 * @returns {any} The parsed JSON data if found and valid, or null if:
 *   - No JSON object/array is found in the string
 *   - The found JSON is invalid and cannot be parsed
 *
 * @example
 * // Returns {name: "John"}
 * extractJsonFromString('Some text {"name": "John"} more text');
 *
 * // Returns [1, 2, 3]
 * extractJsonFromString('Before [1,2,3] after');
 *
 * // Returns null
 * extractJsonFromString('No JSON here');
 */
export function extractJsonFromString(content: string): any {
  console.log(content);
  try {
    // First try to parse the entire string as JSON
    return JSON.parse(content);
  } catch {
    try {
      // If that fails, try to find the first valid JSON object or array in the string
      const matches = content.match(/(\{[\s\S]*\}|\[[\s\S]*\])/g);
      if (!matches) return null;

      // Try each matched potential JSON string until we find a valid one
      for (const match of matches) {
        try {
          return JSON.parse(match);
        } catch {
          continue;
        }
      }
      return null;
    } catch (error) {
      console.error('Error parsing JSON:', error);
      return null;
    }
  }
}
