import { Injectable, inject } from '@angular/core';
import { Store } from '@ngrx/store';
import { take } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class StoreUtilService {
  private store = inject(Store);

  /** Inserted by Angular inject() migration for backwards compatibility */
  constructor(...args: unknown[]);


  constructor() { }

  public getSnapshot<T>(selector): Readonly<T> {
    let data: T;
    this.store.select<T>(selector).pipe(take(1)).subscribe(d => data = d);
    return data;
  }

}
