import { Injectable, inject } from '@angular/core';
import { getDownloadURL, ref, Storage } from '@angular/fire/storage';
@Injectable({
  providedIn: 'root',
})
export class FilesCacheService {
  private storage = inject(Storage);

  /** Inserted by Angular inject() migration for backwards compatibility */
  constructor(...args: unknown[]);

  constructor() {}

  public files: { [key: string]: string } = {};

  public async getURLSrcFile(path: string): Promise<string> {
    if (path in this.files) {
      return this.files[path];
    } else {
      const url = await getDownloadURL(ref(this.storage, path));
      const localUrl = await this.donwloadFileAndGetLocalURL(url);
      this.files[path] = localUrl;
      return localUrl;
    }
  }

  public getBlob(url: string): Promise<Blob> {
    return new Promise((resolve, reject) => {
      const xhr = new XMLHttpRequest();
      xhr.responseType = 'blob';
      xhr.overrideMimeType('audio/mp3');

      xhr.onload = (event) => {
        const blob = xhr.response;
        resolve(blob);
      };
      xhr.onerror = (event) => {
        reject(event);
      };

      xhr.open('GET', url);
      xhr.send();
    });
  }

  public async donwloadFileAndGetLocalURL(url: string) {
    const blob = await this.getBlob(url);

    return URL.createObjectURL(blob);
  }
}
