import { InjectionToken, Type } from '@angular/core';
import { Provider } from '@angular/core';
import { ChatUserSettings, IAgentCard, ModelName, IConversationSettingsDTO, IAgentResponseDTO, TTSRequest } from './agent.models';
import { FiltersConfig, IFilterQueryResponse } from '@dataclouder/ngx-core';

export const CONVERSATION_AI_TOKEN = new InjectionToken<AgentCardsAbstractService>('Conversation Ai Service');

// abstract-my-service.ts
export abstract class AgentCardsAbstractService {
  // TODO define the type of return value
  abstract callChatCompletion(conversation: IConversationSettingsDTO): Promise<IAgentResponseDTO>;
  // Get conversation card by id
  abstract findConversationCardByID(id: string): Promise<IAgentCard>;

  abstract filterConversationCards(filters: FiltersConfig): Promise<{ rows: IAgentCard[]; count: number; skip: number }>;
  // Get all conversation cards
  abstract getAllConversationCards(): Promise<IAgentCard[]>;
  // This is the one to use to filter cards. TODO! change the returning type to be a paginator
  abstract findFilteredAgentCards(paginator: FiltersConfig): Promise<IFilterQueryResponse<IAgentCard>>;
  abstract findAgentCardByTitle(title: string): Promise<IAgentCard>;

  abstract saveConversationCard(conversation: IAgentCard): Promise<IAgentCard>;

  abstract deleteConversationCard(id: string): Promise<IAgentCard>;

  abstract getTextAudioFile(tts: TTSRequest): Promise<any>;

  abstract getConversationUserChatSettings(): Promise<ChatUserSettings>;

  abstract getListModels(provider: string): Promise<ModelName[]>;

  abstract translateConversation(currentLang: string, targetLang: string, id: string): Promise<ChatUserSettings>;

  abstract getAudioTranscriptions(audio: any, options: any): Promise<any>;

  abstract callInstruction(prompt: string, IAIModel: any): Promise<any>;

  // abstract getConversationPromptSettings(): Promise<IConversationSettings>;

  abstract saveConversationUserChatSettings(conversation: ChatUserSettings): Promise<ChatUserSettings>;
}

// my-service.provider.ts
export function provideChatAIService(serviceImplementation: Type<AgentCardsAbstractService>): Provider[] {
  return [
    {
      provide: CONVERSATION_AI_TOKEN,
      useExisting: serviceImplementation,
    },
  ];
}
