
import { Component, OnInit, Input, HostListener, inject } from '@angular/core';
import { ButtonModule } from 'primeng/button';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';

@Component({
  selector: 'app-confirm',
  templateUrl: './confirm.component.html',
  styleUrls: ['./confirm.component.scss'],
  standalone: true,
  imports: [ButtonModule],
})
export class ConfirmComponent implements OnInit {
  private dynamicDialogConfig = inject(DynamicDialogConfig);
  ref = inject(DynamicDialogRef);

  @Input() title: string = 'Confirmación';
  @Input() message: string;
  @Input() subtext: string;

  @Input() oneBotton: boolean;

  @Input() acceptText: string;
  @Input() cancelText: string;

  @HostListener('window:keyup.enter', ['$event'])
  onEnter(_event: any) {
    this.confirm();
  }

  @HostListener('window:keyup.escape', ['$event'])
  onEscapte(_event: any) {
    this.cancel();
  }

  /** Inserted by Angular inject() migration for backwards compatibility */
  constructor(...args: unknown[]);

  constructor() {
    if (this.dynamicDialogConfig.data) {
      this.title = this.dynamicDialogConfig.data.title;
      this.message = this.dynamicDialogConfig.data.message;
      this.subtext = this.dynamicDialogConfig.data.subtext;
      this.oneBotton = this.dynamicDialogConfig.data.oneBotton;
      this.acceptText = this.dynamicDialogConfig.data.acceptText ?? 'Aceptar';
      this.cancelText = this.dynamicDialogConfig.data.cancelText ?? 'Cancelar';
    }
  }

  ngOnInit(): void {}

  public cancel(): void {
    this.ref.close(false);
  }

  public confirm(): void {
    this.ref.close(true);
  }
}
