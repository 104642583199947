<dc-loading-bar />

<router-outlet />

<p-toast key="main-toast" [baseZIndex]="9999" />

<p-toast key="social" [style]="{ width: '30rem' }" [preventOpenDuplicates]="true" [baseZIndex]="9999">
  <ng-template let-message pTemplate="message">
    <div class="flex gap-3 p-0 -m-4">
      <img height="120px" src="assets/gif/llama.gif" alt="Image" />
      <div class="flex flex-column gap-3 py-3">
        <h1 class="m-0 font-semibold text-base text-900">{{ message.summary }}</h1>
        <span class="text-base text-700">{{ message.detail }}</span>

        <div class="flex gap-3">
          <button (click)="toSocialNetwork('tiktok')" pButton pRipple label="Tiktok" class="p-button-text p-0"></button>
          <button style="color: rgb(19, 131, 211)" (click)="toSocialNetwork('instagram')" pButton pRipple label="Instagram" class="p-button-text p-0"></button>

          <button style="color: rgb(211, 19, 48)" (click)="toSocialNetwork('youtube')" pButton pRipple label="Youtube" class="p-button-text p-0"></button>
        </div>
      </div>
    </div>
  </ng-template>
</p-toast>

<!-- <div class="loader" *ngIf="isLoading | async"></div> -->
<!-- <ngx-guided-tour closeText="Cerrar" backText="Atras" doneText="Terminar" nextText="Siguiente" skipText="Saltar"> </ngx-guided-tour> -->
