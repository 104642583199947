import { InjectionToken, Type } from '@angular/core';
import { Provider } from '@angular/core';
import { ConversationDTO, IConversationCard } from './conversation.interface';

export const CONVERSATION_AI_TOKEN = new InjectionToken<ConversationAIAbstractService>('Conversation Ai Service');

// abstract-my-service.ts
export abstract class ConversationAIAbstractService {
  // TODO define the type of return value
  abstract callChatCompletion(conversation: ConversationDTO): Promise<any>;

  abstract findConversationCard(id: string): Promise<IConversationCard>;

  abstract getAllConversationCards(): Promise<IConversationCard[]>;

  abstract saveConversationCard(conversation: IConversationCard): Promise<IConversationCard>;

  abstract deleteConversationCard(id: string): Promise<IConversationCard>;

  abstract getTextAudioFile(tts: any): Promise<any>;
}

// my-service.provider.ts
export function provideChatAIService(serviceImplementation: Type<ConversationAIAbstractService>): Provider[] {
  return [
    {
      provide: CONVERSATION_AI_TOKEN,
      useClass: serviceImplementation,
    },
  ];
}
