import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { NGXLogger } from 'ngx-logger';
import { Store } from '@ngrx/store';

import { User } from '../classes';
import { HttpService } from '../../core/system/http.service';
import { PermissionType, RolType, PlanType, UserWebApi } from '../../core/enums';
import { UserActions, UserSelectors } from '../../core/store/user';
import { StoreUtilService } from '../store/store-util.service';
import { FirebaseAuthService } from '../firebase-auth.service';
import { UtilsService } from '../utils.service';

@Injectable({
  providedIn: 'root',
})
export class UserService {
  public user$: Observable<User> = this.httpService.getObservable(UserWebApi.User);

  public userObs$ = this.store.select(UserSelectors.getUser);

  constructor(
    private storeUtilService: StoreUtilService,
    private store: Store,
    private logger: NGXLogger,
    private httpService: HttpService,
    private firebaseAuthService: FirebaseAuthService,
    private utilsService: UtilsService,
  ) {}

  public getUser$() {
    this.store.dispatch(UserActions.setIsLoading({ isloading: true }));

    return this.httpService.getObservable<User>(UserWebApi.User).pipe(
      tap((user) => {
        this.store.dispatch(UserActions.setUser({ user }));
      }),
    );
  }

  public getUserSnapshot(): User {
    return this.storeUtilService.getSnapshot(UserSelectors.getUser);
  }

  public getUserLangOptions() {
    const settings = this.getUserSnapshot().settings;
    return this.utilsService.objectToQueryString({ base: settings.baseLanguage || 'es', target: settings.targetLanguage });
  }

  public async deleteUserAccount() {
    return this.httpService.deleteDataFromService(UserWebApi.User);
  }

  public async registerUser(data, id): Promise<void> {
    this.logger.debug('Registrando usuario', data, id);
    try {
      await this.httpService.createUserData(data, id);
      this.logger.debug('Se registró el usuario');
    } catch (err) {
      // TODO: debo tener algún sistema que pueda detectar este tipo de errores graves.
      this.logger.debug('Ocurrió un error grave, no se pudo registrar el usuario validar');
      throw err;
    }
  }

  public async updateNewsViewed() {
    await this.httpService.postDataToService(UserWebApi.UserNewsViewed, {});
  }

  public updateOnlyDispatchUserState(user: Partial<User>) {
    this.store.dispatch(UserActions.updateUserProperties({ user }));
  }

  public updateUserState(property: string, state: any) {
    // Update valid user state, every new state should be programed here.
    // settings , subscription
    if (property === 'claims') {
      // TODO entender aquí como modificar la suscripción
      // const suscription = state;
      const user: Partial<User> = { claims: state };
      this.store.dispatch(UserActions.updateUserProperties({ user }));
    } else if (property === 'settings') {
      const settings = state;
      this.store.dispatch(UserActions.updateSettings({ settings }));
    }
  }

  public updateUserStepsState(steps: any) {
    return this.httpService.postDataToService(UserWebApi.UserSteps, steps);
  }

  public async updateUser(user: Partial<User>) {
    // need id and whatever attribute to update {id: 1, settings: {}}
    try {
      const results = await this.httpService.postDataToService(UserWebApi.UpdateUser, user);
      await this.store.dispatch(UserActions.updateUserProperties({ user }));
      return results;
    } catch (err) {
      this.logger.error('Ocurrió un problema al guardar el usuario', err);
    }
  }

  public async changeLanguage(lang: any) {
    try {
      const results = await this.httpService.postDataToService(UserWebApi.ChangeLanguage, { lang });
      console.log('results', results);
      const user = { settings: results.settings, languageProgress: results.languageProgress, recommendations: results.recommendations };
      await this.store.dispatch(UserActions.updateUserProperties({ user }));
    } catch (err) {
      // console.log('results', results);
    }
  }

  public async applyPromoCode(promoCode: string) {
    const response: any = await this.httpService.getDataFromAPI(UserWebApi.UserPromoCode + '/' + promoCode);
    this.updateUserClaimsAndRefresh(response.claims);
    return response;
  }

  public async updateUserClaimsAndRefresh(claims: any) {
    await this.updateUser({ claims });
    await this.firebaseAuthService.refreshToken();
  }

  public isAdmin(): boolean {
    return this.hasRole(RolType.Admin);
  }

  public isPremium(): boolean {
    const plan = this.getUserSnapshot()?.claims?.plan;
    return plan?.type === PlanType.Premium;
  }

  public isFollowerOrBetaOrPremimPlan(follower = true, beta = true, premium = true): boolean {
    const plan = this.getUserSnapshot().claims?.plan;

    const validPlans = [];
    if (follower) {
      validPlans.push(PlanType.Follower);
    }
    if (beta) {
      validPlans.push(PlanType.Beta);
    }
    if (premium) {
      validPlans.push(PlanType.Premium);
    }

    const hasAccess = validPlans.includes(plan.type);

    if (hasAccess) {
      if (plan.exp === null) {
        return true;
      }
      const expireDate = new Date(plan.exp);
      const currentDate = new Date();
      if (expireDate < currentDate) {
        console.warn('role privilege expired');
        return false;
      }
      return true;
    }
    return false;
  }

  public isTester(): boolean {
    return this.hasRole(RolType.Tester);
  }

  public hasRole(rol: RolType): boolean {
    const roles = this.getUserSnapshot().claims?.roles;
    if (roles && rol in roles) {
      if (roles[rol] === null) {
        return true;
      } else {
        const expireDate = new Date(roles[rol]);
        const currentDate = new Date();
        if (expireDate < currentDate) {
          console.warn('role privilege expired');
          return false;
        } else {
          return true;
        }
      }
    }
    return false;
  }

  public hasPermission(permission: PermissionType): boolean {
    try {
      const permissionExpireDate: any = this.getUserSnapshot().claims?.permissions[permission];
      // undefined is no permission, null es no limit
      if (permissionExpireDate === null) {
        return true;
      } else {
        const expireDate = new Date(permissionExpireDate);
        const currentDate = new Date();

        if (expireDate < currentDate) {
          console.warn('permission privilege expired');
          return false;
        }
      }
    } catch (err) {
      return false;
    }
    return false;
  }

  public hasVocabulariPermission(): boolean {
    // Ver si puedo refactorizar para otros permisos valida que tenga o un plan o el permiso activo, null es infinito, undefined es no tiene
    const claims = this.getUserSnapshot().claims;
    if ([PlanType.Beta, PlanType.Follower, PlanType.Premium].includes(claims?.plan.type)) {
      return true;
    }

    const vocabularyExpDate: any = (claims?.permissions || {})[PermissionType.Vocabulary];

    if (vocabularyExpDate === null) {
      return true;
    } else if (vocabularyExpDate === undefined) {
      return false;
    } else {
      const expireDate = new Date(vocabularyExpDate);
      if (expireDate < new Date()) {
        return false;
      }
    }

    return true;
  }

  public async getStats() {
    const langString = this.getUserLangOptions();
    return await this.httpService.getDataFromAPI(`${UserWebApi.UserStats}?${langString}`);
  }

  public async updateLanguageProgress(langProgress: any) {
    const results = await this.httpService.postDataToService(UserWebApi.LangProgress, langProgress);
    console.log('results', results);
  }
}
