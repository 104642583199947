<div>
  <div>
    <p-message>Construcción del componente de Selección, sirve para hacer una pregunta y mostrar varias opciones, ejemplo:</p-message>
  </div>

  <div>
    <span>En que año llegó cristobal colon a america?</span>
    <app-selector [config]="sampleConfig"></app-selector>
  </div>

  <hr />

  <div>
    <form class="builder-form" [formGroup]="formGroup">
      <input class="form-input" type="text" pInputText fullWidth formControlName="response" placeholder="Respuesta Correcta..." />
      <br />

      <input class="form-input" type="" pInputText fullWidth formControlName="hint" placeholder="Escribe una pista para esta pregunta" />

      <br />
      <input
        class="form-input"
        type="text"
        pInputText
        fullWidth
        formControlName="explanation"
        placeholder="Excribe una explicación para la respuesta" />

      <hr />
      <h6>Opciones</h6>

      <div class="form-group" formArrayName="options">
        <div
          style="display: flex; gap: 10px; align-items: center; justify-content: space-between; margin-bottom: 10px; flex-direction: column"
          *ngFor="let item of optionsForm.controls; let i = index">
          <div>
            <input type="text" pInputText fullWidth [formControlName]="i" />
            <p-button (click)="deleteFormArrayByIndex('options', i)" icon="pi pi-times" severity="danger"></p-button>
          </div>
        </div>
      </div>

      <p-button (click)="pushControlToFormArray('options')" label="Agregar Opción" [text]="true" severity="help"></p-button>
    </form>

    <!-- <button nbButton (click)="isRendered = !isRendered"> Renderizar </button> -->

    <div *ngIf="isRendered">
      <!-- TODO:  probably i need to pass some params -->
      <app-selector></app-selector>
    </div>
  </div>

  <div>
    <p-button (click)="copyToClipboard()" [disabled]="formGroup.invalid" label="Copia Código" [rounded]="true"></p-button>
    <p-button (click)="showCode()" [disabled]="formGroup.invalid" label="Mostrar" [rounded]="true" severity="secondary"></p-button>
  </div>
</div>
