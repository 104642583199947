import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';


import { ButtonModule } from 'primeng/button';

import { SpeakerCompConfiguration } from '../lessons.clases';

@Component({
  selector: 'app-translation-switcher',
  standalone: true,
  imports: [ButtonModule],
  templateUrl: './translationSwitcher.component.html',
  styleUrl: './translationSwitcher.component.css',
  changeDetection: ChangeDetectionStrategy.Default,
})
export class TranslationSwitcherComponent implements OnInit {
  @Input() config: SpeakerCompConfiguration;

  public visibleText = '';
  ngOnInit(): void {
    this.visibleText = this.config.settings.text;
    console.log('config', this.config);
  }

  public switchTranslation() {
    if (this.visibleText === this.config.settings.text) {
      this.visibleText = this.config.settings.response;
    } else {
      this.visibleText = this.config.settings.text;
    }
  }
}
