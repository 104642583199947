import { Injectable } from '@angular/core';

/**
 * Service for managing a loading/progress bar component in the application.
 * 
 * This service provides methods to control the visibility, progress state, and appearance
 * of a loading bar. It supports both determinate (with specific progress percentage) 
 * and indeterminate modes, as well as different color options.
 */
@Injectable({
  providedIn: 'root',
})
export class LoadingBarService {
  /** Flag indicating whether the loading bar is currently visible */
  public isShowing: boolean = false;
  
  /** Current progress value (0-100) for determinate mode */
  public progress: number = 0;
  
  /** The current display mode of the loading bar */
  public mode: 'indeterminate' | 'determinate' = 'indeterminate';
  
  /** The color of the loading bar (can be a theme color name or hex value) */
  public color: string = 'primary';
  
  /** Interval reference for the fake progress animation */
  private progressInterval: any;
  constructor() {}

  /**
   * Shows the loading bar in determinate mode with 0% progress.
   * Use this method when starting a process with known progress states.
   */
  public showProgressBar() {
    this.isShowing = true;
    this.mode = 'determinate';
    this.progress = 0;
  }

  /**
   * Shows the loading bar in indeterminate mode.
   * Use this method when the progress of a process cannot be determined.
   */
  public showIndeterminate() {
    this.isShowing = true;
    this.mode = 'indeterminate';
    this.progress = 0;
  }

  /**
   * Shows the loading bar in determinate mode with a specific progress value.
   * 
   * @param progress The progress value to display (0-100)
   */
  public showProgressBarWithProgress(progress: number) {
    this.isShowing = true;
    this.mode = 'determinate';
    this.progress = progress;
  }

  /**
   * Hides the loading bar and resets its state.
   * Call this method when a process completes or when you want to hide the loading bar.
   */
  public hideProgressBar() {
    this.isShowing = false;
    this.mode = null;
    this.progress = 0;
    this.color = 'primary';
  }

  /**
   * Shows a success state (green color, 100% progress) and then automatically hides the loading bar after a delay.
   * Use this method to indicate successful completion of a process with visual feedback.
   */
  public successAndHide() {
    // Clear any existing progress interval
    if (this.progressInterval) {
      clearInterval(this.progressInterval);
      this.progressInterval = null;
    }
    
    this.isShowing = true;
    this.mode = 'indeterminate';
    this.progress = 100;
    this.color = '#4BBD97';
    setTimeout(() => {
      this.hideProgressBar();
    }, 1600);
  }

  /**
   * Demonstrates the loading bar by simulating progress from 0% to 100% over 5 seconds.
   * This is a test/demo method to visualize how the loading bar works.
   */
  public testProgressBar() {
    this.showProgressBarWithProgress(0);
    setTimeout(() => {
      this.showProgressBarWithProgress(20);
    }, 1000);
    setTimeout(() => {
      this.showProgressBarWithProgress(40);
    }, 2000);
    setTimeout(() => {
      this.showProgressBarWithProgress(60);
    }, 3000);
    setTimeout(() => {
      this.showProgressBarWithProgress(80);
    }, 4000);
    setTimeout(() => {
      this.showProgressBarWithProgress(100);
    }, 5000);
  }

  /**
   * Animates the progress bar from 0 to 100 over a specified time period
   * use when you dont know the duration of the process
   * if your process finish before the time ends call successAndHide()
   * @param durationInSeconds The total duration of the animation in seconds (default: 20)
   */
  public fakeProgressWithTime(durationInSeconds: number = 20): void {
    // Reset progress to 0
    this.showProgressBarWithProgress(0);

    // Calculate how often to update based on the duration
    const totalSteps = 50; // 0 to 100
    const intervalMs = (durationInSeconds * 1000) / totalSteps;
    let currentProgress = 0;

    // Clear any existing interval first
    if (this.progressInterval) {
      clearInterval(this.progressInterval);
    }

    // Create interval to update progress
    this.progressInterval = setInterval(() => {
      currentProgress += 2;
      this.showProgressBarWithProgress(currentProgress);

      // Clear interval when we reach local state or global
      if (currentProgress >= 100 || this.progress >= 100) {
        clearInterval(this.progressInterval);
        this.progressInterval = null;
        this.showIndeterminate();
      }
    }, intervalMs);
  }
}
