//mypreset.ts
import { definePreset } from '@primeng/themes';
import Aura from '@primeng/themes/aura';

const MyPreset = definePreset(Aura, {
  semantic: {
    primary: {
      50: '#fef8f7',
      100: '#fcdddb',
      200: '#f9c2be',
      300: '#f7a6a1',
      400: '#f48b85',
      500: '#f27068',
      600: '#ce5f58',
      700: '#a94e49',
      800: '#853e39',
      900: '#612d2a',
      950: '#3d1c1a',
    },
    green: {
      50: '#f6fcfa',
      100: '#d4efe6',
      200: '#b2e3d2',
      300: '#8fd6bf',
      400: '#6dcaab',
      500: '#4bbd97',
      600: '#40a180',
      700: '#35846a',
      800: '#296853',
      900: '#1e4c3c',
      950: '#132f26',
    },

    red: {
      50: '#fef6f6',
      100: '#f8d4d5',
      200: '#f3b2b4',
      300: '#ed8f93',
      400: '#e86d71',
      500: '#e24b50',
      600: '#c04044',
      700: '#9e3538',
      800: '#7c292c',
      900: '#5a1e20',
      950: '#391314',
    },
    sky: {
      50: '#f5f9fe',
      100: '#d1e1f9',
      200: '#accaf4',
      300: '#88b3ef',
      400: '#639bea',
      500: '#3f84e5',
      600: '#3670c3',
      700: '#2c5ca0',
      800: '#23497e',
      900: '#19355c',
      950: '#102139',
    },

    orange: {
      50: '#fffbf5',
      100: '#feebd1',
      200: '#fcdbac',
      300: '#fbcb87',
      400: '#fabb63',
      500: '#f9ab3e',
      600: '#d49135',
      700: '#ae782b',
      800: '#895e22',
      900: '#644419',
      950: '#3e2b10',
    },
    purple: {
      50: '#f8f9ff',
      100: '#dfe3ff',
      200: '#c6cdff',
      300: '#adb6ff',
      400: '#93a0ff',
      500: '#7a8aff',
      600: '#6875d9',
      700: '#5561b3',
      800: '#434c8c',
      900: '#313766',
      950: '#1f2340',
    },
  },
});

export default MyPreset;
