import { createSelector, createFeatureSelector } from '@ngrx/store';

import { VerbsState } from '.';
// aqui tiene que ir mis selectores si tengo.

const featureVerb = createFeatureSelector<Readonly<VerbsState>>('verbs');

export const getVerbs = createSelector(featureVerb, (state: VerbsState) => state.verbs);

export const getIsLoading = createSelector(featureVerb, (state: VerbsState) => state.isLoading);

export const getIsLoaded = createSelector(featureVerb, (state: VerbsState) => state.isLoaded);

export const getMetadata = createSelector(featureVerb, (state: VerbsState) => {
  return { isLoading: state.isLoading, isRehydrated: state.isRehydrated, isLoaded: state.isLoaded };
});
