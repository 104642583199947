import { HTTP_INTERCEPTORS, provideHttpClient, withInterceptorsFromDi, HttpClient } from '@angular/common/http';
import { enableProdMode, isDevMode, importProvidersFrom, provideZoneChangeDetection } from '@angular/core';
import { provideFirebaseApp, initializeApp, getApp } from '@angular/fire/app';
import { provideAuth, getAuth, initializeAuth, indexedDBLocalPersistence } from '@angular/fire/auth';
import { FIREBASE_OPTIONS } from '@angular/fire/compat';
import { provideStorage, getStorage } from '@angular/fire/storage';
import { BrowserModule, bootstrapApplication } from '@angular/platform-browser';
import { provideAnimations } from '@angular/platform-browser/animations';
import { provideAnimationsAsync } from '@angular/platform-browser/animations/async';
import { provideRouter, RouteReuseStrategy } from '@angular/router';
import { provideServiceWorker } from '@angular/service-worker';
import { Capacitor } from '@capacitor/core';

// Dataclouder libraries:
import { provideAuthConfig } from '@dataclouder/app-auth';
import { provideFlashcardService } from '@dataclouder/dc-flashcard';
import { provideChatAIService, provideUserDataExchange } from '@dataclouder/ngx-agent-cards';
import { provideToastAlert } from '@dataclouder/ngx-core';
import { provideLessonsService, provideNotionService } from '@dataclouder/ngx-lessons';
import { IonicRouteStrategy, provideIonicAngular } from '@ionic/angular/standalone';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { FormlyModule } from '@ngx-formly/core';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { LoggerModule } from 'ngx-logger';
import { provideLottieOptions } from 'ngx-lottie';
import { providePrimeNG } from 'primeng/config';
import { DialogService } from 'primeng/dynamicdialog';
import { register } from 'swiper/element/bundle';

import { routes } from './app/app-routing';
import { AppComponent } from './app/app.component';
import { AgentCardsService } from './app/core/data-services/agent-cards.service';
import { LessonsService } from './app/core/data-services/lessons.service';
import { FlashcardService } from './app/core/flashcard.service';
import { InterceptorTokenService } from './app/core/interceptor-token.service';
import { NotionService } from './app/core/notion.service';
import { PhrasesEffects } from './app/core/store/phrases/phrases.effects';
import { phrasesReducer } from './app/core/store/phrases/phrases.reducer';
import { topicsReducer } from './app/core/store/topics/topics.reducer';
import { UserEffects } from './app/core/store/user/user.effects';
import { userReducer } from './app/core/store/user/user.reducer';
import { VerbsEffects } from './app/core/store/verbs/verbs.effects';
import { verbsReducer } from './app/core/store/verbs/verbs.reducer';
import { WordsEffects } from './app/core/store/words/words.effects';
import { wordsReducer } from './app/core/store/words/words.reducer';
import { ToastAlertService } from './app/core/system/toast-alerts.service';
import { UserDataExchangeService } from './app/core/user-data-exchange.service';
import { FormlyFieldInput } from './app/formly-components/input';
import { FormlyFieldTextArea } from './app/formly-components/textarea';
import { environment } from './environments/environment';
import MyPreset from './mypreset';

register();

if (environment.production) {
  enableProdMode();
}
export function HttpLoaderFactory(http: HttpClient): TranslateHttpLoader {
  return new TranslateHttpLoader(http);
}

bootstrapApplication(AppComponent, {
  providers: [
    provideAnimationsAsync(),
    providePrimeNG({
      theme: {
        preset: MyPreset,
        options: {
          darkModeSelector: '.my-app-dark',
        },
      },
    }),
    provideZoneChangeDetection({ eventCoalescing: true }),
    provideRouter(routes),
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    provideIonicAngular(),
    importProvidersFrom(
      BrowserModule,
      LoggerModule.forRoot({ level: environment.logLevel }),
      TranslateModule.forRoot({
        loader: {
          provide: TranslateLoader,
          useFactory: HttpLoaderFactory,
          deps: [HttpClient],
        },
      }),
      // Others
      StoreModule.forRoot({
        words: wordsReducer,
        verbs: verbsReducer,
        user: userReducer,
        topics: topicsReducer,
        phrases: phrasesReducer,
      }),
      StoreDevtoolsModule.instrument({ maxAge: 25, logOnly: environment.production }),
      EffectsModule.forRoot([WordsEffects, VerbsEffects, UserEffects, PhrasesEffects]),
    ),
    DialogService,
    { provide: HTTP_INTERCEPTORS, useClass: InterceptorTokenService, multi: true },
    { provide: FIREBASE_OPTIONS, useValue: environment.firebase },
    // Firebase

    provideFirebaseApp(() => initializeApp(environment.firebase)),

    provideAuth(() => {
      if (Capacitor.isNativePlatform()) {
        return initializeAuth(getApp(), {
          persistence: indexedDBLocalPersistence,
        });
      } else {
        return getAuth();
      }
    }),

    provideStorage(() => getStorage()),
    provideHttpClient(withInterceptorsFromDi()),
    provideLottieOptions({
      player: () => import('lottie-web'), // check if i can move this later
    }),
    provideAnimations(),
    provideServiceWorker('ngsw-worker.js', {
      enabled: !isDevMode(),
      registrationStrategy: 'registerWhenStable:30000',
    }),

    // Dataclouder Providers
    provideChatAIService(AgentCardsService),
    provideToastAlert(ToastAlertService), // basically wraps this { provide: TOAST_ALERTS_TOKEN, useExisting: ToastAlertService },
    provideLessonsService(LessonsService),
    provideNotionService(NotionService),
    provideUserDataExchange(UserDataExchangeService),
    provideFlashcardService(FlashcardService),
    provideAuthConfig({
      clientIds: {
        androidClientId: environment.mobile.androidClientId,
        webClientId: environment.clientId,
        iosClientId: environment.mobile.iosClientId,
      },
      settings: {
        loginRedirectUri: null,
        signupRedirectUri: null,
        afterLoginRedirectUri: null,
        appleRedirectURI: environment.mobile.appleRedirectURI,
      },
    }),

    importProvidersFrom(
      FormlyModule.forRoot({
        types: [
          { name: 'input', component: FormlyFieldInput },
          { name: 'textarea', component: FormlyFieldTextArea },
        ],
        validationMessages: [{ name: 'required', message: 'This field is required' }],
      }),
    ),
  ],
}).catch((err) => console.error(err));
