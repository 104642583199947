import { DCFlashcard } from '@dataclouder/dc-flashcard';

import { AudioStorage, LearningExample } from './classes';
import { WordType } from './enums';

export const LangCodeDescription = {
  es: 'Spanish',
  en: 'English',
  it: 'Italian',
  pt: 'Portuguese',
  fr: 'French',
  ja: 'Japanese',
};

export const LangCodeDescriptionEs = {
  es: 'Español',
  en: 'Inglés',
  it: 'Italiano',
  pt: 'Portugués',
  fr: 'Frances',
  ja: 'Japonés',
};
export class AuditData {
  createdDate: string;
  modifiedDate: string;
}

export class UserExtraData extends AuditData {
  progress: number;
  completed: boolean;
}

export class GeneralWord extends AuditData {
  id: string;
  word: string;
  synonyms: Array<string>;
  definitions: Array<string>;
  examples: Array<string>;

  hispanicPhonetic: string;
  ipaPhonetic: string;
  respelledPhonetic: string;

  wordCategory: string; // before partOfSpeech
  lemma: string;
  baseVerb: string;
  level: number;

  useFrequency: number;
  audios: Array<AudioStorage>;

  learningExamples: Array<LearningExample>;

  translation?: string; // take the value from language target
  otherTranslations?: Array<string>; // take the value from language target

  flashcards?: Array<DCFlashcard>;

  // Availible translations for the word
  es: { translation: string; otherTranslations: Array<string> };
  en: { translation: string; otherTranslations: Array<string> };
}

export interface JapaneseExtra {
  kanji: string;
  hiragana: string;
  katakana: string;
  romaji: string;
  kanjiExample: string;
  hiraganaExample: string;
  katakanaExample: string;
  romajiExample: string;
  spanishTranslation: string;
  englishTranslation: string;
}

export const JapaneseExtraString = `
{ 
  extra: {
    kanji: string;
    hiragana: string;
    katakana: string;
    romaji: string;
    kanjiExample: string;
    hiraganaExample: string;
    katakanaExample: string;
    romajiExample: string;
    spanishTranslation: string;
    englishTranslation: string;
  }
}`;

// This is interface for AI models.
export const LangWordStringInterface: string = `
{
  word: string;              // word to translate
  synonyms: Array<string>;   // synonyms of the word
  definitions: Array<string>; // definitions of the word
  examples: Array<string>;   // examples of the word

  hispanicPhonetic: string; // hispanic phonetic of the word
  ipaPhonetic: string;      // ipa phonetic of the word
  respelledPhonetic: string; // respelled phonetic of the word

  wordCategory: string; // category of the word like verb, noun, adjective, adverb, etc.
  lemma: string;         // lemma of the word
  baseVerb: string;      // base verb of the word
  level: number;         // level of the word from 1 to 5, five is the most difficult
  useFrequency: number;     // use frequency of the word from 1 to 5, five is the most used

  // Availible translations for the word
  es: { translation: string; otherTranslations: Array<string> };
  en: { translation: string; otherTranslations: Array<string> };
}
`;

export class LangWord extends GeneralWord {
  wordType: WordType;

  extra: any;

  constructor(data: any = {}) {
    super();
    Object.assign(this, data);
  }
}

// For future use this type
export type UserWord = LangWord & UserExtraData;
