import { InjectionToken, Provider, Type } from '@angular/core';
import { ImgStorageData } from '@dataclouder/ngx-cloud-storage';

export interface FlashcardFront {
  type?: 'text' | 'image' | 'audio' | 'video' | 'link';
  img?: ImgStorageData;
  content?: string;
}

export interface FlashcardBack {
  type?: 'text' | 'image' | 'audio' | 'video' | 'link';
  img?: ImgStorageData;
  content?: string;
}

export interface DCFlashcard {
  id: string;
  relationId: string;
  name: string;
  front: FlashcardFront;
  back: FlashcardBack;
  tags: string[];
  tag: string;
}

export abstract class FlashcardAbstractService {
  abstract saveFlashcard(flashcard: DCFlashcard): Promise<any>;
}

export const FLASHCARD_SERVICE_TOKEN = new InjectionToken<FlashcardAbstractService>('Flashcard Service');

export function provideFlashcardService(serviceImplementation: Type<FlashcardAbstractService>): Provider[] {
  return [
    {
      provide: FLASHCARD_SERVICE_TOKEN,
      useClass: serviceImplementation,
    },
  ];
}
