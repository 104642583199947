import { Component } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { LessonComponentBuilders, LessonComponentConfiguration, LessonComponentEnum, LessonCompSettings } from './lessons.clases';
// import { ToastService } from 'src/app/core/system/toast.service';
import { DynamicDialogRef } from 'primeng/dynamicdialog';
// import { ToastService } from 'src/app/core/system/toast.service';

// Create a type that maps the interface to FormControls
export type LessonCompSettingsForm = {
  [K in keyof Required<LessonCompSettings>]: FormControl<LessonCompSettings[K]>;
};

@Component({
  selector: 'app-component-builder',
  template: '<div>no template</div>',
})
export class ComponentBuilder {
  constructor(
    protected formBuilder: FormBuilder,
    // protected toastrService: ToastService, // protected ref: NbDialogRef<ComponentBuilder>,
    protected ref: DynamicDialogRef,
  ) {}

  // Sobreescribir si es necesario
  // public formGroup = this.formBuilder.group<LessonCompSettings>({});

  public formGroup = this.formBuilder.group({
    response: new FormControl<string | null>(null),
    responses: new FormControl<string | null>(null),
    options: this.formBuilder.array([]),
    text: new FormControl<string | null>(null),
    hint: new FormControl<string | null>(null),
    explanation: new FormControl<string | null>(null),
  });

  // Every component knows its type by comparing agains LessonComponentBuilders
  get questionType(): LessonComponentEnum {
    const self = this;

    const name = Object.keys(LessonComponentBuilders).find((componentName: any) => {
      const componentClass = LessonComponentBuilders[componentName];
      if (self instanceof componentClass) {
        return componentName;
      }
    });

    return <LessonComponentEnum>name;
  }

  private getCode(): string {
    const data = this.formGroup.value;
    // Deleting null values
    Object.keys(data).forEach((key) => {
      if (data[key] === null) {
        delete data[key];
      }
    });
    const id = Math.random().toString(36).substring(7);
    const code: LessonComponentConfiguration = { id: id, component: this.questionType, settings: { ...data } as unknown as LessonCompSettings };
    return `~${JSON.stringify(code)}~`;
  }

  public showCode() {
    const code = this.getCode();
    alert(code);
  }

  public async copyToClipboard() {
    const code = this.getCode();
    await navigator.clipboard.writeText(code);
    // this.toastrService.info('Control + V para pegar', 'Se copió al portapapeles');
    // this.ref.close();
    this.ref.close(code);
  }
}
