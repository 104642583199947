import { createReducer, on } from '@ngrx/store';

import { WordActions, WordsState } from '.';
import { LangWord } from '../../lexicore.classes';
import { getStorageRehydration, StorageStateEnum, hydrateStorage } from '../app.state';

// this is the very first state of the app, if existed in the storage, it will be rehydrated else will get this call at some point in the app
const wordsStorage = getStorageRehydration<LangWord[]>(StorageStateEnum.Words);

export const wordInitialState: WordsState = {
  words: wordsStorage,
  error: null,
  isLoading: true,
  isLoaded: false,
  isRehydrated: !!wordsStorage,
};

export const wordsReducer = createReducer(
  wordInitialState,

  on(WordActions.setInitialState, () => wordInitialState),
  // difference is set use the regular app flow, reset is to force the reset
  on(WordActions.resetInitialState, () => ({ words: [], error: '', isLoading: false, isLoaded: false, isRehydrated: false })),

  on(WordActions.setIsLoading, (state, action) => ({ ...state, isLoading: action.isloading })),

  on(WordActions.loadWordsSuccess, (state, action): WordsState => {
    hydrateStorage(StorageStateEnum.Words, action.words);
    return { ...state, words: [...action.words], isLoading: false, isLoaded: true };
  }),

  on(WordActions.createWordSuccess, (state, action): WordsState => {
    const words = [...state.words, action.word];
    hydrateStorage(StorageStateEnum.Words, words);
    return { ...state, words: words };
  }),

  on(WordActions.addWords, (state, action): WordsState => {
    const words = [...state.words, ...action.word];
    hydrateStorage(StorageStateEnum.Words, words);
    return { ...state, words: words };
  }),

  on(WordActions.updateWordSuccess, (state, action): WordsState => {
    const updatedWords = state.words.map((item) => (action.word.id === item.id ? action.word : item));
    hydrateStorage(StorageStateEnum.Words, updatedWords);
    return { ...state, words: updatedWords, error: '' };
  }),

  on(WordActions.deleteWordSuccess, (state, action): WordsState => {
    const filterWords = state.words.filter((word) => word.word !== action.word);
    hydrateStorage(StorageStateEnum.Words, filterWords);

    return { ...state, words: filterWords, error: '' };
  }),

  on(WordActions.updateProgressWord, (state, action): WordsState => {
    const wordsMapped = state.words.map((word) => {
      for (const progress of action.progress) {
        if (progress.word === word.word) {
          return { ...word, progress: progress.progress };
        }
      }
      return word;
    });
    hydrateStorage(StorageStateEnum.Words, wordsMapped);
    return { ...state, words: wordsMapped, error: '' };
  }),

  on(WordActions.updateWordProperty, (state, action): WordsState => {
    const wordsMapped = state.words.map((word) => {
      if (action.word === word.word) {
        if (Array.isArray(action.value)) {
          const previousValues = word[action.property] ?? [];
          return { ...word, [action.property]: [...previousValues, ...action.value] };
        }
        return { ...word, [action.property]: action.value };
      }
      return word;
    });

    hydrateStorage(StorageStateEnum.Words, wordsMapped);
    return { ...state, words: wordsMapped, error: '' };
  }),
);
