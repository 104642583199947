<div>
  <div>
    <h5>Constructor de formulario con texto</h5>
  </div>

  <div>
    <form class="builder-form" [formGroup]="formGroup">
      <input pInputText type="text" nbInput fullWidth formControlName="response" placeholder="Respuesta" />

      <input pInputText class="form-input" type="" nbInput fullWidth formControlName="hint"
        placeholder="Escribe una pista para esta pregunta" />

      <input pInputText class="form-input" type="text" nbInput fullWidth formControlName="explanation"
        placeholder="Excribe una explicación para la respuesta" />
    </form>
  </div>

  <div>
    <p-button (click)="copyToClipboard()" [disabled]="formGroup.invalid" label="Copia Código"
      [rounded]="true"></p-button>
    <p-button (click)="showCode()" [disabled]="formGroup.invalid" label="Mostrar" [rounded]="true"
      severity="secondary"></p-button>
  </div>
</div>