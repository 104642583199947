import { Component, Input, OnInit, Inject, ChangeDetectorRef } from '@angular/core';

import { FormBuilder, ReactiveFormsModule } from '@angular/forms';
import { AspectType, CropperComponentModal, ImgStorageData, ResolutionType, StorageImageSettings } from '@dataclouder/ngx-cloud-storage';
import { DCFlashcard, FlashcardAbstractService, FLASHCARD_SERVICE_TOKEN } from '../models/models';

import { CardModule } from 'primeng/card';
import { TextareaModule } from 'primeng/textarea';
import { DropdownModule } from 'primeng/dropdown';
import { ButtonModule } from 'primeng/button';
import { SelectModule } from 'primeng/select';
import { InputTextModule } from 'primeng/inputtext';

import { TOAST_ALERTS_TOKEN, ToastAlertsAbstractService } from '@dataclouder/ngx-core';
import { GeneralWord } from 'src/app/core/lexicore.classes';

const DEFAULT_IMAGE = 'assets/images/default_conversation_card.webp';

const ContentTypesOptions = [
  { label: 'Text', value: 'text' },
  { label: 'Image', value: 'image' },
  { label: 'Audio', value: 'audio' },
  { label: 'Video', value: 'video' },
  { label: 'Link', value: 'link' },
];
@Component({
  selector: 'dc-flashcard-form',
  templateUrl: './dc-flashcard-form.component.html',
  styleUrls: ['./dc-flashcard-form.component.scss'],
  standalone: true,
  imports: [ReactiveFormsModule, CardModule, TextareaModule, DropdownModule, ButtonModule, SelectModule, CropperComponentModal, InputTextModule],
})
export class DcFlashcardComponent implements OnInit {
  @Input() flashcard: DCFlashcard;
  @Input() word: GeneralWord; // No queria acomplar este modulo con la palabra pero por ahora y por tiempo lo haré.

  public frontUrl = DEFAULT_IMAGE;
  public backUrl = DEFAULT_IMAGE;

  public contentTypes = ContentTypesOptions;

  public storageImgSettings: StorageImageSettings;

  public flashcardForm = this.fb.group({
    name: [''],
    front: this.fb.group({ type: ['text'], img: [null], content: [''] }),
    back: this.fb.group({ type: ['text'], img: [null], content: [''] }),
    tag: [''],
  });

  constructor(
    private fb: FormBuilder,
    @Inject(TOAST_ALERTS_TOKEN) private toastService: ToastAlertsAbstractService,
    private cdr: ChangeDetectorRef,
    @Inject(FLASHCARD_SERVICE_TOKEN) private flashcardService: FlashcardAbstractService,
  ) {}

  ngOnInit() {
    this.initForm();

    this.storageImgSettings = {
      path: `flashcards/${this.word.word}`,
      cropSettings: {
        resizeToWidth: 850,
        aspectRatio: AspectType.Vertical_2_3,
        resolutions: [ResolutionType.Medium],
      },
    };

    this.cdr.detectChanges();
  }

  private initForm() {
    if (this.word.flashcards && this.word.flashcards.length > 0) {
      this.flashcard = this.word.flashcards[0];
      this.frontUrl = this.flashcard?.front?.img?.url || DEFAULT_IMAGE;
      this.backUrl = this.flashcard?.back?.img?.url || DEFAULT_IMAGE;
      this.flashcardForm.patchValue(this.flashcard);
      this.cdr.detectChanges();
    }
  }

  public async save() {
    if (this.flashcardForm.valid) {
      const formValue = this.flashcardForm.value;
      // Emit save event or handle save logic
      const flashcard = { ...formValue, relationId: this.word.word } as DCFlashcard;
      await this.flashcardService.saveFlashcard(flashcard);
      this.toastService.success({ title: 'Flashcard saved', subtitle: 'Flashcard saved successfully' });
    } else {
      this.toastService.error({ title: 'Form is not valid', subtitle: 'Please fill all the fields' });
    }
  }

  public async updateUploadedImage(data: ImgStorageData, side: 'front' | 'back'): Promise<void> {
    this.toastService.success({ title: 'Imagen Cargada', subtitle: 'Image uploaded successfully' });
    if (side === 'front') {
      this.flashcardForm.controls.front.controls.img.setValue(data);
      console.log(this.flashcardForm.controls.front.controls.img.value);
      this.frontUrl = data.url;
    } else {
      this.flashcardForm.controls.back.controls.img.setValue(data);
      this.backUrl = data.url;
    }
    await this.save();

    this.cdr.detectChanges();
  }
}
