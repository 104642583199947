import { ChangeDetectionStrategy, Component, OnInit, input, inject } from '@angular/core';
import { FormBuilder, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { InputTextModule } from 'primeng/inputtext';

import { ArrayFormHandlerComponent } from '../array-form-handler/array-form-handler.component';

@Component({
  selector: 'app-lang-translations-form',
  imports: [ArrayFormHandlerComponent, InputTextModule, ReactiveFormsModule],
  template: `
    <div class="field">
      <label class="label">Traducción (Base)</label>
      <input pInputText [formControl]="formGroup().controls.translation" type="text" placeholder="Traducción" />
    </div>
    <app-array-form-handler [arrayForm]="formGroup().controls.otherTranslations" entity="otras traducciones" />
  `,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LanguageTranlationFormComponent implements OnInit {
  private formBuilder = inject(FormBuilder);

  public readonly formGroup = input(this.formBuilder.group({
    translation: [''],
    otherTranslations: this.formBuilder.array<FormGroup<string>>([]),
}));

  /** Inserted by Angular inject() migration for backwards compatibility */
  constructor(...args: unknown[]);
  constructor() {}
  ngOnInit(): void {
    console.log('formGroup', this.formGroup().value);
  }
}
