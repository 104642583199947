import { InjectionToken, Provider, Type } from '@angular/core';

export const TOAST_ALERTS_TOKEN = new InjectionToken<ToastAlertsAbstractService>('Toast alerts Service');

export interface ToastData {
  title: string;
  subtitle: string;
}

export abstract class ToastAlertsAbstractService {
  abstract success(data: ToastData): void;
  abstract info(data: ToastData): void;
  abstract warn(data: ToastData): void;
  abstract error(data: ToastData): void;
}

export function provideToastAlert(serviceImplementation: Type<ToastAlertsAbstractService>): Provider[] {
  return [
    {
      provide: TOAST_ALERTS_TOKEN,
      useExisting: serviceImplementation,
    },
  ];
}

export enum AudioSpeed {
  VerySlow = 'verySlow',
  Slow = 'slow',
  Regular = 'regular',
  Fast = 'fast',
  VeryFast = 'veryFast',
}
