import { inject } from '@angular/core';
import { Router, UrlTree, CanActivateFn } from '@angular/router';
import { Observable, of } from 'rxjs';
import { concatMap } from 'rxjs/operators';
import { FirebaseAuthService } from './firebase-auth.service';

export function redirectToIfAuth(route?: string): CanActivateFn {
  return (): Observable<boolean | UrlTree> => {
    const router = inject(Router);
    const fbAuthService = inject(FirebaseAuthService);

    const isAuth$ = fbAuthService.authState$.pipe(
      concatMap((isAuth) => {
        return !isAuth ? of(true) : of(router.parseUrl(route));
      }),
    );

    return isAuth$;
  };
}
