
import { Component, ViewChild, OnInit, AfterViewInit, input, inject } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, UntypedFormArray, FormControl, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NGXLogger } from 'ngx-logger';
import { WordFormMode } from 'src/app/core/enums';
import { ToastService } from 'src/app/core/system/toast.service';

import { EnglishVerb } from '../../../core/classes';
import { TopicService } from '../../../core/data-services/topic.service';
import { VerbService } from '../../../core/data-services/verb.service';

@Component({
  selector: 'app-verb-form',
  templateUrl: './verb-form.component.html',
  styleUrls: ['./verb-form.component.scss'],
  standalone: true,
  imports: [FormsModule, ReactiveFormsModule],
})
export class VerbFormComponent implements OnInit, AfterViewInit {
  private toastrService = inject(ToastService);
  private topicService = inject(TopicService);
  private verbService = inject(VerbService);
  private logger = inject(NGXLogger);
  protected formBuilder = inject(UntypedFormBuilder);

  readonly englishVerb = input<EnglishVerb>(new EnglishVerb());
  readonly topicId = input<string>(null); // Cuando el formulario lo activa un topic id inicia desde aquí
  readonly mode = input<WordFormMode>(undefined);

  buttonText: string = 'Guardar Verbo';
  @ViewChild('definitionsForm') definitionsForm: any;

  public formGroup: UntypedFormGroup;
  public isSaving: boolean;

  /** Inserted by Angular inject() migration for backwards compatibility */
  constructor(...args: unknown[]);

  constructor() {
    this.formGroup = this.formBuilder.group({
      word: [],
      past: [],
      participle: [],
      translation: [],
      useFrequency: [],
      isIrregular: [],
      isPhrasal: [],
      spanishExplanation: [],
      examples: this.formBuilder.array([]),
      definitions: this.formBuilder.array([]),
      synonyms: this.formBuilder.array([]),
      otherTranslations: this.formBuilder.array([]),
    });
  }

  ngOnInit(): void {
    const mode = this.mode();
    if (!mode || mode == WordFormMode.Regular) {
      this.formGroup.addControl('score', new FormControl(1));
    } else if (mode == WordFormMode.Suggestion) {
      this.buttonText = 'Publicar sugerencia';
    }

    const englishVerb = this.englishVerb();
    if (englishVerb) {
      this.logger.debug('Se recibió un verbo actualizando');
      this.setFormData(englishVerb);
    }
  }

  ngAfterViewInit() {
    // TODO: aqui busco una forma de mostrar el botón de eliminar en las listas solo cuando pase el mouse, va a ser una carestistica
    // Muy util para reutilizar en cualquier proyecto.
    // const htmlElement: HTMLElement = this.verbInput.nativeElement;
    // console.log("el elemento del input es", this.verbInput.nativeElement);
    // fromEvent(htmlElement, 'mouseenter').subscribe(res => {
    //   this.overVerb = true;
    //   console.log("Elemento mouseover");
    // })
    // fromEvent(htmlElement, 'mouseleave').subscribe(res => {
    //   console.log("Elemento mouseleave");
    //   //console.log(htmlElement.target);
    // });
    // console.log("Buscando definiciones ", this.definitionsForm);
  }

  // Getter del arreglo del formulario
  get exampleFormArray(): UntypedFormArray {
    return this.formGroup.get('examples') as UntypedFormArray;
  }
  get definitionFormArray(): UntypedFormArray {
    return this.formGroup.get('definitions') as UntypedFormArray;
  }
  get synonymFormArray(): UntypedFormArray {
    return this.formGroup.get('synonyms') as UntypedFormArray;
  }
  get otherTranslationFormArray(): UntypedFormArray {
    return this.formGroup.get('otherTranslations') as UntypedFormArray;
  }

  public async save(): Promise<void> {
    const formVerb: EnglishVerb = new EnglishVerb(this.formGroup.getRawValue());

    // set properties if exist
    formVerb.id = this.englishVerb().id || null;
    formVerb.verb = formVerb.word ? 'to ' + formVerb.word : null;

    let response: EnglishVerb = null;

    try {
      this.isSaving = true;
      const topicId = this.topicId();
      if (this.mode() === WordFormMode.Suggestion) {
        this.verbService.promoteVerb(formVerb);
      } else if (topicId) {
        response = await this.topicService.saveCustomTopicVerb(formVerb, topicId);
      } else {
        response = await this.verbService.updateVerb(formVerb);
      }
      if (response) {
        this.toastrService.success(`Se guardó el verbo ${response.word}`, 'Excelente');
      }
    } catch (err) {
      this.toastrService.error('Ocurrió un error al guardar el verbo', 'Ups');
    } finally {
      this.isSaving = false;
      this.dismiss(response);
    }
  }

  public promoteVerb(): Promise<void> {
    const isConfirmed = confirm(
      'Se tomarán a consideración la información del verbo para actualizar la base de datos de Polilan, ¿Estas seguro de que la información es correcta? ',
    );
    if (!isConfirmed) return null;

    this.verbService.promoveVerb$(this.englishVerb().id).subscribe(
      () => {},
      (err) => {},
      () => {
        this.toastrService.success('El verbo se agregará pronto a la base de datos', 'Muchas Gracias');
      },
    );

    return null;
  }

  public pushControlToFormArray(controlName: string): void {
    (this.formGroup.get(controlName) as UntypedFormArray).push(this.formBuilder.control(''));
  }

  public deleteFormArrayByIndex(controlName: string, index: number): void {
    console.log('Eliminado del control', controlName, index);
    (this.formGroup.get(controlName) as UntypedFormArray).removeAt(index);
  }

  private setFormData(verbdata): void {
    const data = { ...verbdata };
    if (data.examples) {
      for (const i of data.examples) {
        this.pushControlToFormArray('examples');
      }
    }
    if (data.synonyms) {
      for (const i of data.synonyms) {
        this.pushControlToFormArray('synonyms');
      }
    }
    if (data.definitions) {
      for (const i of data.definitions) {
        this.pushControlToFormArray('definitions');
      }
    }
    if (data.otherTranslations) {
      for (const _ of data.otherTranslations) {
        this.pushControlToFormArray('otherTranslations');
      }
    }
    this.formGroup.patchValue(data);
  }

  protected dismiss(verb = null): void {
    // this.ref.close(verb);
  }
}
