import { Component, Input, OnInit, ViewChild, ChangeDetectorRef, inject, output, input } from '@angular/core';

import { FormsModule } from '@angular/forms';

import { DialogModule } from 'primeng/dialog';

import { ImageCroppedEvent, ImageCropperComponent, LoadedImage } from 'ngx-image-cropper';
import { Observable } from 'rxjs';

import { MultiImagesStorageService } from '../../services/multi-images-storage.service';
import {
  AspectRatio,
  AspectType,
  ImageMultipleCrops,
  StorageImageSettings,
  ImgStorageData,
  AspectRatioOptions,
  AspectRatioOption,
  DEFAULT_SETTINGS,
} from '../../classes/cropper.classes';
import { TooltipModule } from 'primeng/tooltip';

import { ButtonModule } from 'primeng/button';
import { MessageModule } from 'primeng/message';
import { SelectModule } from 'primeng/select';
import { InputTextModule } from 'primeng/inputtext';

@Component({
  selector: 'dc-cropper-modal',
  templateUrl: './cropper-modal.component.html',
  styleUrls: ['./cropper-modal.component.scss'],
  standalone: true,
  imports: [FormsModule, ImageCropperComponent, ButtonModule, DialogModule, TooltipModule, MessageModule, SelectModule, InputTextModule],
})
export class CropperComponentModal implements OnInit {
  private multiImagesStorageService = inject(MultiImagesStorageService);
  private changeDetectorRef = inject(ChangeDetectorRef);

  // overrides name, path and resizeToWidth

  readonly imgStorageSettings = input<StorageImageSettings>(DEFAULT_SETTINGS);
  readonly buttonLabel = input<string>('Seleccionar archivo');
  @Input() currentStorage: ImgStorageData = {} as any;

  readonly imageUploaded = output<ImgStorageData>();
  readonly onImageCropped = output<ImageMultipleCrops>();
  readonly onFileSelected = output<any>();

  @ViewChild(ImageCropperComponent) imageCropper!: ImageCropperComponent;

  public aspectRatioOptions = AspectRatioOptions;
  public fileMetadata: File | null = null;
  public imageChangedEvent!: Event;
  public displayDialog = false;
  public aspectRatioValue: number = 1;
  public croppedImage: any = '';
  public renameFile: any = '';
  public storagePath: string = '';
  public downloadURL!: Observable<string>;
  public resizeToWidth: number = 450;
  public ratioSelected: any = null;

  // Add a unique identifier for the file input
  public fileInputId: string;

  /** Inserted by Angular inject() migration for backwards compatibility */
  constructor(...args: unknown[]);

  constructor() {
    // Generate random ID for file input
    this.fileInputId = `file-upload-${Math.random().toString(36).substring(2, 11)}`;
  }

  ngOnInit(): void {
    if (!this.imgStorageSettings().path) {
      console.warn('⚠️ Remember to set imgStorageSettings, path and fileName are required , path example: /collection/id/subcollection ');
    }
    this.reloadPath();
  }

  public reloadPath(): void {
    const randomCharacters = Math.random().toString(36).substring(2, 15);
    this.storagePath = `${this.imgStorageSettings().path}/${this.imgStorageSettings().fileName}-${randomCharacters}.webp`;
  }

  private setSettingsForComponent(): void {
    const imgStorageSettings = this.imgStorageSettings();
    console.log('setSettingsForComponent', imgStorageSettings);
    // TODO: remove all the imageSettings and keep only imgStorageSettings

    this.aspectRatioValue = imgStorageSettings?.cropSettings?.aspectRatio
      ? AspectRatio[imgStorageSettings?.cropSettings?.aspectRatio]
      : AspectRatio[AspectType.Square];

    if (imgStorageSettings?.path) {
      this.storagePath = `${imgStorageSettings.path}/${imgStorageSettings.fileName}.webp`;
    } else if (imgStorageSettings.path) {
      this.storagePath = `${imgStorageSettings.path}/${imgStorageSettings.fileName}.webp`;
    }
    if (imgStorageSettings?.cropSettings?.resizeToWidth) {
      this.resizeToWidth = imgStorageSettings.cropSettings.resizeToWidth;
    } else if (imgStorageSettings.cropSettings.resizeToWidth) {
      this.resizeToWidth = imgStorageSettings.cropSettings.resizeToWidth;
    }
    if (imgStorageSettings?.fileName) {
      this.renameFile = imgStorageSettings.fileName;
    } else if (imgStorageSettings.fileName) {
      this.renameFile = imgStorageSettings.fileName;
    }
  }

  async fileChangeEvent(event: any) {
    this.setSettingsForComponent();

    console.log(this.fileInputId);

    this.imageChangedEvent = event;
    const file = event?.target?.files[0];
    if (file) {
      this.fileMetadata = file;
      this.onFileSelected.emit(file);
      this.renameFile = this.fileMetadata?.name
        ?.split('.')[0]
        .replace(/[^a-zA-Z0-9]/g, '')
        .slice(0, 80);

      console.log(this.renameFile);

      if (!this.imgStorageSettings().fileName) {
        this.reloadPath();
      }
      this.displayDialog = true;
      this.changeDetectorRef.detectChanges();
    }
  }

  onInnerImageCropped(event: ImageCroppedEvent) {
    this.croppedImage = event.base64;
  }

  imageLoaded(image: LoadedImage) {
    this.changeDetectorRef.detectChanges();
  }

  cropperReady() {
    this.changeDetectorRef.detectChanges();
  }

  loadImageFailed() {
    console.error('fallo al cargar la imagen');
  }

  public async simpleCropAndUpload() {
    console.log(this.fileInputId);

    console.log('simpleCropAndUpload');
    const imageCropped: any = await this.imageCropper.crop();
    const imgStorage = await this.multiImagesStorageService.uploadImage(imageCropped?.blob, this.storagePath);
    if (this.currentStorage?.path) {
      console.warn('deleting current Image', this.currentStorage?.path);
      this.multiImagesStorageService.deleteImage(this.currentStorage.path);
    }
    console.log('imgStorage', imgStorage);
    this.imageUploaded.emit(imgStorage);
    this.displayDialog = false;
    this.changeDetectorRef.detectChanges();
  }

  public changeRatio(event: AspectRatioOption) {
    console.log('changeRatio', event);
    // this.imgStorageSettings.cropSettings.aspectRatio = event.valueRatio;
    this.aspectRatioValue = event.valueRatio;
    this.changeDetectorRef.detectChanges();
  }
}
