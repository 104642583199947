import { InjectionToken, Provider, Type } from '@angular/core';

export interface AuthConfig {
  clientIds: {
    androidClientId: string;
    iosClientId: string;
    webClientId: string;
  };
  settings: {
    loginRedirectUri: string;
    signupRedirectUri: string;
    afterLoginRedirectUri: string;
    appleRedirectURI: string;
  };
}

export const AUTH_CONFIG = new InjectionToken<AuthConfig>('auth.config');

export function provideAuthConfig(config: AuthConfig): Provider[] {
  return [
    {
      provide: AUTH_CONFIG,
      useValue: config,
    },
  ];
}

export abstract class AuthConfigAbstractService {
  abstract getAuthConfig(): AuthConfig;
}
