
import { Component, OnInit, ViewChild, input, inject, output } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { ImageCroppedEvent, ImageCropperComponent, LoadedImage } from 'ngx-image-cropper';
import { Observable } from 'rxjs';

import { AspectRatio, AspectType, ImageMultipleCrops, ImageSettings, ResolutionType } from './cropper.classes';
import { MultiImagesStorageService } from '../multi-images-storage.service';

@Component({
  selector: 'app-cropper',
  templateUrl: './cropper.component.html',
  styleUrls: ['./cropper.component.scss'],
  standalone: true,
  imports: [FormsModule, ImageCropperComponent],
})
export class CropperComponent implements OnInit {
  private multiImagesStorageService = inject(MultiImagesStorageService);

  // overrides name, path and resizeToWidth
  readonly imageSettings = input<ImageSettings>({} as any);

  readonly ratioType = input<AspectType | string>(AspectType.Square);

  readonly resolutions = input<Array<number>>([ResolutionType.MediumLarge]);

  readonly imageUploaded = output<any>();

  readonly onImageCropped = output<ImageMultipleCrops>();

  readonly onFileSelected = output<any>();

  @ViewChild(ImageCropperComponent) imageCropper!: ImageCropperComponent;

  public fileMetadata: File | null = null;
  public imageChangedEvent!: Event;

  public aspectRatio: number = 1;

  public croppedImage: any = '';

  public isLoading = false;
  public isUploaded = false;
  public renameFile: any = '';
  public storagePath: string = '';

  /** Inserted by Angular inject() migration for backwards compatibility */
  constructor(...args: unknown[]);

  constructor() {}

  ngOnInit(): void {
    this.aspectRatio = AspectRatio[this.ratioType()];
    const imageSettings = this.imageSettings();
    if (imageSettings.path) {
      this.storagePath = `${imageSettings.path}/${imageSettings.fileName}.webp`;
    }
  }

  public reloadPath(): void {
    this.storagePath = `${this.imageSettings().path}/${this.renameFile}.webp`;
  }

  async fileChangeEvent(event: Event) {
    this.imageChangedEvent = event;
    const filesEvent = <FileList>(<HTMLInputElement>event.target).files;
    this.fileMetadata = filesEvent[0];
    this.onFileSelected.emit(this.fileMetadata);

    this.renameFile = this.fileMetadata.name.split('.')[0];
    if (!this.imageSettings().fileName) {
      this.reloadPath();
    }
  }

  onInnerImageCropped(event: ImageCroppedEvent) {
    this.croppedImage = event.base64;
  }

  imageLoaded(image: LoadedImage) {
    // show cropper
  }

  loadImageFailed() {
    console.error('fallo al cargar la imagen');
  }

  public downloadURL!: Observable<string>;

  public async simpleCropAndUpload() {
    const imageCropped: any = await this.imageCropper.crop();
    const imgStorage = await this.multiImagesStorageService.uploadImage(imageCropped?.blob, this.storagePath);
    this.imageUploaded.emit(imgStorage);
  }

  public async uploadToStorage(imageMulticrops: ImageMultipleCrops): Promise<void> {
    // TODO: Nota si algo falla aquí puede causar inconsistencias en el sistema, ver como manejar errores
    const path = this.imageSettings().path;
    // const imageUploaded = await this.multiImagesStorageService.uploadImage(imageMulticrops, path);
    // this.modalRef.close();
    // console.log(imageUploaded);
    // const image = { type: 'cover', ...imageUploaded };

    // TODO: creo que esta parte va en el componente que llama a este componente
    // if (!this.lesson.media) {
    //   this.lesson.media = {};
    //   this.lesson.media.images = [image];
    // } else {
    //   // solo sustituir el cover si ya existe
    //   const currentCover = this.lesson.media.images.find((img) => img.type === 'cover');
    //   this.multiImagesStorageService.delete_directory(currentCover.path);

    //   this.lesson.media.images = this.lesson.media.images.filter((img) => img.type !== 'cover');

    //   this.lesson.media.images.push(image);
    // }

    // await this.saveLesson();
    // this.updateCover();
    // this.imageUploaded.emit(image);
  }
}
