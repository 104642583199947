import { UntypedFormControl } from '@angular/forms';
import { SelectorBuilderComponent } from './selector/selector-builder/selector-builder.component';
import { SelectorComponent } from './selector/selector.component';
// import { SpeakerBuilderComponent } from './speaker/speaker-builder/speaker-builder.component';
// import { SpeakerComponent } from './speaker/speaker.component';
import { TextWriterBuiderComponent } from './text-writer/text-writer-buider/text-writer-buider.component';
import { TextWriterComponent } from './text-writer/text-writer.component';
// import { VerbSummaryBuilderComponent } from './verb-summary/verb-summary-builder/verb-summary-builder.component';
// import { VerbSummaryComponent } from './verb-summary/verb-summary.component';
// import { WordSummaryBuilderComponent } from './word-summary/word-summary-builder/word-summary-builder.component';
import { TranslationSwitcherBuilderComponent } from './translationSwitcher/translationSwitcherBuilder/translationSwitcherBuilder.component';
import { TranslationSwitcherComponent } from './translationSwitcher/translationSwitcher.component';

import { InjectionToken, Type } from '@angular/core';
import { Provider } from '@angular/core';

// import { WordSummaryComponent } from './word-summary/word-summary.component';
// import { AudioStorage } from 'src/app/core/classes';

export type LessonComponentsType = 'selector' | 'speaker' | 'text-writer' | 'verb-summary' | 'word-summary';

export enum LessonComponentEnum {
  Selector = 'selector',
  Speaker = 'speaker',
  TextWriter = 'textWriter',
  VerbSummary = 'verbSummary',
  WordSummary = 'wordSummary',
  TranslationSwitcher = 'translationSwitcher',
}

export const LessonComponentBuilders = {
  [LessonComponentEnum.Selector]: SelectorBuilderComponent,
  // [LessonComponentEnum.Speaker]: SpeakerBuilderComponent,
  [LessonComponentEnum.TextWriter]: TextWriterBuiderComponent,
  // [LessonComponentEnum.VerbSummary]: VerbSummaryBuilderComponent,
  // [LessonComponentEnum.WordSummary]: WordSummaryBuilderComponent,
  [LessonComponentEnum.TranslationSwitcher]: TranslationSwitcherBuilderComponent,
};

export const LessonComponents = {
  [LessonComponentEnum.Selector]: SelectorComponent,
  // [LessonComponentEnum.Speaker]: SpeakerComponent,
  [LessonComponentEnum.TextWriter]: TextWriterComponent,
  // [LessonComponentEnum.VerbSummary]: VerbSummaryComponent,
  // [LessonComponentEnum.WordSummary]: WordSummaryComponent,
  [LessonComponentEnum.TranslationSwitcher]: TranslationSwitcherComponent,
};

export function getLessonComponentClass(type: LessonComponentsType) {
  return LessonComponents[type];
}

export interface LessonCompSettings {
  response?: string;
  responses?: string; // en caso de que haya multiples respuestas
  options?: string[]; // optiones para visualizar
  text?: string;
  hint?: string;
  explanation?: string;
}
export interface LessonComponentConfiguration {
  id: string;
  component: LessonComponentEnum;
  settings: LessonCompSettings;
}

export interface StorageFile {
  bucket: string;
  url: string;
  path: string; // path where the file is in the storage
}

export interface AudioStorage extends StorageFile {
  text?: string;
  voiceType?: string;
}
export interface SpeakerCompConfiguration extends LessonComponentConfiguration {
  audio: AudioStorage;
  settings: LessonCompSettings & { voice: string };
}

export interface LessonComponentInterface {
  config: LessonComponentConfiguration;

  control?: UntypedFormControl;

  validate?(): boolean | null;
  evaluate?(): boolean | null;
}
// nuevs

// import { UntypedFormControl } from '@angular/forms';
// import { LessonComponents } from 'src/app/pages/lessons/components/lessons.clases';

// import { LessonComponents } from '../models/lessons.class';

export const LESSONS_TOKEN = new InjectionToken<LessonsAbstractService>('Lessons Service');

// export enum LessonComponentEnum {
//   Selector = 'selector',
//   Speaker = 'speaker',
//   TextWriter = 'textWriter',
//   VerbSummary = 'verbSummary',
//   WordSummary = 'wordSummary',
//   TranslationSwitcher = 'translationSwitcher',
// }

export interface StorageFile {
  bucket: string;
  url: string;
  path: string; // path where the file is in the storage
}

export interface ImgStorageData extends StorageFile {
  fullPath: string; // path + name
  resolutions: any;
  resolution: string;
}

export type LessonImage = ImgStorageData & { type: string };

// export interface LessonCompSettings {
//   response: string;
//   responses: string; // en caso de que haya multiples respuestas
//   options: string[]; // optiones para visualizar
//   text: string;
//   hint: string;
//   explanation: string;
// }

export interface LessonComponentConfiguration {
  id: string;
  component: LessonComponentEnum;
  settings: LessonCompSettings;
}

export class Lesson {
  id: string;
  title: string;
  description: string;
  textCoded: string;
  authorId: string;
  authorEmail: string;
  tags: Array<string>;
  isPublished: boolean;
  isPrivate: boolean;
  comments: Array<Comment>;
  media: { audios?: Array<LessonImage>; images?: Array<LessonImage>; videos?: Array<LessonImage> };
  components: Array<LessonComponentConfiguration>;
  generatedByAI: boolean;
  prompt: string;
  level: number;

  constructor(data: any = {}) {
    this.isPublished = false;
    this.isPrivate = false;
    Object.assign(this, data);
  }
}

export class LessonWithTaken extends Lesson {
  // FIXME: moved to lessons lib

  taken: any;
}

// Think on someway of getting the toast alerts service

export abstract class LessonsAbstractService {
  abstract getLessons(paginator?: any): Promise<any>;
  abstract getLesson(id: string): Promise<any>;
  abstract postLesson(lesson: Lesson): Promise<any>;
  abstract updateLesson(lesson: Lesson): Promise<any>;
  abstract deleteLesson(id: string): Promise<any>;
  abstract generateLesson(lesson: Lesson): Promise<any>;
  abstract postGenerateByAI(id: string): Promise<any>;
}

// my-service.provider.ts
export function provideLessonsService(serviceImplementation: Type<LessonsAbstractService>): Provider[] {
  return [
    {
      provide: LESSONS_TOKEN,
      useExisting: serviceImplementation,
    },
  ];
}

// export type LessonComponentsType = 'selector' | 'speaker' | 'text-writer' | 'verb-summary' | 'word-summary';

// export function getLessonComponentClass(type: LessonComponentsType) {
//   // return LessonComponents[type];
//   return null;
// }

export interface LessonComponentConfiguration {
  id: string;
  component: LessonComponentEnum;
  settings: LessonCompSettings;
}

export interface StorageFile {
  bucket: string;
  url: string;
  path: string; // path where the file is in the storage
}

export interface AudioStorage extends StorageFile {
  text?: string;
  voiceType?: string;
}

export interface SpeakerCompConfiguration extends LessonComponentConfiguration {
  audio: AudioStorage;
  settings: LessonCompSettings & { voice: string };
}

export interface LessonComponentInterface {
  config: LessonComponentConfiguration;

  control?: UntypedFormControl;

  validate?(): boolean | null;
  evaluate?(): boolean | null;
}

// export const LessonComponents = {
//   [LessonComponentEnum.Selector]: 1,
//   [LessonComponentEnum.Speaker]: 2,
//   [LessonComponentEnum.TextWriter]: 3,
//   [LessonComponentEnum.VerbSummary]: 4,
//   [LessonComponentEnum.WordSummary]: 5,
//   [LessonComponentEnum.TranslationSwitcher]: 6,
// }

export const LangCodeDescription = {
  es: 'Spanish',
  en: 'English',
  it: 'Italian',
  pt: 'Portuguese',
  fr: 'French',
};

export const LangCodeDescriptionEs = {
  es: 'Español',
  en: 'Inglés',
  it: 'Italiano',
  pt: 'Portugués',
  fr: 'Frances',
};
