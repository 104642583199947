import { Injectable, inject } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { mergeMap, map, catchError, take, tap, filter } from 'rxjs/operators';

import { VerbActions, VerbSelectors } from '.';
import { VerbService } from '../../data-services/verb.service';
import { StoreUtilService } from './../../../core/store/store-util.service';

@Injectable()
export class VerbsEffects {
  private storeUtilService = inject(StoreUtilService);
  private store = inject(Store);
  private actions$ = inject(Actions);
  private verbService = inject(VerbService);

  /** Inserted by Angular inject() migration for backwards compatibility */
  constructor(...args: unknown[]);


  constructor() {
  }

  loadProducts$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(VerbActions.loadVerbs),
      filter(() => !this.isLoaded()),
      mergeMap(() => this.verbService.getVerbs$().pipe(
        map(verbs => VerbActions.loadVerbsSuccess({ verbs })),
      )
      )
    )
  });

  private isLoaded(): boolean {
    return this.storeUtilService.getSnapshot<boolean>(VerbSelectors.getIsLoaded);
  }

}
