import { Component, Input, OnInit, Output, EventEmitter, ViewChild } from '@angular/core';
import { ImageCroppedEvent, ImageCropperComponent, LoadedImage, base64ToFile } from 'ngx-image-cropper';
import { Observable } from 'rxjs';
import { AspectRatio, AspectType, BaseStorage, ImageMultipleCrops, ImageSettings, ImgStorageData, ResolutionType } from './cropper.classes';
import { NgIf } from '@angular/common';
import { MultiImagesStorageService } from '../multi-images-storage.service';
import { FormsModule } from '@angular/forms';

@Component({
  selector: 'app-cropper',
  templateUrl: './cropper.component.html',
  styleUrls: ['./cropper.component.scss'],
  standalone: true,
  imports: [NgIf, FormsModule, ImageCropperComponent],
})
export class CropperComponent implements OnInit {
  // overrides name, path and resizeToWidth
  @Input() imageSettings: ImageSettings = {} as any;

  @Input() ratioType: AspectType | string = AspectType.Square;

  @Input() resolutions: Array<number> = [ResolutionType.MediumLarge];

  @Output() imageUploaded = new EventEmitter<any>();

  @Output() onImageCropped = new EventEmitter<ImageMultipleCrops>();

  @Output() onFileSelected = new EventEmitter<any>();

  @ViewChild(ImageCropperComponent) imageCropper!: ImageCropperComponent;

  public fileMetadata: File | null = null;
  public imageChangedEvent!: Event;

  public aspectRatio: number = 1;

  public croppedImage: any = '';

  public isLoading = false;
  public isUploaded = false;
  public renameFile: any = '';
  public storagePath: string = '';

  constructor(private multiImagesStorageService: MultiImagesStorageService) {}

  ngOnInit(): void {
    this.aspectRatio = AspectRatio[this.ratioType];
    if (this.imageSettings.path) {
      this.storagePath = `${this.imageSettings.path}/${this.imageSettings.fileName}.webp`;
    }
  }

  public reloadPath(): void {
    this.storagePath = `${this.imageSettings.path}/${this.renameFile}.webp`;
  }

  async fileChangeEvent(event: Event) {
    this.imageChangedEvent = event;
    const filesEvent = <FileList>(<HTMLInputElement>event.target).files;
    this.fileMetadata = filesEvent[0];
    this.onFileSelected.emit(this.fileMetadata);

    this.renameFile = this.fileMetadata.name.split('.')[0];
    if (!this.imageSettings.fileName) {
      this.reloadPath();
    }
  }

  onInnerImageCropped(event: ImageCroppedEvent) {
    this.croppedImage = event.base64;
  }

  imageLoaded(image: LoadedImage) {
    // show cropper
  }

  loadImageFailed() {
    console.error('fallo al cargar la imagen');
  }

  public downloadURL!: Observable<string>;

  public async simpleCropAndUpload() {
    const imageCropped: any = await this.imageCropper.crop();
    const imgStorage = await this.multiImagesStorageService.uploadImage(imageCropped?.blob, this.storagePath);
    this.imageUploaded.emit(imgStorage);
  }

  public async uploadToStorage(imageMulticrops: ImageMultipleCrops): Promise<void> {
    // TODO: Nota si algo falla aquí puede causar inconsistencias en el sistema, ver como manejar errores
    const path = this.imageSettings.path;
    // const imageUploaded = await this.multiImagesStorageService.uploadImage(imageMulticrops, path);
    // this.modalRef.close();
    // console.log(imageUploaded);
    // const image = { type: 'cover', ...imageUploaded };

    // TODO: creo que esta parte va en el componente que llama a este componente
    // if (!this.lesson.media) {
    //   this.lesson.media = {};
    //   this.lesson.media.images = [image];
    // } else {
    //   // solo sustituir el cover si ya existe
    //   const currentCover = this.lesson.media.images.find((img) => img.type === 'cover');
    //   this.multiImagesStorageService.delete_directory(currentCover.path);

    //   this.lesson.media.images = this.lesson.media.images.filter((img) => img.type !== 'cover');

    //   this.lesson.media.images.push(image);
    // }

    // await this.saveLesson();
    // this.updateCover();
    // this.imageUploaded.emit(image);
  }
}
