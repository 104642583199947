import { createSelector, createFeatureSelector } from '@ngrx/store';

import { WordsState } from '.';


const featureWord = createFeatureSelector<Readonly<WordsState>>('words');

export const getWords = createSelector(featureWord, (state: WordsState) => state.words);

export const getIsLoading = createSelector(featureWord, (state: WordsState) => state.isLoading);

export const getIsLoaded = createSelector(featureWord, (state: WordsState) => state.isLoaded);

export const getMetadata = createSelector(featureWord, (state: WordsState) => {
  return { isLoading: state.isLoading, isRehydrated: state.isRehydrated, isLoaded: state.isLoaded };
});
