export interface StorageImageSettings {
  path?: string;
  fileName?: string;
  cropSettings?: ImageCropSettings;
}

export interface ImageCropSettings {
  resizeToWidth?: number;
  resolutions: Array<number>; // 400, 800, 1200, 1600
  aspectRatio: AspectType;
}

export interface CropImageSettings {
  // Resolutions should be here.
  path: string;
  fileName?: string;
  resizeToWidth?: number;
}

export interface ImageMultipleCrops {
  file: File;
  defaultImageBlob?: Blob;
  imagesBlobs: { [resolution: number]: Blob };
  settings: { renameFile: string; width?: number };
}

export const AspectRatioOptions: AspectRatioOption[] = [
  { value: '1:1', label: 'square', description: 'Square (1:1)', valueRatio: 1 / 1 },
  { value: '1:2', label: 'vertical_1_2', description: 'Vertical (1:2)', valueRatio: 1 / 2 },
  { value: '2:3', label: 'vertical_2_3', description: 'Vertical (2:3)', valueRatio: 2 / 3 },
  { value: '3:4', label: 'vertical_3_4', description: 'Vertical (3:4)', valueRatio: 3 / 4 },
  { value: '4:5', label: 'vertical_4_5', description: 'Vertical (4:5)', valueRatio: 4 / 5 },
  { value: '9:16', label: 'vertical_9_16', description: 'Vertical (9:16)', valueRatio: 9 / 16 },
  { value: '2:1', label: 'horizontal_2_1', description: 'Horizontal (2:1)', valueRatio: 2 / 1 },
  { value: '3:2', label: 'horizontal_3_2', description: 'Horizontal (3:2)', valueRatio: 3 / 2 },
  { value: '4:3', label: 'horizontal_4_3', description: 'Horizontal (4:3)', valueRatio: 4 / 3 },
  { value: '5:4', label: 'horizontal_5_4', description: 'Horizontal (5:4)', valueRatio: 5 / 4 },
  { value: '16:9', label: 'horizontal_16_9', description: 'Horizontal (16:9)', valueRatio: 16 / 9 },
];

export interface AspectRatioOption {
  label: string;
  description: string;
  value: string;
  valueRatio: number;
}

export enum AspectType {
  Square = 'square',
  Rectangle = 'rectangle',
  Banner = 'banner',
  RectangleLarge = 'rectangleLarge',
  Vertical_9_16 = 'vertical_9_16',
  Vertical_3_5 = 'vertical_3_5',
  Vertical_2_3 = 'vertical_2_3',
}

// Creo que seria bueno refactorizar para solo enviar aspect ratio asi
export enum AspectRatio2 {
  // Vertical
  '9:16' = 16 / 9,
  '3:5' = 3 / 5,
  '5:8' = 5 / 8,
  '2:3' = 2 / 3,
  '1:1' = 1 / 1,
}

export enum ResolutionType {
  Small = 200,
  Medium = 400,
  MediumLarge = 800,
  Large = 1200,
  VeryLarge = 1600,
}

export const AspectRatio = {
  [AspectType.Square]: 1 / 1,
  [AspectType.Rectangle]: 16 / 9,
  [AspectType.Vertical_9_16]: 9 / 16,
  [AspectType.RectangleLarge]: 16 / 8,
  [AspectType.Banner]: 16 / 7,
  [AspectType.Vertical_3_5]: 3 / 5,
  [AspectType.Vertical_2_3]: 2 / 3,
};

export interface ImgStorageData extends CloudStorageData {
  name?: string;
  bucket?: string;
  url?: string;
  path?: string; // path where the file is in the storage
  fullPath?: string; // path + name
  resolution?: string;
  resolutions?: any;
}

export interface CloudStorageData {
  bucket?: string;
  url?: string;
  path?: string; // path where the file is in the storage
}

export const DEFAULT_SETTINGS: StorageImageSettings = {
  path: '/default-collection/id/please-change-this-subcollection',
  fileName: 'image',
  cropSettings: {
    aspectRatio: AspectType.Square,
    resolutions: [ResolutionType.Small, ResolutionType.MediumLarge],
    resizeToWidth: 450,
  },
};
