import { Injectable, inject } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { mergeMap, map, take } from 'rxjs/operators';

import { UserActions } from '.';
import { UserService } from '../../data-services/user.service';

@Injectable()
export class UserEffects {
  private store = inject(Store);
  private actions$ = inject(Actions);
  private userService = inject(UserService);

  /** Inserted by Angular inject() migration for backwards compatibility */
  constructor(...args: unknown[]);

  constructor() { }
  // some how this loadUser$  variable does not matter, internaly createEffect add the effects to the root effects
  loadUser$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(UserActions.loadUser),
      mergeMap(() => this.userService.user$.pipe(map((user) => UserActions.loadUserSuccess({ user })))),
      take(1),
    );
  });
}
