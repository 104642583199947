import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';
import { Store } from '@ngrx/store';

import { EnglishVerb, DeletedData, ProgressWordEvaluation, ProgressResultsDto } from '../classes';
import { HttpService } from '../system/http.service';
import { VerbActions, VerbSelectors } from '../store/verbs';
import { VerbWebApi, WordFormMode } from '../enums';
import { StoreUtilService } from '../store/store-util.service';
import { VerbFormComponent } from 'src/app/pages/my-verbs/verb-form/verb-form.component';
import { UserActions } from '../store/user';
import { AudioService } from '../system/audio.service';
import { ConfirmService } from 'src/app/shared/confirm/confirm.service';
import { ToastService } from '../system/toast.service';
import { DialogService } from 'primeng/dynamicdialog';

@Injectable({
  providedIn: 'root',
})
export class VerbService {
  public verbs: Array<any> = null;

  constructor(
    private dialogService: DialogService,
    private storeUtilService: StoreUtilService,
    private store: Store,
    private httpService: HttpService,
    private toastrService: ToastService,
    private audioService: AudioService,
    private confirmService: ConfirmService,
  ) {}

  public getVerbs$(): Observable<Array<EnglishVerb>> {
    return this.httpService.getObservable<Array<EnglishVerb>>(VerbWebApi.GetVerbs, 'nodejs');
  }

  // @deprecated TODO! ahora que una palabra no tiene un id este no funciona más
  public async saveVerb(verb: EnglishVerb): Promise<EnglishVerb> {
    const savedVerb: EnglishVerb = await this.httpService.postDataToService(VerbWebApi.PostVerb, verb);
    if (verb.id) {
      this.store.dispatch(VerbActions.updateVerbSuccess({ verb: savedVerb }));
    } else {
      this.store.dispatch(VerbActions.createVerbSuccess({ verb: savedVerb }));
    }
    return savedVerb;
  }

  public promoveVerb$(id: string): Observable<EnglishVerb> {
    return this.httpService.getObservable<EnglishVerb>(`${VerbWebApi.PromoteVerb}/${id}`);
  }

  public async promoteVerb(verb: EnglishVerb): Promise<EnglishVerb> {
    return await this.httpService.postDataToService(VerbWebApi.PromoteVerb, verb);
  }

  public async getSingleVerb(word: string): Promise<EnglishVerb> {
    return this.httpService.getDataFromService(`${VerbWebApi.GetVerb}/${word}`);
  }

  public async deleteVerb(word: string): Promise<DeletedData> {
    const deletedVerb = await this.httpService.deleteDataFromService(`${VerbWebApi.DeleteVerb}/${word}`, 'nodejs');
    this.store.dispatch(VerbActions.deleteVerbSuccess({ word }));
    return deletedVerb;
  }

  public async saveProgressVerb(
    progressWords: Array<ProgressWordEvaluation>,
    callbackFn: () => void = null,
  ): Promise<Array<ProgressWordEvaluation>> {
    const responseProgress = await this.httpService.postDataToService<ProgressResultsDto>(VerbWebApi.SaveProgress, progressWords);
    this.store.dispatch(VerbActions.updateProgressWord({ progress: responseProgress.progress }));

    if (responseProgress.points > 0) {
      this.store.dispatch(UserActions.addStatPoints({ points: responseProgress.points }));
      this.audioService.playCoins(responseProgress.points);
    }
    const data = responseProgress.progress.filter((word) => word.justCompleted);
    if (data.length > 0) {
      let title = '';
      let message = '';

      if (data.length === 1) {
        title = `Felicidades completaste el progreso para el verbo ${data[0].word}.`;
        message = `¿Quieres eliminar ${data[0].word} de tu lista de aprendizaje ?`;
      } else {
        title = `Felicidades completaste el progreso para los verbos ${data.map((item) => item.word).join(', ')}.`;
        message = `¿Quieres eliminar ${data.map((item) => item.word).join(', ')} de tu lista de aprendizaje ?`;
      }

      const ref = this.confirmService.openConfirm(title, message, 'Eliminar');

      ref.subscribe(async (result) => {
        if (result) {
          for (const word of data) {
            await this.deleteVerb(word.word);
            if (callbackFn) {
              callbackFn();
            }
          }
        }
      });
      return responseProgress.progress;
    }
    return null;
  }

  public dispatchNewVerb(verb: EnglishVerb): void {
    this.store.dispatch(VerbActions.createVerbSuccess({ verb }));
  }

  public async updateVerb(verb): Promise<EnglishVerb> {
    const savedVerb: EnglishVerb = await this.httpService.postDataToService(verb, VerbWebApi.PostVerb);
    this.store.dispatch(VerbActions.updateVerbSuccess({ verb: savedVerb }));
    return savedVerb;
  }

  // Guarda un verbo con una cadena
  public async saveVerbToUserList(verb: string): Promise<EnglishVerb> {
    if (this.isVerbAlreadySaved(verb)) {
      return null;
    }
    const newVerb: EnglishVerb = await this.httpService.postDataToService(VerbWebApi.saveVerbWithString, { word: verb });
    this.toastrService.success(`Se registró la palabra '${newVerb.word}' - '${newVerb.translation}'`, 'Excelente');
    this.dispatchNewVerb(newVerb);
    return newVerb;
  }

  public isVerbAlreadySaved(word: string): boolean {
    // TODO:  this works only if i have already verbs in memory, reload in the lesson and add a verb via VerbSummary component,
    const isFound = this.isVerbInUserList(word);
    if (isFound) {
      this.toastrService.warn(`Ya tienes registrado el verbo "${word}"`, 'Recordatorio');
    }
    return !!isFound;
  }

  public isVerbInUserList(verb: string): boolean {
    const verbs = this.getVerbsSnapshot() || [];
    const isFound = !!verbs.find((verbItem) => verbItem.word === verb);
    return !!isFound;
  }

  public getVerbsSnapshot(): ReadonlyArray<EnglishVerb> {
    return this.storeUtilService.getSnapshot<ReadonlyArray<EnglishVerb>>(VerbSelectors.getVerbs);
  }

  public async updateVerbProperty(word: string, property: string, value: any): Promise<void> {
    this.store.dispatch(VerbActions.updateVerbProperty({ word, property, value }));
  }

  // TODO: Abre un modal, quizá no deba agregar aquí para respetar que es solo servicio de datos.
  public openVerbFormDialog(verb: EnglishVerb, topicId = null, mode: WordFormMode = null) {
    const context = { englishVerb: verb, topicId, mode };
    console.log(context);
    const dialogRef = this.dialogService.open(VerbFormComponent, {
      data: context,
      closable: true,
    });

    dialogRef.onClose.subscribe((verbResult) => {
      // this.logger.log('Se guardó un nuevo verb', verbResult);
    });
  }
}
