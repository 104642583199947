import { Inject, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import {
  Auth,
  authState,
  createUserWithEmailAndPassword,
  FacebookAuthProvider,
  GoogleAuthProvider,
  IdTokenResult,
  sendPasswordResetEmail,
  signInWithEmailAndPassword,
  signInWithPopup,
  sendEmailVerification,
  signInWithCredential,
} from '@angular/fire/auth';
import { firstValueFrom, from, of } from 'rxjs';
import { take, concatMap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class FirebaseAuthService {
  constructor(private router: Router, private auth: Auth) {}

  public authState$ = authState(this.auth);

  public tokenId$ = this.authState$.pipe(
    take(1),
    concatMap((auth: any) => {
      if (auth === null) {
        return of(null);
      }
      return from(auth.getIdToken());
    }),
  );

  refreshToken(): void {
    this.auth.currentUser?.getIdToken(true);
  }

  async signInWithCredential(credential: any): Promise<any> {
    return signInWithCredential(this.auth, credential);
  }

  async logOut(redirectRoute = '/signup'): Promise<void> {
    await this.auth.signOut();
    this.router.navigate([redirectRoute]);
  }

  async signWithProvider(provider: 'google' | 'facebook'): Promise<void> {
    try {
      const authProvider = provider === 'google' ? new GoogleAuthProvider() : new FacebookAuthProvider();
      await signInWithPopup(this.auth, authProvider);
      this.router.navigate(['/']);
    } catch (error) {
      console.error('Error signing in with provider:', error);
      throw error;
    }
  }

  async register(email: string, password: string): Promise<void> {
    try {
      await createUserWithEmailAndPassword(this.auth, email, password);
      this.router.navigate(['/']);
    } catch (error) {
      console.error('Error registering:', error);
      throw error;
    }
  }

  async sendEmailVerification(): Promise<void> {
    if (this.auth.currentUser) {
      await sendEmailVerification(this.auth.currentUser);
    }
  }

  async signWithEmailPassword(email: string, password: string): Promise<void> {
    try {
      await signInWithEmailAndPassword(this.auth, email, password);
      this.router.navigate(['/']);
    } catch (error) {
      console.error('Error signing in:', error);
      throw error;
    }
  }

  async getTokenResult(): Promise<IdTokenResult | null> {
    return this.auth.currentUser?.getIdTokenResult();
  }

  async isAuthenticated(): Promise<boolean> {
    try {
      const user = await firstValueFrom(authState(this.auth));
      return !!user; // converts to boolean, true if user exists, false if null
    } catch (error) {
      console.error('Auth check failed:', error);
      return false;
    }
  }

  async sendPasswordResetEmail(email: string): Promise<void> {
    return sendPasswordResetEmail(this.auth, email);
  }
}
