import { InjectionToken, Type } from '@angular/core';
import { Provider } from '@angular/core';
import { ConversationPromptSettings, ConversationDTO, ConversationUserSettings, IAgentCard, ModelName } from './conversation.interface';
import { FiltersConfig, IFilterQueryResponse } from '@dataclouder/core-components';

export const CONVERSATION_AI_TOKEN = new InjectionToken<AgentCardsAbstractService>('Conversation Ai Service');

// abstract-my-service.ts
export abstract class AgentCardsAbstractService {
  // TODO define the type of return value
  abstract callChatCompletion(conversation: ConversationPromptSettings): Promise<any>;
  // Get conversation card by id
  abstract findConversationCardByID(id: string): Promise<IAgentCard>;

  abstract filterConversationCards(filters: FiltersConfig): Promise<{ rows: IAgentCard[]; count: number; skip: number }>;
  // Get all conversation cards
  abstract getAllConversationCards(): Promise<IAgentCard[]>;
  // This is the one to use to filter cards. TODO! change the returning type to be a paginator
  abstract findAgentCards(paginator: FiltersConfig): Promise<IFilterQueryResponse<IAgentCard>>;

  abstract saveConversationCard(conversation: IAgentCard): Promise<IAgentCard>;

  abstract deleteConversationCard(id: string): Promise<IAgentCard>;

  abstract getTextAudioFile(tts: any): Promise<any>;

  abstract getConversationUserChatSettings(): Promise<ConversationUserSettings>;

  abstract getListModels(provider: string): Promise<ModelName[]>;

  abstract translateConversation(currentLang: string, targetLang: string, id: string): Promise<ConversationUserSettings>;

  abstract getAudioTranscriptions(audio: any, options: any): Promise<any>;

  // abstract getConversationPromptSettings(): Promise<ConversationPromptSettings>;

  abstract saveConversationUserChatSettings(conversation: ConversationUserSettings): Promise<ConversationUserSettings>;
}

// my-service.provider.ts
export function provideChatAIService(serviceImplementation: Type<AgentCardsAbstractService>): Provider[] {
  return [
    {
      provide: CONVERSATION_AI_TOKEN,
      useExisting: serviceImplementation,
    },
  ];
}
