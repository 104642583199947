<p-dialog
  header="Selecciona y recorta la imagen"
  [(visible)]="isCropperVisible"
  [modal]="true"
  [style]="{ width: '55vw' }"
  [draggable]="false"
  [resizable]="false">
  <app-cropper (imageUploaded)="onUploadedImage($event)"
    ratioType="vertical_9_16"
  [imageSettings]="{ path: 'words/' + word() + '/images' }" />
</p-dialog>

@if (id) {
  <div style="display: flex; justify-content: space-between; margin-bottom: 20px">
    <div> ID: {{ form.controls.id.value }} </div>

    <p-button label="Generar imagen con AI" (click)="generateImageAgain()" [text]="true" [raised]="true"></p-button>

    <p-button label="Agregar/Cambiar imagen" (click)="showImageCropper()" [text]="true" [raised]="true"></p-button>

    <p-button (click)="removeLearningExample()" label="Eliminar" icon="pi pi-times" [text]="true" severity="danger"> </p-button>
  </div>

} @else {
  <!-- no se porque no funciona  -->
  @if (form.dirty) {
    <p-button [loading]="isLoading" label="Guardar vacío" (click)="saveLearningExample()" [raised]="true" size="small" />
  }
}

<div style="display: flex; gap: 20px">
  <div class="image">
    @if (form.controls.image.value?.url) {
      <div>
        <img width="300px" [src]="form.controls.image.value.url" alt="" />
      </div>
    }
  </div>

  <div class="inputs-container">
    <div class="flex">
      <input class="w-full" type="text" pInputText [formControl]="form.controls.example" placeholder="Escribe oración ejemplo (Ingles)" />

      @if (id && !form.controls.audio.value) {
        <p-button label="Generar" [text]="true" (click)="generateAudio()"></p-button>
      }

      @if (form.controls.audio.value) {
        <p-button (click)="playAudio(form.controls.audio.value)" icon="pi pi-play" [text]="true"></p-button>
      }
    </div>

    <input class="w-full" type="text" pInputText [formControl]="form.controls.meaning" placeholder="Significado o Relevacia de la palabra " />

    <input class="w-full" type="text" pInputText [formControl]="form.controls.scenario" placeholder="Scenario" />

    <input class="w-full" type="text" pInputText [formControl]="form.controls.attribution" placeholder="Atribuciones al autor" />

    <h6>Traducciones Español</h6>

    <input class="w-full" type="text" pInputText [formControl]="form.controls.es.controls.translation" placeholder="Traducción" />

    <input
      class="w-full"
      type="text"
      pInputText
      [formControl]="form.controls.es.controls.meaningTranslation"
      placeholder="Escribe explicación (español)" />

    <h6>Traducciones Inglés</h6>

    <input class="w-full" type="text" pInputText [formControl]="form.controls.en.controls.translation" placeholder="Traducción ingles" />

    <input
      class="w-full"
      type="text"
      pInputText
      [formControl]="form.controls.en.controls.meaningTranslation"
      placeholder="Significado translation" />

    <p-checkbox [formControl]="form.controls.isPublic" [binary]="true" inputId="binary"></p-checkbox> Mostrar al público?

    <div class="grid">
      <div class="sm:col-6">
        <hr />
        <h4>Revisar voice type descomentar hay error</h4>

        <!-- <p-dropdown [options]="voiceOptions" [formControl]="form.get('voiceType')" optionLabel="name"
        optionValue="id" [showClear]="true" placeholder="Voz" formControlName="voice">

        <ng-template let-voice pTemplate="item">
          <div class="flex align-items-center gap-2">
            <div>{{ voice.provider }}</div>
            -
            <div>{{ voice.name }}</div>
          </div>
        </ng-template>
      </p-dropdown>

      <p-button *ngIf="form.controls.audio.value" (click)="playVoiceExample(form.controls.voiceType.value)"
      icon="pi pi-play" [text]="true"></p-button> -->
    </div>
  </div>
</div>
</div>
<div style="display: flex; justify-content: space-between">
  @if (form.dirty) {
    <p-button
      [loading]="isLoading"
      [label]="id ? 'Guardar Cambios' : 'Crear Ejemplo'"
      (click)="saveLearningExample()"
      [raised]="true"
      [severity]="id ? 'info' : 'warn'"
    size="small"></p-button>
  }

  @if (form.controls.example.dirty) {
    <p-button
      [loading]="isLoading"
      label="AutoCompletar con AI"
      (click)="autoCompleteExample()"
      [raised]="true"
      severity="help"
    size="small"></p-button>
  }
</div>
