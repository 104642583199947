import { LangWord } from '../lexicore.classes';
import { EnglishVerb, User, Topic } from './../classes';

export interface AppState {
  words: Array<LangWord>;
  verbs: ReadonlyArray<EnglishVerb>;
  user: User;
  topics: ReadonlyArray<Topic>;
  phrases: ReadonlyArray<Topic>;
}

export enum StorageStateEnum {
  User = 'user_state',
  Words = 'words_state',
  Verbs = 'verbs_state',
}

export function hydrateStorage(key: StorageStateEnum, data: any): void {
  localStorage.setItem(key, JSON.stringify(data));
}

export function getStorageRehydration<T>(key: StorageStateEnum): T {
  // obtiene el valor del storage y lo parsea
  const storageValue = localStorage.getItem(key);
  if (storageValue) {
    try {
      return JSON.parse(storageValue);
    } catch (e) {
      console.error('error on getting user from storage', key);
      return null;
    }
  }
  return null;
}
